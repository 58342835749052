import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import PlaneForm from "./PlanForm";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePlan from "./DeletePlan";
import { fetchStudyPlaneDataByPage, studyPlaneData } from "../../redux/studypalne";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { useTranslation } from "react-i18next";
import EditPlaneForm from "./EditPaln";
import UploadFile from "./FileUpload";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";

const StudyPlaneTable = () => {
  const nextPage = useSelector(
    (state) => state.studyPlane.studyPlaneLinks.next
  );
  const lastPage = useSelector(
    (state) => state.studyPlane.studyPlaneLinks.last
  );
  const prevPage = useSelector(
    (state) => state.studyPlane.studyPlaneLinks.prev
  );
  const currentPage = useSelector((state) => state.studyPlane.currentPage);
  const firstPage = useSelector(
    (state) => state.studyPlane.studyPlaneLinks.first
  );
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const studyPlane =
    useSelector((state) => state.studyPlane.studyPlane.data) || [];
  const loading = useSelector((state) => state.studyPlane.loading);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const createdWidth =
    permission.includes("update-study_plan") &&
    permission.includes("delete-study_plan")
      ? 200
      : 400;
  const nameWidth =
    permission.includes("update-study_plan") &&
    permission.includes("delete-study_plan")
      ? 250
      : 300;
  const idWidth =
    permission.includes("update-study_plan") &&
    permission.includes("delete-study_plan")
      ? 130
      : 230;
  const typeWidth =
    permission.includes("update-study_plan") &&
    permission.includes("delete-study_plan")
      ? 150
      : 150;

  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    { field: "created_at", headerName: t("created at"), width: createdWidth },
    {
      field: "type",
      headerName: t("Type"),
      width: typeWidth,
      renderCell: (params) => {
        const typeValue = parseInt(params.row.type);
        let label = null;
        switch (typeValue) {
          case 1:
            label = t("Holiday");
            break;
          case 2:
            label = t("Occasion");
            break;
          default:
            label = "Unknown";
        }
        return <Box>{label}</Box>;
      },
    },
    ...(permission.includes("update-study_plan") &&
    permission.includes("delete-study_plan")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 300,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditPlaneForm
                  sx={{
                    background: `${colors.primary[600]}`,
                    display: permission.includes("update-study_plan")
                      ? "inline-flex"
                      : "none",
                  }}
                  ID={params.id}
                  icon={<EditIcon />}
                  pageSize={pageSize}
                />
                <DeletePlan
                  pageSize={pageSize}
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-study_plan")
                      ? "inline-flex"
                      : "none",
                  }}
                  icon={<DeleteIcon />}
                  id={params.id}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    dispatch(studyPlaneData(pageSize));
  }, [pageSize, dispatch]);
  const tableData = {
    rows: studyPlane && studyPlane,
    columns: columns,
    loading: loading && true,
    pageSize: pageSize,
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
  };
  return (
    <Box m="20px">
      <Header dir={sidebarRTL ? "rtl" : "ltr"} title={t("Occasions")} />
      <Box
        display="flex"
        flexDirection={sidebarRTL ? "end" : "start"}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        dir={sidebarRTL ? "rtl" : "ltr"}
      >
        <Box
          dir={sidebarRTL ? "rtl" : "ltr"}
          width="50%"
          sx={{ display: "inline-flex", marginTop: "1rem" }}
        >
          <UploadFile dir={sidebarRTL ? "rtl" : "ltr"} pageSize={pageSize} />
          <PlaneForm
            show={permission.includes("store-study_plan")}
            pageSize={pageSize}
            dir={sidebarRTL ? "rtl" : "ltr"}
          />
        </Box>
      </Box>
      <CustomTableBox
        tableData={tableData}
        CustomPagenation={
          <CustomPagenation
            nextPage={nextPage}
            lastPage={lastPage}
            prevPage={prevPage}
            currentPage={currentPage}
            firstPage={firstPage}
            pageSize={pageSize}
            action={fetchStudyPlaneDataByPage}
          />
        }
      />
    </Box>
  );
};

export default StudyPlaneTable;