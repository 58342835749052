import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, AlertTitle, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch } from "react-redux";
import {
  getStudentBans,
  getStudentExams,
  getStudentReports,
  getstudentAppreciations,
  getstudentGuardians,
  removeAppreciations,
  removeBans,
  removeExams,
  removeGuardians,
  removeReports,
} from "../../redux/student";
import { useTranslation } from "react-i18next";

const RemoveStudentDetails = ({ selectedTab, id, studentId, sx, icon }) => {
  const { t } = useTranslation()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const info = {
    id,
    studentId: studentId
  };

  const handleRemove = () => {
    if (selectedTab === 0) {
      dispatch(removeReports(info)).then(() => {
        dispatch(getStudentReports(info));
        handleClose();
      });
    } else if (selectedTab === 1) {
      dispatch(removeExams(info)).then(() => {
        dispatch(getStudentExams());
        handleClose();
      });
    } else if (selectedTab === 2) {
      dispatch(removeBans(info)).then(() => {
        dispatch(getStudentBans(info));
        handleClose();
      });
    } else if (selectedTab === 3) {
      dispatch(removeAppreciations(info)).then(() => {
        dispatch(getstudentAppreciations(info));
        handleClose();
      });
    } else if (selectedTab === 4) {
      dispatch(removeGuardians(info)).then(() => {
        dispatch(getstudentGuardians(info));
        handleClose();
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button sx={sx} variant="contained" endIcon={icon} onClick={handleClickOpen}>
        {t("Remove")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} id="alert-dialog-title">
          <Alert severity="warning" sx={{ fontSize: "20px" }}>
            <AlertTitle sx={{ fontSize: "20px" }}>Are you sure?</AlertTitle>
            <p sx={{ fontSize: "14px" }}>
              {t("this Will be Removed")}
            </p>
          </Alert>
        </DialogTitle>
        <DialogActions sx={{ margin: "1rem auto" }}>
        <Button
            variant="contained"
            sx={{ background: `${colors.redAccent[500]}` }}
            onClick={handleRemove}
            autoFocus
          >
            {t("yes")}
          </Button>
          <Button variant="contained" onClick={handleClose}>
            {t("No")}
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RemoveStudentDetails;
