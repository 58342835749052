import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import { getFullMarkGrades } from "../../redux/FullMarks";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";
import {
  getExamSchedule,
  getExamScheduleByPage,
  SearchExamSchedule,
} from "../../redux/ExamSchedule";
import DeleteExamSchedule from "./DeleteExamSchedule";
import AddExamSchedule from "./AddExamSchedule";
import EditExamSchedule from "./EditExamSchedule";
import UploadExamFile from "./uploadExcel";
import CustomSearch from "../../components/customSearch/customSearch";

const ExamScheduleTable = () => {
  const nextPage = useSelector(
    (state) => state.ExamSchedule.ExamScheduleLinks.next
  );
  const lastPage = useSelector(
    (state) => state.ExamSchedule.ExamScheduleLinks.last
  );
  const prevPage = useSelector(
    (state) => state.ExamSchedule.ExamScheduleLinks.prev
  );
  const currentPage = useSelector((state) => state.ExamSchedule.currentPage);
  const firstPage = useSelector(
    (state) => state.ExamSchedule.ExamScheduleLinks.first
  );
  const { sidebarRTL } = useSidebarContext();
  const [selectedGradeId, setSelectedGradeId] = useState();
  const [SelectedGradeName, setSelectedGradeName] = useState("");
  const [SelectedExamType, setSelectedExamType] = useState("");

  const AllGrades =
    useSelector((state) => state.FullMarks.getGradesData.data) || [];
  const ExamScheduleData =
    useSelector((state) => state.ExamSchedule.ExamScheduleData.data) || [];
  console.log(useSelector((state) => state));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const loading = useSelector((state) => state.gradeExpenses.loading);
  const theme = useTheme();

  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const colors = tokens(theme.palette.mode);

  const ExamsTypeArray = [`${t("mid-term")}`, `${t("final")}`];
  useEffect(() => {
    dispatch(getFullMarkGrades());
  }, [dispatch]);

  const info = {
    gradeId: selectedGradeId,
    typeId: SelectedExamType,
    pageSize: pageSize,
  };
  useEffect(() => {
    dispatch(getExamSchedule(info));
  }, [dispatch, selectedGradeId, SelectedExamType, AllGrades]);

  useEffect(() => {
    if (AllGrades.length > 0 && !selectedGradeId) {
      setSelectedGradeId(AllGrades[0].id);
      setSelectedGradeName(AllGrades[0].name);
    }
  }, [AllGrades, selectedGradeId]);

  const handleSelectChangeGrade = (event) => {
    const selectedGradeId = event.target.value; // Get the selected grade ID
    setSelectedGradeId(selectedGradeId); // Update the selected grade ID in state

    const selectedGrade = AllGrades.find(
      (grade) => grade.id === selectedGradeId
    );
    if (selectedGrade) {
      setSelectedGradeName(selectedGrade.name); // Update the selected grade name in state
    }
  };
  const handleSelectExamType = (event) => {
    const SelectExamType = event.target.value; // Get the selected grade ID
    setSelectedExamType(SelectExamType); // Update the selected grade ID in state

    const selectedGrade = AllGrades.find(
      (grade) => grade.id === selectedGradeId
    );
    if (selectedGrade) {
      setSelectedGradeName(selectedGrade.name); // Update the selected grade name in state
    }
  };

  const expense =
    permission.includes("update-exam_schedule") &&
    permission.includes("delete-exam_schedule")
      ? 200
      : 400;
  const columns = [
    { field: "id", headerName: t("ID"), width: 120 },
    {
      field: "examDate",
      headerName: t("expenseDate"),
      width: expense,
      cellClassName: "name-column--cell",
    },

    { field: "from", headerName: t("from"), width: 120 },
    { field: "to", headerName: t("to"), width: 120 },
    { field: "subject", headerName: t("subject"), width: 180 },
    ...(permission.includes("delete-exam_schedule") &&
    permission.includes("update-exam_schedule")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 300,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditExamSchedule
                  sx={{
                    backGround: `${colors.grey[600]}`,
                    display: permission.includes("update-exam_schedule")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<EditIcon />}
                  gradeExpensesId={selectedGradeId}
                  pageSize={pageSize}
                  typeId={SelectedExamType}
                  examDate={params.row.examDate}
                  from={params.row.from}
                  to={params.row.to}
                />
                <DeleteExamSchedule
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-exam_schedule")
                      ? "inline-flex"
                      : "none",
                  }}
                  icon={<DeleteIcon />}
                  id={params.id}
                  SelectedExamType={SelectedExamType}
                  gradeId={selectedGradeId}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];

  const tableData = {
    rows: ExamScheduleData.length > 0 && ExamScheduleData,
    columns: columns,
    loading: loading && true,
    pageSize: pageSize,
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        dir={sidebarRTL ? "rtl" : "ltr"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Header title={t("ExamSchedule")} />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={sidebarRTL ? "row" : "row-reverse"}
      >
        <UploadExamFile grades={AllGrades} />
        <AddExamSchedule
          show={
            permission.includes("store-exam_schedule") ? "inline-flex" : "none"
          }
          pageSize={pageSize}
          typeId={SelectedExamType}
          gradeExpensesId={selectedGradeId}
        />
        <FormControl variant="outlined">
          <label style={{ fontSize: "18px", fontWeight: "bold" }}>
            {t("Grade")}
          </label>
          <Select
            sx={{ minWidth: "300px" }}
            value={selectedGradeId || ""}
            onChange={handleSelectChangeGrade}
            dir={sidebarRTL ? "rtl" : "ltr"}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: "#000",
                fontSize: "20px",
                fontWeight: "bold",
                direction: sidebarRTL ? "rtl" : "ltr",
                maxHeight: "150px",
                overflowY: "auto",
                overflowX: "hidden",
              }),
              container: (provided) => ({
                ...provided,
                zIndex: 9999,
                direction: sidebarRTL ? "rtl" : "ltr",
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
                direction: sidebarRTL ? "rtl" : "ltr",
              }),
            }}
          >
            {AllGrades &&
              AllGrades.map((gradeItem) => (
                <MenuItem
                  key={gradeItem.id}
                  value={gradeItem.id}
                  sx={{ fontSize: "18px", fontWeight: "bold" }}
                >
                  {gradeItem.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined">
          <label style={{ fontSize: "18px", fontWeight: "bold" }}>
            {t("ExamType")}
          </label>
          <Select
            sx={{ minWidth: "300px" }}
            value={SelectedExamType}
            onChange={handleSelectExamType}
            dir={sidebarRTL ? "rtl" : "ltr"}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: "#000",
                fontSize: "20px",
                fontWeight: "bold",
                direction: sidebarRTL ? "rtl" : "ltr",
                maxHeight: "150px",
                overflowY: "auto",
                overflowX: "hidden",
              }),
              container: (provided) => ({
                ...provided,
                zIndex: 9999,
                direction: sidebarRTL ? "rtl" : "ltr",
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
                direction: sidebarRTL ? "rtl" : "ltr",
              }),
            }}
          >
            {ExamsTypeArray &&
              ExamsTypeArray.map((typeItem, index) => (
                <MenuItem
                  key={index}
                  value={index}
                  sx={{ fontSize: "18px", fontWeight: "bold" }}
                >
                  {typeItem}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <CustomTableBox
        action={SearchExamSchedule}
        pageSize={pageSize}
        gradeId={selectedGradeId}
        typeId={SelectedExamType}
        tableData={tableData}
        CustomPagenation={
          <CustomPagenation
            pageSize={pageSize}
            nextPage={nextPage}
            lastPage={lastPage}
            prevPage={prevPage}
            currentPage={currentPage}
            firstPage={firstPage}
            action={getExamScheduleByPage}
          />
        }
      />
    </Box>
  );
};
export default ExamScheduleTable;
