import { Box } from "@mui/material";
import ExpensesTable from "./table";

const Expenses = () => {
  return (
    <Box m="20px">
      <ExpensesTable />
    </Box>
  );
};
export default Expenses;
