import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Tab,
  Tabs,
  useMediaQuery,
  Paper,
  useTheme,
  ButtonGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./studentDetails.css";
import {
  getStudentBans,
  getStudentExams,
  getStudentReports,
  getstudentAppreciations,
  getstudentGuardians,
  studentData,
} from "../../redux/student";
import { tokens } from "../../theme";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import RemoveStudentDetails from "./RemoveStudentDetails";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ExamsComponent from "./ExamsComponent";
import EditStudentDetails from "./EditStudentDetails";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
const Student_Details = () => {
  const permission = useSelector(
    (state) => state.user.userInfo.data.permissions
  );
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const loading = useSelector((state) => state.student.loading);
  const [openDialog, setOpenDialog] = useState(false);
  const [exam, setExam] = useState();
  const { student_id } = useParams();
  const dispatch = useDispatch();
  const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const tabsData = [
    { label: t("Reports"), content: t("Reports content") },
    { label: t("Exams"), content: t("Exams content") },
    { label: t("Bans"), content: t("Bans content") },
    { label: t("Appreciations"), content: t("Appreciations content") },
    { label: t("Guardians"), content: t("Guardians content") },
  ];

  const info = {
    studentId: student_id,
  };

  const formatDataForDataGrid = (data) => {
    return data.map((item) => ({
      id: item.id,
      description: item.description,
      createdAt: item.createdAt,
      name: item.name ? item.name : null,
      type: item.type ? item.type : null,
    }));
  };

  useEffect(() => {
    dispatch(studentData(info));
    if (selectedTab === 0) {
      dispatch(getStudentReports(info));
    }
    if (selectedTab === 1) {
      dispatch(getStudentExams());
    }
    if (selectedTab === 2) {
      dispatch(getStudentBans(info));
    }
    if (selectedTab === 3) {
      dispatch(getstudentAppreciations(info));
    }
    if (selectedTab === 4) {
      dispatch(getstudentGuardians(info));
    }
  }, [dispatch, selectedTab]);

  const student = useSelector((state) => state.student.studentInfo.data);
  const { id, age, isMale, name, phone, username, parent } = student || {};

  const reports = useSelector((state) => state.student.studentReports.data);

  const exams = useSelector((state) => state.student.studentExams.data);

  const bans = useSelector((state) => state.student.studentBans.data);

  const appreciations = useSelector(
    (state) => state.student.studentAppreciations.data
  );

  const guardians = useSelector((state) => state.student.studentGuardians.data);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const { sidebarRTL } = useSidebarContext();

  const idWidth =
    ((selectedTab === 0 &&
      permission.includes("update-report") &&
      permission.includes("delete-report")) ||
      (selectedTab === 2 &&
        permission.includes("update-ban") &&
        permission.includes("delete-ban")) ||
      (selectedTab === 3 &&
        permission.includes("update-appreciation") &&
        permission.includes("delete-appreciation")) ||
      (selectedTab === 4 &&
        permission.includes("update-summon_guardian") &&
        permission.includes("delete-summon_guardian"))) &&
    100;

  const nameWidth =
    ((selectedTab === 0 &&
      permission.includes("update-report") &&
      permission.includes("delete-report")) ||
      (selectedTab === 2 &&
        permission.includes("update-ban") &&
        permission.includes("delete-ban")) ||
      (selectedTab === 3 &&
        permission.includes("update-appreciation") &&
        permission.includes("delete-appreciation")) ||
      (selectedTab === 4 &&
        permission.includes("update-summon_guardian") &&
        permission.includes("delete-summon_guardian"))) &&
    150;

  const createdWidth =
    ((selectedTab === 0 &&
      permission.includes("update-report") &&
      permission.includes("delete-report")) ||
      (selectedTab === 2 &&
        permission.includes("update-ban") &&
        permission.includes("delete-ban")) ||
      (selectedTab === 3 &&
        permission.includes("update-appreciation") &&
        permission.includes("delete-appreciation")) ||
      (selectedTab === 4 &&
        permission.includes("update-summon_guardian") &&
        permission.includes("delete-summon_guardian"))) &&
    100;

  const generateActionsColumn = (selectedTab, permission) => {
    if (
      (selectedTab === 0 &&
        permission.includes("update-report") &&
        permission.includes("delete-report")) ||
      (selectedTab === 2 &&
        permission.includes("update-ban") &&
        permission.includes("delete-ban")) ||
      (selectedTab === 3 &&
        permission.includes("update-appreciation") &&
        permission.includes("delete-appreciation")) ||
      (selectedTab === 4 &&
        permission.includes("update-summon_guardian") &&
        permission.includes("delete-summon_guardian"))
    ) {
      return {
        field: "actions",
        headerName: t("Actions"),
        width: 300,
        renderCell: (params) => (
          <ButtonGroup
            sx={{
              display: "flex",
              flexDirection: sidebarRTL
                ? "row-reverse !important"
                : "row !important",
              alignItems: "center",
            }}
          >
            {selectedTab === 0 && (
              <>
                <EditStudentDetails
                  sx={{
                    background: `${colors.primary[600]}`,
                    mr: !sidebarRTL ? 1 : 0,
                    ml: sidebarRTL ? 1 : 0,
                    display: permission.includes("update-report")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  selectedTab={selectedTab}
                  studentId={student_id}
                  icon={<EditIcon />}
                />
                <RemoveStudentDetails
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-report")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<DeleteForeverIcon />}
                  selectedTab={selectedTab}
                  studentId={student_id}
                />
              </>
            )}
            {selectedTab === 2 && (
              <>
                <EditStudentDetails
                  sx={{
                    background: `${colors.primary[600]}`,
                    mr: !sidebarRTL ? 1 : 0,
                    ml: sidebarRTL ? 1 : 0,
                    display: permission.includes("update-ban")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  selectedTab={selectedTab}
                  studentId={student_id}
                  icon={<EditIcon />}
                />
                <RemoveStudentDetails
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-ban")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<DeleteForeverIcon />}
                  selectedTab={selectedTab}
                  studentId={student_id}
                />
              </>
            )}
            {selectedTab === 3 && (
              <>
                <EditStudentDetails
                  sx={{
                    background: `${colors.primary[600]}`,
                    mr: !sidebarRTL ? 1 : 0,
                    ml: sidebarRTL ? 1 : 0,
                    display: permission.includes("update-appreciation")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  selectedTab={selectedTab}
                  studentId={student_id}
                  icon={<EditIcon />}
                />
                <RemoveStudentDetails
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-appreciation")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<DeleteForeverIcon />}
                  selectedTab={selectedTab}
                  studentId={student_id}
                />
              </>
            )}
            {selectedTab === 4 && (
              <>
                <EditStudentDetails
                  sx={{
                    background: `${colors.primary[600]}`,
                    mr: !sidebarRTL ? 1 : 0,
                    ml: sidebarRTL ? 1 : 0,
                    display: permission.includes("update-summon_guardian")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  selectedTab={selectedTab}
                  studentId={student_id}
                  icon={<EditIcon />}
                />
                <RemoveStudentDetails
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-summon_guardian")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<DeleteForeverIcon />}
                  selectedTab={selectedTab}
                  studentId={student_id}
                />
              </>
            )}
          </ButtonGroup>
        ),
      };
    }
    return null;
  };

  const actionsColumn = generateActionsColumn(selectedTab, permission);

  const examColumns = [
    { field: "id", headerName: t("ID"), width: 200 },
    { field: "name", headerName: t("Name"), width: 400 },
  ];

  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "description",
      headerName: t("Description"),
      width: nameWidth,
    },
    selectedTab === 2
      ? { field: "type", headerName: t("Type"), width: 100 }
      : null,
    {
      field: "createdAt",
      headerName: t("created at"),
      width: createdWidth,
    },
    actionsColumn,
  ].filter(Boolean);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <Box p={2}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <h3>{t("Student Details")}</h3>
            <div dir={sidebarRTL ? "rtl" : "ltr"}>
              <div className="studentDetailsContainer">
                <div>
                  <p>
                    <strong>{t("ID")}:</strong>
                  </p>
                </div>
                <div>
                  <strong>{id}</strong>
                </div>
              </div>
              <div className="studentDetailsContainer">
                <div>
                  <p>
                    <strong>{t("Name")}:</strong>
                  </p>
                </div>
                <div>
                  <strong>{name}</strong>
                </div>
              </div>
              <div className="studentDetailsContainer">
                <div>
                  <p>
                    <strong>{t("Age")}:</strong>
                  </p>
                </div>
                <div>
                  <strong>{age}</strong>
                </div>
              </div>

              <div className="studentDetailsContainer">
                <div>
                  <p>
                    <strong>{t("Gender")}:</strong>
                  </p>
                </div>
                <div>
                  <strong>{isMale ? t("Male") : "Female"}</strong>
                </div>
              </div>

              <div className="studentDetailsContainer">
                <div>
                  <p>
                    <strong>{t("Username")}:</strong>
                  </p>
                </div>
                <div>
                  <strong>{username}</strong>
                </div>
              </div>

              <div className="studentDetailsContainer">
                <div>
                  <p>
                    <strong>{t("Phone")}:</strong>
                  </p>
                </div>
                <div>
                  <strong>{phone}</strong>
                </div>
              </div>
              {parent && (
                <>
                  <div className="studentDetailsContainer">
                    <div>
                      <p>
                        <strong>{t("Parent Name")}:</strong>
                      </p>
                    </div>
                    <div>
                      <strong>{parent.name}</strong>
                    </div>
                  </div>

                  <div className="studentDetailsContainer">
                    <div>
                      <p>
                        <strong>{t("Parent Username")}:</strong>
                      </p>
                    </div>
                    <div>
                      <strong>{parent.username}</strong>
                    </div>
                  </div>

                  <div className="studentDetailsContainer">
                    <div>
                      <p>
                        <strong>{t("Parent Phone")}:</strong>
                      </p>
                    </div>
                    <div>
                      <strong>{parent.phone}</strong>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={17} md={9}>
        <Box p={2}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} gap="10%">
            {isTabletScreen ? (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                orientation="vertical"
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  width: "100%",
                  marginBottom: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& .MuiTab-root": {
                    marginRight: "10px",
                    backgroundColor: "#252525",
                    "&.Mui-selected": {
                      backgroundColor: "#f8f8f8",
                    },
                  },
                }}
              >
                {tabsData.map((tab, index) => (
                  <Tab key={index} label={tab.label} />
                ))}
              </Tabs>
            ) : (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                sx={{
                  // width: "500px",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  "& .MuiTab-root": { marginRight: "10px" },
                  "& .Mui-selected": { backgroundColor: "#f0f0f0" },
                }}
              >
                {tabsData.map((tab, index) => (
                  <Tab key={index} label={tab.label} />
                ))}
              </Tabs>
            )}
          </Box>
          
            <Box
              style={{ height: 400, width: "100%" }}
              sx={{
                "& .MuiButtonGroup-root.MuiButtonGroup-outlined.css-iajp3t-MuiButtonGroup-root":
                  {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: sidebarRTL ? "row-reverse" : "row",
                     gap: sidebarRTL ? "35px" : "0px",
                },
                "& .MuiTabs-flexContainer": {
                  gap:"10% !important"
                },
                "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  justifyContent: sidebarRTL
                    ? "flex-end !important"
                    : "flex-start !important",
                },
                "& .MuiDataGrid-columnHeaderDraggableContainer": {
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                },
                // "& .MuiDataGrid-columnHeaders": {
                //   flexDirection: sidebarRTL
                //     ? "row-reverse !important"
                //     : "row !important",
                // },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  justifyContent: sidebarRTL
                    ? "end !important"
                    : "start !important",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                },
              }}
            >
                <DataGrid
                  loading={loading}
                rows={
                  selectedTab === 0
                    ? formatDataForDataGrid(reports || [])
                    : selectedTab === 1
                    ? formatDataForDataGrid(exams || [])
                    : selectedTab === 2
                    ? formatDataForDataGrid(bans || [])
                    : selectedTab === 3
                    ? formatDataForDataGrid(appreciations || [])
                    : selectedTab === 4
                    ? formatDataForDataGrid(guardians || [])
                    : []
                }
                columns={
                  selectedTab === 1
                    ? !sidebarRTL
                      ? examColumns
                      : examColumns.reverse()
                    : !sidebarRTL
                    ? columns
                    : columns.reverse()
                }
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                pagination
                autoPageSize
                disableSelectionOnClick={true}
                autoHeight
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: t("rowsPerPageText"),
                    dir: sidebarRTL ? "rtl" : "ltr",
                  },
                }}
                onRowClick={(params) => {
                  if (selectedTab === 1) {
                    const exam = exams.find(
                      (item) => item.id === params.row.id
                    );
                    setExam(exam.id);
                    setOpenDialog(true);
                  } else {
                    const id = params.row.id;
                  }
                }}
              />
            </Box>
        </Box>
      </Grid>
      <ExamsComponent
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        exam={exam}
        studentId={student_id}
      />
    </Grid>
  );
};

export default Student_Details;
