import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DefaultButton from "./defaultBtn";
import defaultAPI from "../../axiosInstance";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { getExpenses } from "../../redux/Expenses";
import { Box } from "@mui/system";

const AddExpenses = ({ icon, pageSize, show }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(`${t("this-is-required")}`),
    default_price: Yup.number().required(`${t("this-is-required")}`),
  });
  const info = {
    pageSize,
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      default_price: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await defaultAPI
          .post("/expenses", values)
          .then((res) => res.status === 201 && dispatch(getExpenses(info)))
          .then(() => {
            setOpen(false);
            formik.resetForm();
          });
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <DefaultButton
        show={show}
        handleClick={handleClickOpen}
        text={t("Add")}
      />
      <Dialog
        fullWidth={true}
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Add-More-expenses")}
        </DialogTitle>
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          <Box dispaly="flex" flexDirection="column">
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("Name")}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              placeholder={t("Name")}
              type="text"
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              dir={sidebarRTL ? "rtl" : "ltr"}
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: colors.blueAccent[700],
                  },
              }}
              inputProps={{
                style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
              }}
            />
          </Box>
          <Box dispaly="flex" flexDirection="column">
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("defaultPrice")}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              placeholder={t("defaultPrice")}
              type="number"
              fullWidth
              variant="outlined"
              id="default_price"
              name="default_price"
              value={formik.values.default_price}
              onChange={formik.handleChange}
              error={
                formik.touched.default_price &&
                Boolean(formik.errors.default_price)
              }
              helperText={
                formik.touched.default_price && formik.errors.default_price
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: colors.blueAccent[700],
                  },
              }}
              inputProps={{
                style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: colors.redAccent[500],
              },
            }}
            onClick={() => setOpen(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: colors.greenAccent[400],
              color: colors.primary[300],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 15px",
              "&:hover": {
                backgroundColor: colors.greenAccent[500],
              },
            }}
            disabled={!formik.isValid || formik.isSubmitting}
            onClick={formik.handleSubmit}
            endIcon={icon}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddExpenses;
