import * as React from "react";
import Tooltip from "@mui/material/Tooltip";

const CustomToolTip = (props) => {
  return (
    <Tooltip
      fontSize="18px"
      title={<h6 style={{ fontSize: "18px !important" }}>{props.text}</h6>}
    >
      <span style={{ fontSize: "18px !important" }}>{props.text}</span>
    </Tooltip>
  );
};
export default CustomToolTip;
