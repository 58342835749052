import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth";
import profile from "./profile";
import student from "./student";
import roles from "./responsibility"
import stuff from "./stuff"
import classes from "./classes";
import Grades from "./Grades";
import dashboard from "./dashboard";
import subject from "./subject";
import Subjects from "./Subjects";
import studyPlaneData from "./studypalne";
import showOnePlaneData from "./showOnePlane";
import changePassowrd from "./changePassowrd";
import parents from "./parents";
import socialSpecialist from "./socialSpecialist";
import absence from "./absence";
import Exam from "./Exams";
import FullMarks from "./FullMarks";
import ExamAssignment from "./ExamAssignment";
import Terms from "./Terms";
import Expenses from "./Expenses";
import gradeExpenses from "./gradeExpenses";
import StudentsExpenses from "./StudentsExpenses";
import ExamSchedule from "./ExamSchedule";
import StudentInstallments from "./StudentInstallments";
import currencies from "./currencies";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const persistedReducer = persistReducer(persistConfig, auth);

export const store = configureStore({
  reducer: {
    user: persistedReducer,
    profile: profile,
    student: student,
    dashboard: dashboard,
    classes: classes,
    grades: Grades,
    roles: roles,
    stuff: stuff,
    subject: subject,
    subjects: Subjects,
    studyPlane: studyPlaneData,
    showOnePlane: showOnePlaneData,
    changePassword: changePassowrd,
    parentData: parents,
    socialSpecialist: socialSpecialist,
    absence: absence,
    exams: Exam,
    FullMarks: FullMarks,
    examAssignment: ExamAssignment,
    Terms: Terms,
    Expenses:Expenses,
    gradeExpenses:gradeExpenses,
    StudentsExpenses: StudentsExpenses,
    ExamSchedule: ExamSchedule,
    Installments: StudentInstallments,
    currencies:currencies
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export let persistor = persistStore(store);