import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import defaultAPI from "../axiosInstance";

export const getExpenses = createAsyncThunk(
  "Expenses/getExpenses",
  async (info) => {
    const { pageSize } = info;
    const apiUrl = `/expenses?per_page=${pageSize}`;

    try {
      const res = await defaultAPI.get(apiUrl);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getExpensesDataByPage = (info) => async (dispatch) => {
  try {

    dispatch(getExpenses.pending());
    const response = await defaultAPI.get(`${info.state}&per_page=${info.pageSize}`);
    const data = response.data;

    dispatch(getExpenses.fulfilled(data));
    dispatch(setCurrentPage(info.state));
  } catch (error) {
    dispatch(getExpenses.rejected());
  }
};


const expensesSlice = createSlice({
  name: "Expenses",
  initialState: {
    expensesData: [],
    className: "",
    ExpensesLinks: {
      first: null,
      last: null,
      next: null,
      prev: null
    },
    currentPage: null,
    loading: false,
    error: false,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload.meta.current_page;
    },
  },
  extraReducers: {
    [getExpenses.pending]: (state) => {
      state.loading = true;
    },
    [getExpenses.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.expensesData = action.payload;
      state.ExpensesLinks = action.payload.links;
      state.ExpensesLinks.first = action.payload.links.first;
      state.ExpensesLinks.last = action.payload.links.last;
      state.ExpensesLinks.prev = action.payload.links.prev;
      state.ExpensesLinks.next = action.payload.links.next;
      state.currentPage = action.payload.meta.current_page;
    },
    [getExpenses.rejected]: (state) => {
      state.error = true;
    }
  },
});
export const { setCurrentPage } = expensesSlice.actions;

export default expensesSlice.reducer;
