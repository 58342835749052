import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import defaultAPI from '../axiosInstance'
export const parentData = createAsyncThunk(
    "parent/parentData",
    async (info) => {
        const apiUrl = `/users/parent?per_page=${info.pageSize}`;
        try {
            const res = await defaultAPI.get(apiUrl);
            return res.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
export const parentSearchData = createAsyncThunk(
    "parent/parentSearchData",
    async (info) => {
        const apiUrl = `/users/parent?handle=${info.handle}&per_page=${info.pageSize}`;
        try {
            const res = await defaultAPI.get(apiUrl);
            return res.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
export const parentSelectMenu = createAsyncThunk(
    "parent/parentSelectMenu",
    async () => {
        const apiUrl = `select_menu/parents`;
        try {
            const res = await defaultAPI.get(apiUrl);
            return res.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
export const fetchParentsDataByPage = (info) => async (dispatch) => {
    const { state, pageSize } = info
    try {

        dispatch(parentData.pending());
        const response = await defaultAPI.get(`${state}&per_page=${pageSize}`);
        const data = response.data;

        dispatch(parentData.fulfilled(data));
        dispatch(setCurrentPage(state));
    } catch (error) {
        dispatch(parentData.rejected());
    }
};
export const addParent = createAsyncThunk(
    "parent/addParent",
    async (values, { rejectWithValue, getState }) => {
        try {
            const apiUrl = "/users";
            const response = await defaultAPI.post(apiUrl, values);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data); // Handle errors and return the error data
        }
    }
);
export const ShowParent = createAsyncThunk(
    "parent/showParent",
    async (ID, { getState }) => {
        try {
            const apiUrl = `/users/parent/${ID}`;
            const response = await defaultAPI.get(apiUrl);
            return response.data;
        } catch (error) {
            return (error.response.data);
        }
    }
);
export const editParent = createAsyncThunk(
    "parent/editParent",
    async (info) => {
        try {
            const apiUrl = `/users/${info.id}`;
            const response = await defaultAPI.put(apiUrl, info.values);
            return response.data;
        } catch (error) {
            return
        }
    }
);
export const deleteParent = createAsyncThunk(
    "parent/deletePlane",
    async (ID, { rejectWithValue }) => {
        try {
            const apiUrl = `/users/parent/${ID}`;
            const { data } = await defaultAPI.delete(apiUrl);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data); // Handle errors and return the error data
        }
    }
);
const parentSlice = createSlice({
    name: "parent",
    initialState: {
        parent: [],
        showParent: [],
        ParentMenu: [],
        parentLinks: {
            first: null,
            last: null,
            next: null,
            prev: null
        },
        currentPage: null,
        loading: false,
        error: false,
    },
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload.meta.current_page;
        }, resetShowParent: (state) => {
            state.showParent.data = null;
            state.showParent.loading = false;
            state.showParent.error = false;
        },
    },
    extraReducers: {
        [parentData.pending]: (state) => {
            state.loading = true;
        },
        [parentData.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.parent = action.payload;
            state.parentLinks = action.payload.links;
            state.parentLinks.first = action.payload.links.first;
            state.parentLinks.last = action.payload.links.last;
            state.parentLinks.prev = action.payload.links.prev;
            state.parentLinks.next = action.payload.links.next;
            state.currentPage = action.payload.meta.current_page;
        },
        [parentSearchData.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.parent = action.payload;
            state.parentLinks = action.payload.links;
            state.parentLinks.first = action.payload.links.first;
            state.parentLinks.last = action.payload.links.last;
            state.parentLinks.prev = action.payload.links.prev;
            state.parentLinks.next = action.payload.links.next;
            state.currentPage = action.payload.meta.current_page;
        },
        [parentSelectMenu.rejected]: (state) => {
            state.ParentMenu = [];
        },
        [parentSelectMenu.fulfilled]: (state, action) => {
            state.ParentMenu = action.payload;
        },
        [parentSelectMenu.pending]: (state) => {
            state.ParentMenu = [];
        },
        [parentData.rejected]: (state) => {
            state.error = true;
        },
        [ShowParent.pending]: (state) => {
            state.loading = true;
        },
        [ShowParent.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = false;
            state.showParent = action.payload;
        },
        [ShowParent.rejected]: (state) => {
            state.error = true;
        }
    },
});
export const { setCurrentPage, resetShowParent } = parentSlice.actions;
export default parentSlice.reducer;