import React from "react";
import StaffTable from "./staffTable";

const AdminStaff = () => {
  return (
    <div>
      <StaffTable />
    </div>
  );
};

export default AdminStaff;
