import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormControl, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DefaultButton from "./defaultBtn";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import defaultAPI from "../../axiosInstance";
import { getStudentInfo } from "../../redux/student";
import { getExamAssignment } from "../../redux/ExamAssignment";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const AddExam = ({ icon, subjects, exams, grades, pageSize, show }) => {
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    subjectId: "",
    examId: "",
    studentId: "",
    obtainedMark: "",
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(getStudentInfo());
  }, []);

  const students =
    useSelector((state) => state.student.studentInfo?.data) || [];

  const handleClickOpen = () => {
    setOpen(true);

    // Reset the form data when the dialog is opened
    setFormData({
      subjectId: "",
      examId: "",
      studentId: "",
      obtainedMark: "",
    });
  };

  const handleApiCall = async () => {
    const apiUrl = `/exam_assignment`;
    try {
      await defaultAPI
        .post(apiUrl, {
          student_id: formData.studentId,
          exam_id: formData.examId,
          subject_id: formData.subjectId,
          obtained_mark: formData.obtainedMark,
        })
        .then((res) => {
          //toast.success(res.data.message);
          dispatch(
            getExamAssignment({
              grade_id: grades[0].id,
              subject_id: formData.subjectId,
              exam_id: formData.examId,
              pageSize: pageSize,
            })
          );
        });

      // Reset the form data
      setFormData({
        subjectId: "",
        examId: "",
        studentId: "",
        obtainedMark: "",
      });

      // Close the dialog
      setOpen(false);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box>
      <DefaultButton
        show={show}
        handleClick={handleClickOpen}
        text={t("add-exam")}
      />
      <Dialog
        fullWidth={true}
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "30px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Add")}
        </DialogTitle>
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3, mt: 2 }}
          >
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("Select Students")}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              value={formData.studentId}
              onChange={(e) =>
                setFormData({ ...formData, studentId: e.target.value })
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              {Array.isArray(students) && students.length > 0 ? (
                students.map((student) => (
                  <MenuItem
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    key={student.id}
                    value={student.id}
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                  >
                    {student.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">Loading students...</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3 }}
          >
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("Select Subject")}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              value={formData.subjectId}
              onChange={(e) =>
                setFormData({ ...formData, subjectId: e.target.value })
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              {subjects.map((subject) => (
                <MenuItem
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  key={subject.id}
                  value={subject.id}
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3 }}
          >
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("Select Exam")}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              value={formData.examId}
              onChange={(e) =>
                setFormData({ ...formData, examId: e.target.value })
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              {exams.map((exam) => (
                <MenuItem
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  key={exam.id}
                  value={exam.id}
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  {exam.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            component="label"
            variant="body1"
            fontWeight="bold"
            mb={1}
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {t("obtained-Mark")}
            <span style={{ color: "red" }}>*</span>
          </Typography>

          <TextField
            fullWidth
            placeholder={t("obtained-Mark")}
            value={formData.obtainedMark}
            onChange={(e) =>
              setFormData({ ...formData, obtainedMark: e.target.value })
            }
            sx={{ mb: 3 }}
            dir={sidebarRTL ? "rtl" : "ltr"}
            inputProps={{
              style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: colors.redAccent[500],
              },
            }}
            onClick={() => setOpen(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: colors.greenAccent[400],
              color: colors.primary[300],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 15px",
              "&:hover": {
                backgroundColor: colors.greenAccent[500],
              },
            }}
            onClick={handleApiCall}
            endIcon={icon}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddExam;
