import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import defaultAPI from "../axiosInstance";

export const getExamAssignment = createAsyncThunk(
  "student/examAssignment",
  async ({ subject_id, grade_id, exam_id, class_id, pageSize }) => {
    let apiUrl = `/exam_assignment?subject_id=${subject_id}&exam_id=${exam_id}&grade_id=${grade_id}&per_page=${pageSize}`;
    if (class_id) {
      apiUrl += `&class_id=${class_id}`;
    }

    try {
      const res = await defaultAPI.get(apiUrl);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchExamsDataByPage = (info) => async (dispatch) => {
  const { state, pageSize } = info
  try {

    dispatch(getExamAssignment.pending());
    const response = await defaultAPI.get(`${state}&per_page=${pageSize}`);
    const data = response.data;

    dispatch(getExamAssignment.fulfilled(data));
    dispatch(setCurrentPage(state));
  } catch (error) {
    dispatch(getExamAssignment.rejected());
  }
};
export const getExams = createAsyncThunk("school/exam", async () => {
  const apiUrl = "/select_menu/exams";

  try {
    const res = await defaultAPI.get(apiUrl);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getSubjects = createAsyncThunk("school/subject", async () => {
  const apiUrl = `/select_menu/subjects`;
  try {
    const res = await defaultAPI.get(apiUrl);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const deleteExamAssignment = createAsyncThunk("school/delete/exam", async ({ id }) => {
  const apiUrl = `/exam_assignment/${id}`;
  try {
    const res = await defaultAPI.delete(apiUrl)
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const editExamAssignment = createAsyncThunk("school/delete/exam", async ({ obtained_mark, id }) => {
  const apiUrl = `/exam_assignment/${id}`;

  try {
    const res = await defaultAPI.put(apiUrl, { obtained_mark })
    return res.data;
  } catch (error) {
    throw error;
  }
});

const examAssignmentSlice = createSlice({
  name: "examAssignment",
  initialState: {
    exam_assignment: [],
    exams: [],
    examLinks: {
      first: null,
      last: null,
      next: null,
      prev: null
    },
    currentPage: null,
    subjects: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExamAssignment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExamAssignment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.exam_assignment = action.payload;
        state.examLinks = action.payload.links;
        state.examLinks.first = action.payload.links.first;
        state.examLinks.last = action.payload.links.last;
        state.examLinks.prev = action.payload.links.prev;
        state.examLinks.next = action.payload.links.next;
        state.currentPage = action.payload.meta.current_page;
      })
      .addCase(getExamAssignment.rejected, (state) => {
        state.error = true;
      })
      .addCase(getExams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExams.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.exams = action.payload;
      })
      .addCase(getExams.rejected, (state) => {
        state.error = true;
      })
      .addCase(getSubjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubjects.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.subjects = action.payload;
      })
      .addCase(getSubjects.rejected, (state) => {
        state.error = true;
      })
      .addCase(deleteExamAssignment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteExamAssignment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(deleteExamAssignment.rejected, (state) => {
        state.error = true;
      })
  },
});
export const { setCurrentPage } = examAssignmentSlice.actions;

export default examAssignmentSlice.reducer;