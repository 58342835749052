import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  editExamAssignment,
  getExamAssignment,
} from "../../redux/ExamAssignment";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { Typography } from "@mui/material";

const EditExam = ({
  icon,
  sx,
  obtainedMark,
  id,
  grade_id,
  class_id,
  subject_id,
  exam_id,
  pageSize,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [obtained_mark, setObtainedMark] = useState("");
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();

  const handleClickOpen = () => {
    setOpen(true);
    setObtainedMark(obtainedMark);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = async () => {
    dispatch(editExamAssignment({ obtained_mark, id })).then(() =>
      dispatch(
        getExamAssignment({ grade_id, class_id, subject_id, exam_id, pageSize })
      )
    );
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={sx}
        endIcon={icon}
      >
        {t("edit-exam")}
      </Button>
      <Dialog
        dir={sidebarRTL ? "rtl" : "ltr"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        sx={{
          width: { xs: "100%", md: "50%" },
          margin: "auto",
          padding: "0",
        }}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("edit-exam")}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{t("Edit Exam")}</DialogContentText> */}
          <Typography
            component="label"
            variant="body1"
            fontWeight="bold"
            mb={1}
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {t("obtained-Mark")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            placeholder={t("obtained-Mark")}
            value={obtained_mark}
            dir={sidebarRTL ? "rtl" : "ltr"}
            onChange={(e) => setObtainedMark(e.target.value)}
            inputProps={{
              style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
            }}
          />
        </DialogContent>
        <DialogActions sx={{ margin: "1rem auto" }}>
          <Button variant="contained" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button variant="contained" onClick={handleEdit}>
            {t("Edit")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditExam;
