import Box from "@mui/material/Box";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  colors,
} from "@mui/material";
import { useState } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
export default function Login() {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const token = useSelector((state) => state.user.token) || "";
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    username: yup.string().required("Required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handelSubmit(values);
    },
  });
  const handelSubmit = async (values) => {
    await dispatch(userLogin(values))
      .then(setDisable(true))
      .then((res) => res && setDisable(false))
      .then((res) => console.log(res));
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, [token]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url('/assets/login_img.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        fontWeight: "bold",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            opacity: "0.8",
            textAlign: "center",
            p: 2,
            background:
              "linear-gradient(219.64deg, #4caf50 7.91%, #81c784 92.12%)",
            flexGrow: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            borderRadius: { md: "12px 0px 0px 12px", xs: "12px 12px 0px 0px" },
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold", color: "#fff" }}>
            Welcome to ..
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "11px",
            }}
          >
            <img src="/assets/left_login_logo.png" alt="logo" width="40px" />
            <Typography
              variant="h1"
              sx={{
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              AlGhadir
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff" }}>
            System for managing students' notes
            <br /> at various educational stages
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            opacity: "0.8",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 3,
            minWidth: { xs: "100%", md: 480 },
            minHeight: { xs: "100%", md: 480 },
            bgcolor: "#fff",
            flex: 1,
            borderRadius: { md: "0px 12px 12px 0px", xs: "0px 0px 12px 12px" },
            justifyContent: "center",
          }}
        >
          {/* <img src="/assets/educator_icons.png" alt="icon" /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "11px",
              marginBottom: "30px",
            }}
          >
            <img src="/assets/left_login_logo.png" alt="logo" width="30px" />
            <Typography
              variant="h1"
              sx={{
                fontWeight: "bold",
                color: colors.green[500],
              }}
            >
              AlGhadir
            </Typography>
          </Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              color: colors.green[700],
              marginBottom: "10px",
            }}
          >
            Zone 4
          </Typography>

          <OutlinedInput
            required
            type="text"
            placeholder="username"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.username}
            name="username"
            error={!!formik.touched.username && !!formik.errors.username}
            helperText={formik.touched.username && formik.errors.username}
            sx={{
              color: "#000",
              width: "100%",
              marginTop: "16px",
              borderRadius: "5px",
              border: "1px solid #000",
              outline: "none",
              fontWeight: "bold",
              fontSize: "17px",
            }}
          />
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            name="password"
            required
            error={!!formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{ color: "#000" }}
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            sx={{
              color: "#000",
              width: "100%",
              marginTop: "16px",
              borderRadius: "5px",
              border: "1px solid #000",
              outline: "none",
              fontWeight: "bold",
              fontSize: "17px",
            }}
          />
          <button
            type="submit"
            style={{
              width: "45%",
              padding: "12px",
              marginTop: "16px",
              borderRadius: "25px",
              fontSize: "17px",
              background:
                "linear-gradient(219.64deg, #4caf50 7.91%, #81c784 92.12%)",
              color: "#fff",
              fontWeight: "bold",
              border: "none",
              cursor: "pointer",
            }}
            disabled={disable}
          >
            {disable ? "wait..." : "Sign In"}
          </button>
        </Box>
      </Box>
    </Box>
  );
}
