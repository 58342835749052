import React, { useState, useEffect } from "react";
import { Box, ButtonGroup, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import EditClass from "./EditClass";
import RemoveClassBtn from "./deleteClass";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import AddClass from "./addClass";
import { useDispatch, useSelector } from "react-redux";
import { getClasses, getClassessDataByPage, getClassesSearch } from "../../redux/classes";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";

const ClassesTable = () => {
  const nextPage = useSelector((state) => state.classes.classesLinks.next);
  const lastPage = useSelector((state) => state.classes.classesLinks.last);
  const prevPage = useSelector((state) => state.classes.classesLinks.prev);
  const firstPage = useSelector((state) => state.classes.classesLinks.first);
  const currentPage = useSelector((state) => state.classes.currentPage);
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const info = { pageSize };
  const { sidebarRTL } = useSidebarContext();

  useEffect(() => {
    dispatch(getClasses(info));
  }, [dispatch, pageSize]);

  const data = useSelector((state) => state?.classes?.classes?.data) || [];
  const loading = useSelector((state) => state?.classes?.loading);

  const nameWidth =
    permission.includes("update-class") && permission.includes("delete-class")
      ? 350
      : 700;
  const idWidth =
    permission.includes("update-class") && permission.includes("delete-class")
      ? 250
      : 480;

  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    ...(permission.includes("update-class") &&
    permission.includes("delete-class")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 400,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditClass
                  sx={{
                    background: `${colors.primary[600]}`,
                    m: 4,
                    display: permission.includes("update-class")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<EditIcon />}
                  pageSize={pageSize}
                />
                <RemoveClassBtn
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-class")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  pageSize={pageSize}
                  icon={<DeleteForeverIcon />}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  const tableData = {
    rows:data.length>0 && data,
    columns:columns,
    loading:loading===true,
    pageSize:pageSize,
    onPageSizeChange:(newPageSize) => setPageSize(newPageSize)
  }
  return (
    <Box m="20px">
      <Box
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("All_Classes")} />
        <AddClass
          show={permission.includes("store-class")}
          pageSize={pageSize}
          icon={<AddIcon />}
        />
      </Box>
        <CustomTableBox
        tableData={tableData}
        action={getClassesSearch}
          CustomPagenation={
            <CustomPagenation
              pageSize={pageSize}
              nextPage={nextPage}
              lastPage={lastPage}
              prevPage={prevPage}
              firstPage={firstPage}
              currentPage={currentPage}
              action={getClassessDataByPage}
            />
          }
          />
    </Box>
  );
};
export default ClassesTable;