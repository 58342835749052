import React, { useState, useEffect } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ENGIMG from "./flags/us.svg";
import Lebanon from "./flags/lebanon-flag-icon.svg";
import FRENCHIMG from "./flags/french.svg";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../sidebar/sidebarContext";
import { tokens } from "../../../theme";
import "./Style/style.css";
import { memo } from "react";
import { useTheme } from "@mui/material";

const getFlagImage = (language) => {
  switch (language) {
    case "en":
      return ENGIMG;
    case "ar":
      return Lebanon;
    case "fr":
      return FRENCHIMG;
    default:
      return ENGIMG;
  }
};

const Languages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { i18n } = useTranslation();
  const { sidebarRTL, setSidebarRTL } = useSidebarContext();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") || "en"
  );
  const [flagImage, setFlagImage] = useState(
    getFlagImage(localStorage.getItem("lang") || "en")
  );

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      setFlagImage(getFlagImage(lang));
      lang === "ar" && setSidebarRTL(true);
    }
  }, [setSidebarRTL]);

  const handleLanguageChange = (language) => {
    if (language !== i18n.language) {
      localStorage.setItem("lang", language);
      i18n.changeLanguage(language);
      const isRTL = language === "ar";
      setSidebarRTL(isRTL);
      setSelectedLanguage(language);
      setFlagImage(getFlagImage(language));
      setDropdownOpen(false);
    }
  };

  return (
    <UncontrolledDropdown
      tag="li"
      className="dropdown-user nav-item"
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => {
          e.preventDefault();
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <img
          src={flagImage}
          alt="Current Language"
          className="language-flag"
          style={{ width: 24, height: 24 }}
        />
      </DropdownToggle>
      <DropdownMenu
        end
        style={{ background: colors.blueAccent[700], color: colors.grey[100] }}
      >
        <div className="lang" onClick={() => handleLanguageChange("en")}>
          <img src={ENGIMG} alt="ENG" style={{ width: 24, height: 24 }} />
          <span>English</span>
        </div>
        <div className="lang" onClick={() => handleLanguageChange("ar")}>
          <img src={Lebanon} alt="AR" style={{ width: 24, height: 24 }} />
          <span>Arabic</span>
        </div>
        <div className="lang" onClick={() => handleLanguageChange("fr")}>
          <img src={FRENCHIMG} alt="FR" style={{ width: 24, height: 24 }} />
          <span>Français</span>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default memo(Languages);
