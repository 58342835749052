import React, { useState, useEffect } from "react";
import { Box, ButtonGroup, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import EditClass from "./EditCurrencies";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import { getCurrencies } from "../../redux/currencies";
import AddCurrencies from "./AddCurrencies";
import RemoveCurrencies from "./RemoveCurrencies";
import ActiveCurrenciesSwitch from "./switch";

const CurrenciesTable = () => {
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const info = { pageSize };
  const { sidebarRTL } = useSidebarContext();

  useEffect(() => {
    dispatch(getCurrencies(info));
  }, [dispatch, pageSize]);

  const data = useSelector((state) => state?.currencies?.currenciesData?.data) || [];
  const loading = useSelector((state) => state?.classes?.loading);
  const nameWidth =
    permission.includes("update-class") && permission.includes("delete-class")
      ? 250
      : 700;
  const idWidth =
    permission.includes("update-class") && permission.includes("delete-class")
      ? 180
      : 480;

  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    {
      field: "status",
      headerName: t("default_currency"),
      width: 180,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <ActiveCurrenciesSwitch
          isActive={params.row.status}
          id={params.id}
          disabled={params.row.status && true}
        />
      )
    },
    ...(permission.includes("update-class") &&
    permission.includes("delete-class")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 400,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditClass
                  sx={{
                    background: `${colors.primary[600]}`,
                    m: 4,
                    display: permission.includes("update-class")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<EditIcon />}
                  pageSize={pageSize}
                />
                <RemoveCurrencies
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-class")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  pageSize={pageSize}
                  icon={<DeleteForeverIcon />}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  const tableData = {
    rows:data.length>0 && data,
    columns:columns,
    loading:loading===true,
    pageSize:pageSize,
    onPageSizeChange:(newPageSize) => setPageSize(newPageSize)
  }
  return (
    <Box m="20px">
      <Box
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("currencies")} />
        <AddCurrencies
          show={permission.includes("store-class")}
          pageSize={pageSize}
          icon={<AddIcon />}
        />
      </Box>
        <CustomTableBox
          tableData={tableData}
          />
    </Box>
  );
};
export default CurrenciesTable;