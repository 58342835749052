import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { getStudents, SearchStudents } from "../../redux/student";
import DeleteStudents from "./DeleteStudent";
import DefaultButton from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import Pagenation from "./pagenation";
import "./studentForm.css";
import CustomSearch from "../../components/customSearch/customSearch";

const StudentsTable = () => {
  const [pageSize, setPageSize] = useState(10);
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  useEffect(() => {
    dispatch(getStudents(pageSize));
  }, [dispatch, pageSize]);

  const data = useSelector((state) => state?.student?.students?.data) || {};
  const loading = useSelector((state) => state?.student?.loading);

  const columns = [
    { field: "id", headerName: t("ID"), width: 120 },
    {
      field: "name",
      headerName: t("Name"),
      width: 180,
      cellClassName: "name-column--cell",
    },
    {
      field: "username",
      headerName: t("Username"),
      width: 180,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: t("Phone"),
      width: 180,
      cellClassName: "name-column--cell",
    },
    {
      field: "schoolStudentID",
      headerName: t("School Student Id"),
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "isMale",
      headerName: t("Gender"),
      width: 100,
      cellClassName: "name-column--cell",
      renderCell: (params) => <>{params.value ? t("Male") : t("Female")}</>,
    },
    {
      field: "actions",
      headerName: t("Actions"),
      width: 250,
      renderCell: (params) => (
        <ButtonGroup
          sx={{
            display: "flex",
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
            alignItems: "center",
          }}
        >
          <Link
            to={`/edit-student/${params.id}`}
            style={{
              display: permission.includes("update-student")
                ? "inline-flex"
                : "none",
              textDecoration: "none",
            }}
          >
            <Button
              sx={{
                m: 2,
                backgroundColor: `${colors.primary[600]}`,
              }}
              variant="contained"
              color="primary"
              endIcon={<EditIcon />}
              pageSize={pageSize}
              id={params.id}
            >
              {t("Edit")}
            </Button>
          </Link>
          <DeleteStudents
            sx={{
              background: `${colors.redAccent[600]}`,
              display: permission.includes("delete-student")
                ? "inline-flex"
                : "none",
            }}
            pageSize={pageSize}
            id={params.id}
            icon={<DeleteForeverIcon />}
          />
        </ButtonGroup>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("Students")} />
        <Link
          to="/add-student"
          style={{
            display: permission.includes("store-student")
              ? "inline-flex"
              : "none",
          }}
        >
          <ButtonGroup>
            <DefaultButton
              show={permission.includes("store-student")}
              text={t("Add")}
            />
          </ButtonGroup>
        </Link>
      </Box>
      <Box
        m="8px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
            fontWeight: "bold",
            fontSize: "20px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiButtonGroup-root.MuiButtonGroup-outlined.css-iajp3t-MuiButtonGroup-root":
            {
              display: "flex",
              alignItems: "center",
              flexDirection: sidebarRTL ? "row-reverse" : "row",
              gap: sidebarRTL ? "35px" : "0px",
            },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
            justifyContent: sidebarRTL
              ? "flex-end !important"
              : "flex-start !important",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: sidebarRTL ? "end !important" : "start !important",
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
        }}
      >
        <CustomSearch action={SearchStudents} pageSize={pageSize} />
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
            }}
            rows={data}
            autoHeight
            disableSelectionOnClick={true}
            pageSize={pageSize}
            componentsProps={{
              pagination: {
                labelRowsPerPage: t("rowsPerPageText"),
                dir: sidebarRTL ? "rtl" : "ltr",
              },
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15]}
            columns={sidebarRTL ? columns.reverse() : columns}
          />
        )}
        <Pagenation pageSize={pageSize} />
      </Box>
    </Box>
  );
};

export default StudentsTable;
