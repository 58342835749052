import React, { useEffect, useMemo } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getGrades, getSubjectInfo } from "../../redux/Subjects";

const SubjectInfoPopUp = ({ subject, open, onClose }) => {
  const info = { id: subject.id };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubjectInfo(info));
    dispatch(getGrades());
  }, [dispatch, subject.id]);

  const loading = useSelector((state) => state.subjects.loading);
  const subject_info = useSelector((state) => state.subjects.subjectInfo?.data);
  const gradesData = useSelector((state) => state.subjects.grades?.data);

  const mapClassIdToName = (gradeIDS, gradesData) => {
    return gradeIDS.map((classId) => {
      const gradeInfo = gradesData.find(
        (gradeItem) => gradeItem.id === classId
      );
      return gradeInfo ? gradeInfo.name : null;
    });
  };

  const gradeNames = useMemo(
    () => mapClassIdToName(subject_info?.grades || [], gradesData || []),
    [subject_info?.grades, gradesData]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        width: { xs: "100%", md: "50%" },
        margin: "auto",
        padding: "0",
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }}>
        {subject_info?.name} Subject Information
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loading && subject_info && (
          <div>
            <p>Subject ID: {subject_info.id}</p>
            <p>Subject Name: {subject_info.name}</p>
            {gradeNames.length > 0 ? (
              gradeNames.map((gradename, index) => (
                <div key={index}>
                  <p>Grade Name: {gradename}</p>
                </div>
              ))
            ) : (
              <p>No grade information available</p>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SubjectInfoPopUp;
