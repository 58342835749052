import { useTranslation } from "react-i18next";
// import { useSidebarContext } from '../global/sidebar/sidebarContext';
import defaultAPI from "../../axiosInstance";
import CallIcon from "@mui/icons-material/Call";
import "./InvoiceStyle.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Link } from "react-router-dom"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
export const InstallmentGradeInvoice = () => {
  const { grade_id } = useParams();

  const { t } = useTranslation();
  const [invoiceGradeData, setInvoiceData] = useState(null);
  useEffect(() => {
    defaultAPI
      .get(`exports/grades/${grade_id}/grade_expenses`)
      .then((res) => setInvoiceData(res.data.data));
  }, [invoiceGradeData === null]);
  let totalPaidExpenses = 0;

  if (invoiceGradeData && invoiceGradeData.gradeExpenses) {
    totalPaidExpenses = invoiceGradeData.gradeExpenses
      .map(function (item) {
        return parseFloat(
          item.price.split(" ")[0].replace(/,/g, "")
        );
      })
      .reduce((number, numberTwo) => {
        return number + numberTwo;
      }, 0);
  }

  const [printing, setPrinting] = useState(false);

  const handlePrintClick = () => {
    // Set the printing state to true when the print button is clicked
    setPrinting(true);
  
    // Trigger the browser's print dialog after a brief delay
    setTimeout(() => {
      window.print();
      
      // After printing, set the printing state back to false
      setPrinting(false);
    }, 100); // Adjust the delay time as needed
  };

  let lang = localStorage.getItem("lang")
  if (lang === null) {
    localStorage.setItem("lang","en")
  }
    return (
        <div dir={localStorage.getItem("lang")==="ar"?"rtl":"ltr"} className="invoiceContainer">
        <div className="buttonContainer">
          <Link to={`/showGradeExpenses`}>
            <button style={{display:printing?"none":"inline-block"}}>
              <KeyboardBackspaceIcon />
            </button>
          </Link>
          <button style={{display:printing?"none":"inline-block"}} onClick={handlePrintClick}>
            <LocalPrintshopIcon />
          </button>
        </div>

      <div
        dir={localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"}
        className="header-section"
      >
        <div
          dir={localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"}
          className="student-info"
        >
          {t("grade Name")}: {invoiceGradeData && invoiceGradeData.grade}
        </div>
        <div className="school-info">
          <img
            src={`${invoiceGradeData && invoiceGradeData.schoolInfo.logo}`}
            alt="School Logo"
            className="school-logo"
          />
          <div className="school-name">
            {invoiceGradeData && invoiceGradeData.schoolInfo.title}
          </div>
        </div>
      </div>
      <table className="outer-table">
        <thead>
          <tr>
              <th className="header">{ t("ID")}</th>
              <th className="header">{t("expense_name")}</th>
              <th className="header">{t("Expenses")}</th>
          </tr>
        </thead>
        <tbody>
          {invoiceGradeData &&
            invoiceGradeData.gradeExpenses.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.expense}</td>
                <td>{item.price}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan="2" className="total-expenses">
              {t("total_paid_expenses")}
            </th>
            <td colSpan="2">
              {totalPaidExpenses} {invoiceGradeData && invoiceGradeData.currency}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="footer-section">
        <div className="social-media-section">
          <div className="Phones">
            <div className="IconDiv">
              <CallIcon
                sx={{
                  fontSize: "30px",
                  margin: "auto",
                }}
                className="icon"
              />
            </div>
            <div classNames="dataContainer">
              <a disabled="true">
                {invoiceGradeData && invoiceGradeData.schoolInfo.phone}
              </a>
            </div>
          </div>
          <div className="Links">
            <div className="IconDiv">
              <LocationOnIcon
                className="icon"
                sx={{
                  fontSize: "30px",
                  margin: "auto",
                }}
              />
            </div>
            <div classNames="dataContainer">
              <a disabled="true">
                {invoiceGradeData && invoiceGradeData.schoolInfo.address}
              </a>
            </div>
          </div>
          <div className="location">
            <div className="IconDiv">
              <PublicIcon
                sx={{
                  fontSize: "30px",
                  margin: "auto",
                }}
                className="icon"
              />
            </div>
            <div classNames="dataContainer">
              <a disabled="true">
                {invoiceGradeData && invoiceGradeData.schoolInfo.email}
              </a>
              <br />
              <a disabled="true">
                {invoiceGradeData && invoiceGradeData.schoolInfo.facebook}
              </a>
              <br />
              <a disabled="true">
                {invoiceGradeData && invoiceGradeData.schoolInfo.whatsapp}
              </a>
              <br />
              <a disabled="true">
                {invoiceGradeData && invoiceGradeData.schoolInfo.telegram}
              </a>
            </div>
          </div>
        </div>
        <div className="contact-info">
          <p>
                        {t("contact_us")}{" "}
            <span>{invoiceGradeData && invoiceGradeData.schoolInfo.email}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
