import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux"; // Import the useDispatch hook
import { useTranslation } from "react-i18next";
import { editFullMark, fetchFullMarkData } from "../../redux/FullMarks";
import EditIcon from "@mui/icons-material/Edit";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
const EditFullMark = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch(); // Get the dispatch function
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const loading = useSelector((state) => state.FullMarks.loading);
  const checkoutSchema = yup.object().shape({
    full_mark: yup.number().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      full_mark: props.fullMark,
    },
    validationSchema: checkoutSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFormSubmit = async (values) => {
    let markID = props.ID;
    const markInfo = {
      values: values,
      id: markID,
    };
    const info = {
      id: props.examId,
    };
    await dispatch(editFullMark(markInfo));
    await dispatch(fetchFullMarkData(info));
    await handleClose();
  };
  const { sidebarRTL } = useSidebarContext();

  return (
    <Box m="20px">
      <Button
        variant="contained"
        sx={props.sx}
        onClick={handleClickOpen}
        endIcon={props.icon}
      >
        {t("Edit")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("edit-full-mark")}
        </DialogTitle>
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("Full Mark")}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              placeholder={t("Full Mark")}
              dir={sidebarRTL ? "rtl" : "ltr"}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.full_mark}
              name="full_mark"
              error={!!formik.touched.full_mark && !!formik.errors.full_mark}
              helperText={formik.touched.full_mark && formik.errors.full_mark}
              sx={{ gridColumn: "span 2" }}
              inputProps={{
                style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
              }}
            />
            <Box display="flex" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.greenAccent[400],
                  color: colors.primary[300],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 15px",
                  "&:hover": {
                    backgroundColor: colors.greenAccent[500],
                  },
                }}
                disabled={loading ? true : false}
                variant="outlined"
                onClick={handleClickOpen}
                endIcon={<EditIcon />}
              >
                {loading ? "wait..." : t("Edit")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditFullMark;
