import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import defaultAPI from "../axiosInstance";
export const userProfile = createAsyncThunk("users/profile", async () => {
  const apiUrl = "/auth/profile";
  try {
    const res = await defaultAPI.get(apiUrl);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const schoolProfile = createAsyncThunk(
  "school/profile",
  async () => {
    const apiUrl = "/settings";

    try {
      const res = await defaultAPI.get(apiUrl);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const editSchoolProfile = createAsyncThunk(
  "school/edit/profile",
  async (values, { rejectWithValue }) => {
    const apiUrl = "/settings";

    try {
      const res = await defaultAPI.post(apiUrl, values);
      return res.data;
    } catch (error) {
      // Handle the error, for example:
      // dispatch(setError(errorMessage)); // setError is an action to set the error message
      return rejectWithValue(error.response.data); // Pass the error data to the rejection action
    }
  }
);


// export const editSchoolProfile = createAsyncThunk(
//   "school/edit/profile",
//   async (values) => {
//     // const {
//     //   title,
//     //   email,
//     //   phone,
//     //   address,
//     //   facebook,
//     //   whatsapp,
//     //   telegram
//     // } = values;
//     const apiUrl = "/settings";

//     try {
//       const res = await defaultAPI.post(
//         apiUrl,
//         values
//       );
//       return res.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    userInfo: [],
    schoolInfo: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(userProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.userInfo = action.payload;
      })
      .addCase(userProfile.rejected, (state) => {
        state.error = true;
      })
      .addCase(schoolProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(schoolProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.schoolInfo = action.payload;
      })
      .addCase(schoolProfile.rejected, (state) => {
        state.error = true;
      })
      .addCase(editSchoolProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(editSchoolProfile.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(editSchoolProfile.rejected, (state) => {
        state.error = true;
      });
  },
});

export default profileSlice.reducer;
