import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  FormControl,
  Button,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import AddGradeExpenses from "./AddGradeExpenses";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import { getFullMarkGrades } from "../../redux/FullMarks";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";
import {
  getOneGradeExpenses,
  getOneGradeExpensesByPage,
} from "../../redux/gradeExpenses";
import DeleteGradeExpenses from "./DeleteGradeExpenses";
import EditGradeExpenses from "./EditGradeExpenses";
import { Link } from "react-router-dom";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

const OneGradeExpenses = () => {
  const nextPage = useSelector(
    (state) => state.gradeExpenses.OneGradeExpensesLinks.next
  );
  const lastPage = useSelector(
    (state) => state.gradeExpenses.OneGradeExpensesLinks.last
  );
  const prevPage = useSelector(
    (state) => state.gradeExpenses.OneGradeExpensesLinks.prev
  );
  const currentPage = useSelector(
    (state) => state.gradeExpenses.OneGradeExpensesCurrentPage
  );
  const firstPage = useSelector(
    (state) => state.gradeExpenses.OneGradeExpensesLinks.first
  );

  useSelector((state) => state.gradeExpenses.OneGradeExpensesLinks);
  const { sidebarRTL } = useSidebarContext();
  const [selectedGradeId, setSelectedGradeId] = useState(null);
  const [SelectedGradeName, setSelectedGradeName] = useState("");

  const AllGrades =
    useSelector((state) => state.FullMarks.getGradesData.data) || [];

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);

  const loading = useSelector((state) => state.gradeExpenses.loading);

  const getGradeExpenses = useSelector(
    (state) => state.gradeExpenses.OnegradeExpensesData.data || []
  );
  const theme = useTheme();

  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(getFullMarkGrades());
  }, [dispatch]);

  useEffect(() => {
    if (AllGrades.length > 0 && !selectedGradeId) {
      setSelectedGradeId(AllGrades[0].id);
      setSelectedGradeName(AllGrades[0].name);
    }
  }, [AllGrades]);

  useEffect(() => {
    const info = {
      id: selectedGradeId,
      pageSize: pageSize,
    };
    dispatch(getOneGradeExpenses(info));
  }, [selectedGradeId, pageSize]);

  const handleSelectChangeGrade = (event) => {
    const selectedGradeId = event.target.value; // Get the selected grade ID
    setSelectedGradeId(selectedGradeId); // Update the selected grade ID in state

    const selectedGrade = AllGrades.find(
      (grade) => grade.id === selectedGradeId
    );
    if (selectedGrade) {
      setSelectedGradeName(selectedGrade.name); // Update the selected grade name in state
    }
  };

  const expense =
    permission.includes("update-grade_expenses") &&
    permission.includes("delete-grade_expenses")
      ? 250
      : 400;
  const columns = [
    { field: "id", headerName: t("ID"), width: 120 },
    {
      field: "expense",
      headerName: t("expense"),
      width: expense,
      cellClassName: "name-column--cell",
    },

    { field: "price", headerName: t("amount"), width: 180 },
    ...(permission.includes("delete-grade_expenses") &&
    permission.includes("update-grade_expenses")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 400,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditGradeExpenses
                  sx={{
                    backGround: `${colors.grey[600]}`,
                    display: permission.includes("update-grade_expenses")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<EditIcon />}
                  gradeExpensesId={selectedGradeId}
                  price={params.row.price}
                  pageSize={pageSize}
                />
                <DeleteGradeExpenses
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-grade_expenses")
                      ? "inline-flex"
                      : "none",
                  }}
                  icon={<DeleteIcon />}
                  id={params.id}
                  gradeExpensesId={selectedGradeId}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];

  const tableData = {
    rows: getGradeExpenses.length > 0 && getGradeExpenses,
    columns: columns,
    loading: loading && true,
    pageSize: pageSize,
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        dir={sidebarRTL ? "rtl" : "ltr"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Header title={t("OneGradeExpenses")} />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={sidebarRTL ? "row" : "row-reverse"}
      >
        <Link
          style={{
            margin: "20px",
          }}
          to={`/gradeInvoice/${selectedGradeId}`}
        >
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            variant="outlined"
            endIcon={<LocalPrintshopIcon />}
          >
            {t("PDF")}
          </Button>
        </Link>
        <AddGradeExpenses
          show={
            permission.includes("store-grade_expenses") ? "inline-flex" : "none"
          }
          pageSize={pageSize}
          gradeExpensesId={selectedGradeId}
        />
        <FormControl variant="outlined">
          <label style={{ fontSize: "18px", fontWeight: "bold" }}>
            {t("Grade")}
          </label>
          <Select
            fullWidth
            value={selectedGradeId || ""}
            onChange={handleSelectChangeGrade}
            dir={sidebarRTL ? "rtl" : "ltr"}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                color: "#000",
                fontSize: "20px",
                fontWeight: "bold",
                direction: sidebarRTL ? "rtl" : "ltr",
                maxHeight: "150px",
                overflowY: "auto",
                overflowX: "hidden",
              }),
              container: (provided) => ({
                ...provided,
                zIndex: 9999,
                direction: sidebarRTL ? "rtl" : "ltr",
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
                direction: sidebarRTL ? "rtl" : "ltr",
              }),
            }}
          >
            {AllGrades &&
              AllGrades.map((gradeItem) => (
                <MenuItem
                  key={gradeItem.id}
                  value={gradeItem.id}
                  sx={{ fontSize: "17px" }}
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  {gradeItem.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <CustomTableBox
        tableData={tableData}
        CustomPagenation={
          <CustomPagenation
            pageSize={pageSize}
            nextPage={nextPage}
            lastPage={lastPage}
            prevPage={prevPage}
            currentPage={currentPage}
            firstPage={firstPage}
            action={getOneGradeExpensesByPage}
          />
        }
      />
    </Box>
  );
};
export default OneGradeExpenses;
