import React from "react";
import ExamsTable from "./examsTable";
const Exams = () => {
  return (
    <div>
      <ExamsTable />
    </div>
  );
};

export default Exams;
