import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormControl, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DefaultButton from "./defaultBtn";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import defaultAPI from "../../axiosInstance";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { getGradesMenu } from "../../redux/Grades";
import { subjectDataGradeId } from "../../redux/subject";

const AddExelFile = ({ icon, exams, grades, show }) => {
  const subjects = useSelector((state) => state.subject.subject.data) || [];
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    subjectId: "",
    examId: "",
    gradeId: "",
    exelFile: null, // Change the initial value to null
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(getGradesMenu());
  }, []);
  useEffect(() => {
    dispatch(subjectDataGradeId(formData.gradeId));
  }, [formData.gradeId]);
  const handleClickOpen = () => {
    setOpen(true);

    // Reset the form data when the dialog is opened
    setFormData({
      subjectId: "",
      examId: "",
      gradeId: "",
      exelFile: null, // Reset the file input
    });
  };

  const handleApiCall = async () => {
    if (loading) {
      return; // If loading is true, do nothing (prevent multiple clicks)
    }

    // Check if a file is selected and it has a valid Excel file extension
    if (!formData.exelFile) {
      // Show an error message or handle it as needed
      console.error("Please select a file.");
      return;
    }
    const allowedExtensions = [".xls", ".xlsx"];
    const fileName = formData.exelFile.name;
    const fileExtension = fileName
      .substring(fileName.lastIndexOf("."))
      .toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      // Show an error message or handle it as needed
      setMessage("Please select a valid Excel file (xls or xlsx).");
      return;
    }

    setLoading(true); // Set loading state to true

    const apiUrl = `/imports/exam_assignments`;
    try {
      const formDataObj = new FormData(); // Create a FormData object
      formDataObj.append("grade_id", formData.gradeId);
      formDataObj.append("exam_id", formData.examId);
      formDataObj.append("subject_id", formData.subjectId);
      formDataObj.append("marks", formData.exelFile); // Append the file to the FormData object

      await defaultAPI.post(apiUrl, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Reset the form data
      setFormData({
        subjectId: "",
        examId: "",
        gradeId: "",
        exelFile: null, // Reset the file input
      });

      // Close the dialog
      setOpen(false);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false); // Set loading state back to false
    }
  };

  return (
    <Box>
      <DefaultButton
        show={show}
        handleClick={handleClickOpen}
        text={t("upload_students_marks")}
      />
      <Dialog
        fullWidth={true}
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "30px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Add")}
        </DialogTitle>
        <DialogContent>
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3, mt: 2 }}
          >
            <label
              style={{
                fontSize: "18px",
                marginBottom: ".5rem",
                fontWeight: "bold",
              }}
            >
              {t("Select Grade")}
            </label>
            <Select
              value={formData.gradeId}
              onChange={(e) =>
                setFormData({ ...formData, gradeId: e.target.value })
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              {Array.isArray(grades) && grades.length > 0 ? (
                grades.map((gradeId) => (
                  <MenuItem
                    key={gradeId.id}
                    value={gradeId.id}
                    sx={{ fontWeight: "bold", fontSize: "18px" }}
                  >
                    {gradeId.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">Loading grades...</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3 }}
          >
            <label
              style={{
                fontSize: "18px",
                marginBottom: ".5rem",
                fontWeight: "bold",
              }}
            >
              {t("Select Subject")}
            </label>
            <Select
              value={formData.subjectId}
              onChange={(e) =>
                setFormData({ ...formData, subjectId: e.target.value })
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              {subjects.length > 0 &&
                subjects.map((subject) => (
                  <MenuItem
                    key={subject.id}
                    value={subject.id}
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                  >
                    {subject.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3 }}
          >
            <label
              style={{
                fontSize: "18px",
                marginBottom: ".5rem",
                fontWeight: "bold",
              }}
            >
              {t("Select Exam")}
            </label>
            <Select
              value={formData.examId}
              onChange={(e) =>
                setFormData({ ...formData, examId: e.target.value })
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              {exams.map((exam) => (
                <MenuItem
                  key={exam.id}
                  value={exam.id}
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  {exam.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <label
            htmlFor="fileInput"
            dir={sidebarRTL ? "rtl" : "ltr"}
            style={{
              display: "block",
              fontSize: "18px",
              marginBottom: ".5rem",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {t("Select File")}
          </label>
          <input
            type="file"
            id="fileInput"
            onChange={(e) =>
              setFormData({ ...formData, exelFile: e.target.files[0] })
            } // Store the selected file in the state
            style={{ display: "none" }} // Hide the default file input
          />
          <div
            dir={sidebarRTL ? "rtl" : "ltr"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",

                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                variant="outlined"
                value={formData.exelFile ? formData.exelFile.name : ""}
                placeholder={t("File")}
                InputProps={{
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    borderRight: "50px",
                  },
                }}
                disabled
                style={{ width: "83%" }}
              />
              <Button
                variant="contained"
                component="label"
                htmlFor="fileInput" // Link the button to the hidden file input
                style={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "15px 0",

                  width: "15%",
                }}
              >
                {t("Browse")}
              </Button>
            </div>
          </div>

          {/* <div style={{ color: "white" }}>
            <input
              style={{
                background: "transparent",
                borderColor: "gray",
                color: "white",
              }}
              onChange={(e) =>
                setFormData({ ...formData, exelFile: e.target.files[0] })
              } // Store the selected file in the state
              className="form-control form-control-lg"
              id="formFileLg"
              type="file"
            />
          </div> */}

          <p
            dir={sidebarRTL ? "rtl" : "ltr"}
            style={{
              color: "red",
              margin: ".5rem",
              fontSize: "16px",
              fontWeight: "bold",
              direction: sidebarRTL ? "rtl" : "ltr",
            }}
          >
            {t(`${message}`)}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => setOpen(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 15px",
            }}
            onClick={handleApiCall}
            endIcon={icon}
            disabled={loading} // Disable the button when loading is true
          >
            {loading ? t("Loading...") : t("Add")}{" "}
            {/* Display "Loading..." when loading, or "Add" when not loading */}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddExelFile;
