import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { editTerm, TermsData } from "../../redux/Terms";
import dayjs from "dayjs";

const EditTerm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const loading = useSelector((state) => state.studyPlane.loading);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const validationSchema = yup.object().shape({
    name: yup.string().required(`${t("this-is-required")}`),
    start_date: yup
      .date()
      .required(`${t("this-is-required")}`)
      .max(yup.ref("end_date"), "Start date must be before end date"),

    end_date: yup
      .date()
      .required(`${t("this-is-required")}`)
      .min(yup.ref("start_date"), "End date must be after start date"),
  });
  const formik = useFormik({
    initialValues: {
      name: props.name,
      start_date: dayjs(props.startDate),
      end_date: dayjs(props.endDate),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = async (values) => {
    try {
      const formattedStartDate = values.start_date.format("YYYY-MM-DD"); // Format the date
      const formattedEndDate = values.end_date.format("YYYY-MM-DD"); // Format the date
      const formData = {
        name: values.name,
        start_date: formattedStartDate, // Use the formatted date
        end_date: formattedEndDate,
      };
      console.log(formData);

      let info = {
        formData,
        id: props.id,
      };
      await dispatch(editTerm(info))
        .then((res) => res.payload.code === 200 && setOpen(false))
        .then(() => {
          dispatch(TermsData());
        });
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Box m="20px">
      <Button
        variant="contained"
        sx={props.sx}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("Edit")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("edit-terms")}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Box
              display="grid"
              dir={sidebarRTL ? "rtl" : "ltr"}
              gap="20px"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Typography
                component="label"
                variant="body1"
                fontWeight="bold"
                mb={1}
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("Name")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                fullWidth={true}
                variant="outlined"
                type="text"
                placeholder={t("Name")}
                dir={sidebarRTL ? "rtl" : "ltr"}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
                error={!!formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
              />
              <Typography
                component="label"
                variant="body1"
                fontWeight="bold"
                mb={1}
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("StartTerm")}
                <span style={{ color: "red" }}>*</span>
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                <DatePicker
                  format="YYYY/MM/DD"
                  value={formik.values.start_date}
                  onBlur={() => formik.setFieldTouched("start_date", true)}
                  onChange={(date) => formik.setFieldValue("start_date", date)}
                  name="start_date"
                  error={
                    !!formik.touched.start_date && !!formik.errors.start_date
                  }
                  helperText={
                    formik.touched.start_date && formik.errors.start_date
                  } // Display error message here
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      fullWidth
                      error={
                        !!formik.touched.start_date &&
                        !!formik.errors.start_date
                      }
                      helperText={
                        formik.touched.start_date && formik.errors.start_date
                      } // Display error message here
                    />
                  )}
                />
              </LocalizationProvider>

              {/* End Date */}

              <Typography
                component="label"
                variant="body1"
                fontWeight="bold"
                mb={1}
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("EndDate")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                <DatePicker
                  format="YYYY/MM/DD"
                  value={formik.values.end_date}
                  onBlur={() => formik.setFieldTouched("end_date", true)}
                  onChange={(date) => formik.setFieldValue("end_date", date)}
                  name="end_date"
                  error={!!formik.touched.end_date && !!formik.errors.end_date}
                  helperText={formik.touched.end_date && formik.errors.end_date} // Display error message here
                  renderInput={(props) => (
                    <TextField {...props} variant="outlined" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.greenAccent[400],
                  color: colors.primary[300],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 15px",
                  "&:hover": {
                    backgroundColor: colors.greenAccent[500],
                  },
                }}
                variant="outlined"
                endIcon={<AddIcon />}
                disabled={loading ? true : false}
              >
                {loading ? t("wait") : t("Edit")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditTerm;
