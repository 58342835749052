import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import dayjs from "dayjs";
import {
  editStudentInstallments,
  getAllStudentInstallments,
} from "../../redux/StudentInstallments";

const EditStudentInstallment = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { sidebarRTL } = useSidebarContext();
  const loading = useSelector((state) => state.ExamSchedule.loading);

  const checkoutSchema = yup.object().shape({
    payment_date: "",
  });
  console.log(props.paymentDate);
  const formik = useFormik({
    initialValues: {
      payment_date: "",
    },
    validationSchema: checkoutSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    setOpen(false);
    const info = {
      id: props.student_id,
      pageSize: props.pageSize,
    };
    await dispatch(getAllStudentInstallments(info));
    formik.resetForm();
  };

  const handleFormSubmit = async (values) => {
    const formValues = {
      id: props.id,
      payment_date: values.payment_date.format("YYYY-MM-DD"),
    };
    await dispatch(editStudentInstallments(formValues)).then((res) =>
      res.payload.code === 200 ? handleClose() : setOpen(true)
    );
  };

  return (
    <Box m="20px">
      <Button
        variant="contained"
        sx={{
          background: `${colors.primary[600]}`,
        }}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("payment_postponement")}
      </Button>
      <Dialog
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          <DialogTitle
            sx={{ fontWeight: "bold", fontSize: "22px" }}
            align={sidebarRTL ? "right" : "left"}
          >
            {t("Edit")}
          </DialogTitle>
          <form onSubmit={formik.handleSubmit} dir={sidebarRTL ? "ltr" : "rtl"}>
            <Box
              dir={sidebarRTL ? "rtl" : "ltr"}
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Typography
                component="label"
                variant="body1"
                fontWeight="bold"
                mb={1}
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("payment_date")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                <DatePicker
                  sx={{
                    direction: sidebarRTL ? "rtl" : "ltr",
                    gridColumn: "span 4",
                  }}
                  placeholder={t("Date Picker")}
                  format="YYYY/MM/DD"
                  value={formik.values.payment_date || dayjs(props.paymentDate)}
                  onBlur={() => formik.setFieldTouched("payment_date", true)}
                  onChange={(date) =>
                    formik.setFieldValue("payment_date", date)
                  }
                  name="payment_date"
                  error={
                    !!formik.touched.payment_date &&
                    !!formik.errors.payment_date
                  }
                  helperText={
                    formik.touched.payment_date && formik.errors.payment_date
                  }
                  renderInput={(props) => (
                    <TextField {...props} variant="outlined" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.greenAccent[400],
                  color: colors.primary[300],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 15px",
                  "&:hover": {
                    backgroundColor: colors.greenAccent[500],
                  },
                }}
                disabled={loading ? true : false}
                variant="outlined"
                onClick={handleClickOpen}
              >
                {loading ? t("wait") : t("Edit")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditStudentInstallment;
