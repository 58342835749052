import { Box, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Header from "../../components/Header";
import MessageChart from "../../components/messageChart/messageChart";
import StudentsTable from "./studentsTable";
import { dashboardStatistics } from "../../redux/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Person4Icon from "@mui/icons-material/Person4";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ClassIcon from "@mui/icons-material/Class";
import BadgeIcon from "@mui/icons-material/Badge";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const DashBoard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(dashboardStatistics());
  }, [dispatch]);
  const statistic = useSelector((state) => state.dashboard.statistics);
  const { sidebarRTL } = useSidebarContext();

  return (
    <Box m="20px">
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={sidebarRTL && smScreen ? "row-reverse" : "row"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title={t("Dashboard")} />
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <MessageChart
            title={t("Classes")}
            count={statistic?.classesCount}
            icon={
              <ClassIcon
                sx={{ color: colors.greenAccent[300], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid direction="row-reverse" xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <MessageChart
            title={t("Employees")}
            count={statistic?.employeesCount}
            icon={
              <BadgeIcon
                sx={{ color: colors.greenAccent[300], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <MessageChart
            title={t("Parents")}
            count={statistic?.parentsCount}
            icon={
              <SupervisorAccountIcon
                sx={{ color: colors.greenAccent[300], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <MessageChart
            title={t("Social Specialist")}
            count={statistic?.socialSpecialistsCount}
            icon={
              <ConnectWithoutContactIcon
                sx={{ color: colors.greenAccent[300], fontSize: "26px" }}
              />
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={2.4} xl={2.4}>
          <MessageChart
            title={t("Students")}
            count={statistic?.studentsCount}
            icon={
              <Person4Icon
                sx={{ color: colors.greenAccent[300], fontSize: "26px" }}
              />
            }
          />
        </Grid>
      </Grid>
      <StudentsTable />
    </Box>
  );
};
export default DashBoard;
