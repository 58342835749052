import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useSidebarContext } from '../../pages/global/sidebar/sidebarContext';
import { useDispatch } from "react-redux";
import { TextField } from '@mui/material';

let FetchedBefore = false;

const CustomSearch = (props) => {
    const { t } = useTranslation()
    const { SidebarRTL } = useSidebarContext()
    const dispatch = useDispatch()
    const handleSearch = (e) => {
        console.log('Handle input is changed');
        if (!e.target.value && !FetchedBefore) {
            FetchedBefore = true;
            dispatch(props.action({ handle: "", pageSize: props.pageSize }));
        } else {
            FetchedBefore = false;
            dispatch(props.action({ handle: e.target.value, pageSize: props.pageSize, gradeId:props.gradeId,typeId:props.typeId,classId:props.classId }));
        }
    }

    return (
        <FormControl dir={SidebarRTL?"rtl":"ltr"} sx={{ m: 1, width: '25ch', fontSize: "20px" }} variant="outlined">
            <label style={{ fontSize: "20px", fontWeight: "bold" }}>{t("search")}</label>
            <TextField
                className='search-input'
                onKeyUp={handleSearch}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                    'aria-label': 'weight',
                }}
            />
        </FormControl>
    )
}

export default CustomSearch;
