import React, { useState, useEffect } from "react";
import { Box, ButtonGroup, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";
import { AllStudentsExpensesData, getAllStudentsExpensesDataByPage } from "../../redux/StudentsExpenses";
import AddStudentsExpenses from "./AddStudentsExpenses";
import RemoveStudentsExpenses from "./RemoveStudentsExpenses";
import EditStudentsExpenses from "./EditStudentsExpenses";

const StudentsExpenses = () => {
  const nextPage = useSelector((state) => state.StudentsExpenses.AllStudentsExpensesLinks.next);
  const lastPage = useSelector((state) => state.StudentsExpenses.AllStudentsExpensesLinks.last);
  const prevPage = useSelector((state) => state.StudentsExpenses.AllStudentsExpensesLinks.prev);
  const firstPage = useSelector((state) => state.StudentsExpenses.AllStudentsExpensesLinks.first);
  const currentPage = useSelector((state) => state.StudentsExpenses.currentPage);
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const info = { pageSize };
  const { sidebarRTL } = useSidebarContext();

  useEffect(() => {
    dispatch(AllStudentsExpensesData(info));
  }, [dispatch, pageSize]);

  const allExpensesData = useSelector((state) => state?.StudentsExpenses?.AllStudentsExpensesData?.data) || [];
  const loading = useSelector((state) => state?.StudentsExpenses?.loading);
  const nameWidth =
    permission.includes("update-student_grade_expenses") && permission.includes("delete-student_grade_expenses")
      ? 200
      : 700;
  const idWidth =
    permission.includes("update-student_grade_expenses") && permission.includes("delete-student_grade_expenses")
      ? 180
      : 480;

  const columns = [
    { field: "id", headerName: t("School Student Id"), width: idWidth },
    { field: "studentName", headerName: t("StudentsName"), width: nameWidth },
    { field: "parentName", headerName: t("Parent Name"), width: nameWidth },
    {
      field: "grade", headerName: t("Grade"), width: 130,
      renderCell: (params) => (
        <p>{params.row.grade.name}</p>
        )
    },
    {
      field: "class", headerName: t("class"), width: 150},
    ...(permission.includes("update-student_grade_expenses") &&
    permission.includes("delete-student_grade_expenses")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 300,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditStudentsExpenses
                  sx={{
                    background: `${colors.primary[600]}`,
                    m: 4,
                    display: permission.includes("update-student_grade_expenses")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<EditIcon />}
                  pageSize={pageSize}
                  grade={params.row.grade.id}
                />
                <RemoveStudentsExpenses
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-student_grade_expenses")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  pageSize={pageSize}
                  icon={<DeleteForeverIcon />}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  const tableData = {
    rows:allExpensesData.length>0 && allExpensesData,
    columns:columns,
    loading:loading===true,
    pageSize:pageSize,
    onPageSizeChange:(newPageSize) => setPageSize(newPageSize)
  }
  return (
    <Box m="20px">
      <Box
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("StudentsExpenses")} />
        <AddStudentsExpenses
          show={permission.includes("store-student_grade_expenses")}
          pageSize={pageSize}
          icon={<AddIcon />}
        />
      </Box>
        <CustomTableBox
          tableData={tableData}
          CustomPagenation={
            <CustomPagenation
              pageSize={pageSize}
              nextPage={nextPage}
              lastPage={lastPage}
              prevPage={prevPage}
              firstPage={firstPage}
              currentPage={currentPage}
              action={getAllStudentsExpensesDataByPage}
            />
          }
          />
    </Box>
  );
};
export default StudentsExpenses;