import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  ShowSocialSpecialist,
  editSocial,
  resetShowSocialSpecialist,
  socialSpecialistData,
} from "../../redux/socialSpecialist";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
const SocailEdit = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const loading = useSelector((state) => state.socialSpecialist.loading);
  const dispatch = useDispatch();
  const [initialValues, setInitialValuesSet] = useState(false); // Add state to track initial values
  const OneSocial = useSelector(
    (state) => state.socialSpecialist.ShowSocialSpecialist.data
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const validationSchema = yup.object().shape({
    username: yup.string().required(`${t("this-is-required")}`),
    name: yup.string().required(`${t("this-is-required")}`),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Z][a-z])/,
        "Password must contain at least one uppercase letter and least one lowercase letter"
      )
      .required(`${t("this-is-required")}`),
    password_confirmation: yup
      .string()
      .required(`${t("this-is-required")}`)
      .min(8)
      .oneOf([yup.ref("password")], "Passwords must match"), // Check if password_confirmation matches the password field
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = async (values) => {
    try {
      const ID = props.ID;

      const info = {
        ID,
        values: {
          ...values, // Spread the existing values
          type: "social_specialist", // Add the type property
        },
      };
      console.log(info);
      await dispatch(editSocial(info));
      const dataInfo = {
        pageSize: props.pageSize,
      };
      await dispatch(socialSpecialistData(dataInfo)).then(
        (res) => res.payload.code === 200 && handleClose()
      );
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  const handleClickOpen = async () => {
    await dispatch(ShowSocialSpecialist(props.ID));
    setOpen(true);
  };
  const handleClose = async () => {
    setInitialValuesSet(false);
    formik.resetForm();
    await dispatch(resetShowSocialSpecialist());
    setOpen(false);
  };
  useEffect(() => {
    if (open && OneSocial && !initialValues) {
      formik.setValues({
        name: OneSocial.name,
        username: OneSocial.username,
      });
      setInitialValuesSet(true); // Mark initial values as set
    }
  }, [open, OneSocial, initialValues, formik]);

  const { sidebarRTL } = useSidebarContext();

  return (
    <Box m="20px">
      <Button
        variant="contained"
        sx={props.sx}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("Edit")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ direction: sidebarRTL ? "rtl" : "ltr" }}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }}>
          {t("Edit")}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Username")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder={t("Username")}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  name="username"
                  error={!!formik.touched.username && !!formik.errors.username}
                  helperText={formik.touched.username && formik.errors.username}
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                  }}
                />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Name")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder={t("Name")}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  error={!!formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Password")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Password")}
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  name="password"
                  error={!!formik.touched.password && !!formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Password Confirmation")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Password Confirmation")}
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password_confirmation}
                  name="password_confirmation"
                  error={
                    !!formik.touched.password_confirmation &&
                    !!formik.errors.password_confirmation
                  }
                  helperText={
                    formik.touched.password_confirmation &&
                    formik.errors.password_confirmation
                  }
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.greenAccent[400],
                  color: colors.primary[300],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 15px",
                  "&:hover": {
                    backgroundColor: colors.greenAccent[500],
                  },
                }}
                disabled={loading ? true : false}
                variant="outlined"
              >
                {loading ? "Wait..." : t("Save")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SocailEdit;
