import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, AlertTitle, Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch } from "react-redux";
import { deleteExam, fetchExamsData } from "../../redux/Exams";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const DeleteExam = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { sidebarRTL } = useSidebarContext();

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleApiCall = async () => {
    const redispatchInfo = {
      pageSize: props.pageSize,
    };
    try {
      await dispatch(deleteExam(props.id)).then(() =>
        dispatch(fetchExamsData(redispatchInfo))
      );
    } catch (error) {}
    setOpen(false);
  };

  return (
    <Box m="20px">
      <Button
        variant="contained"
        sx={props.sx}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("Delete")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        dir={sidebarRTL ? "rtl" : "lrt"}
        sx={{
          width: { xs: "100%", md: "35%" },
          margin: "auto",
          padding: "0",
        }}
      >
        <DialogTitle
          sx={{
            padding: "0",
          }}
          id="alert-dialog-title"
        >
          <Alert
            severity="error"
            sx={{
              marginBottom: "20px",
              background: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& .MuiAlert-icon": {
                fontSize: "60px",
              },
              "& .MuiAlert-message": {
                fontSize: "24px",
              },
            }}
          ></Alert>
          <AlertTitle
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              color: colors.primary[100],
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {t("Are you Sure?")}
          </AlertTitle>
          <p
            style={{
              fontSize: "17px",
              color: colors.primary[300],
              textAlign: "center",
            }}
          >
            {t("this Will be Removed")}
          </p>
        </DialogTitle>
        <DialogActions
          sx={{
            margin: "1rem auto",
            display: "flex",
            justifyContent: "space-between",
            width: "45%",
          }}
        >
          <Button
            variant="contained"
            sx={{ background: `${colors.redAccent[500]}` }}
            onClick={() => {
              handleApiCall();
              handleClose();
            }}
          >
            {t("Yes")}
          </Button>

          <Button variant="contained" onClick={handleClose}>
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteExam;
