import { Box } from '@mui/material';
import React from 'react'
import SubjectsTable from './SubjectsTable';

const Subjects = () => {
    return (
        <Box m="20px">
          <SubjectsTable />
        </Box>
      );
}

export default Subjects