import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { Typography } from "@mui/material";

const MultiSelectMenu = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { t } = useTranslation();
  const { sidebarRTL } = useSidebarContext();
  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);

    // Call the callback function with selected options
    if (props.onSelectedOptions) {
      props.onSelectedOptions(selected);
    }
  };
  return (
    <div style={{ marginTop: "10px", position: "relative" }}>
      <Typography
        component="label"
        variant="body1"
        fontWeight="bold"
        mb={1}
        style={{ fontSize: "20px", fontWeight: "bold" }}
      >
        {t(`${props.text}`)}
        <span style={{ color: "red" }}>*</span>
      </Typography>
      <Select
        options={props.data} // Use props.data instead of props.options
        placeholder={t(`${props.text}`)}
        value={selectedOptions}
        onChange={handleSelectChange} // Call handleSelectChange when options change
        isSearchable={true}
        name={props.name}
        isMulti
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            color: "#000",
            direction: sidebarRTL ? "rtl" : "ltr",
          }),
          container: (provided) => ({
            ...provided,
            zIndex: 9999,
            direction: sidebarRTL ? "rtl" : "ltr",
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
            direction: sidebarRTL ? "rtl" : "ltr",
          }),
        }}
      />
    </div>
  );
};

export default MultiSelectMenu;
