import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, AlertTitle, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch } from "react-redux";
import { deleteGrade, getGrades } from "../../redux/Grades";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const DeleteGrade = ({ icon, sx, pageSize, id }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();

  const info = {
    id,
    pageSize,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApiCall = () => {
    dispatch(deleteGrade(info)).then((response) => {
      dispatch(getGrades(info));
    });
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={sx}
        endIcon={icon}
        onClick={handleClickOpen}
      >
        {t("Remove")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        dir={sidebarRTL ? "rtl" : "ltr"}
        sx={{
          width: { xs: "100%", md: "35%" },
          margin: "auto",
          padding: "0",
        }}
      >
        <DialogTitle
          sx={{
            padding: "0",
          }}
          id="alert-dialog-title"
        >
          <Alert
            severity="error"
            sx={{
              marginBottom: "20px",
              background: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& .MuiAlert-icon": {
                fontSize: "60px",
              },
              "& .MuiAlert-message": {
                fontSize: "24px",
              },
            }}
          ></Alert>
          <AlertTitle
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              color: colors.primary[100],
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            {t("Are you Sure?")}
          </AlertTitle>
          <p
            style={{
              fontSize: "17px",
              color: colors.primary[300],
              textAlign: "center",
            }}
          >
            {t("this Will be Removed")}
          </p>
        </DialogTitle>
        <DialogActions
          dir={sidebarRTL ? "rtl" : "ltr"}
          sx={{
            margin: "1rem auto",
            dispaly: "flex",
            justifyContent: "space-evenly",
            width: "50%",
          }}
        >
          <Button
            variant="contained"
            sx={{ background: `${colors.redAccent[500]}` }}
            onClick={() => {
              handleApiCall();
            }}
            autoFocus
          >
            {t("Yes")}
          </Button>
          <Button variant="contained" onClick={handleClose}>
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteGrade;
