import React from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import DropDown from "./dropDown";
import { useState } from "react";
import { useEffect } from "react";
import { dashboardStudents } from "../../redux/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Pagenation from "./pagenation";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const StudentsTable = () => {
  const { t } = useTranslation();
  const classes = useSelector((state) => state.dashboard.classes) || [];
  const [pageSize, setPageSize] = useState(10);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const permission =
    useSelector((state) => state.user.userInfo.data?.permissions) || [];
  const [selectedClass, setSelectedClass] = useState(
    classes.length > 0 ? classes[0].id : null
  );
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dashboard.loading);
  useEffect(() => {
    if (classes.length > 0) {
      setSelectedClass(classes[0].id);
    }
  }, [classes]);
  const info = {
    id: selectedClass,
    pageSize: pageSize,
  };

  const columns = [
    { field: "id", headerName: t("ID"), width: 100 },
    {
      field: "name",
      headerName: t("Name"),
      width: 300,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: colors.greenAccent[300],
          }}
          to={`/studentdetails/${params.row.id}`}
        >
          {params.row.name}
        </Link>
      ),
    },
    {
      field: "gender",
      headerName: t("Gender"),
      width: 200,
      valueGetter: (params) => (params.row.isMale === true ? "Male" : "Female"),
    },
    { field: "age", headerName: t("Age"), width: 100 },
    {
      field: "absence",
      headerName: t("Absence"),
      width: 200,
      valueGetter: (params) => t(params.row.absence) || "N/A",
    },
    {
      field: "percentage",
      headerName: t("Percentage"),
      width: 150,
      valueGetter: (params) => params.row.studentRatio?.percentage || "N/A",
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 130,
      valueGetter: (params) => t(params.row.studentRatio.status),
    },
  ];

  useEffect(() => {
    dispatch(dashboardStudents(info));
  }, [selectedClass, dispatch]);

  const students = useSelector((state) => state.dashboard.students);
  const { sidebarRTL } = useSidebarContext();

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
      >
        <Header
          style={{
            display: permission.includes("show-student"),
          }}
          title={t("Students")}
        />
        <DropDown
          selectedClass={selectedClass}
          setSelectedClass={setSelectedClass}
        />
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          display: permission.includes("show-student") ? "block" : "none",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
            fontWeight: "bold",
            fontSize: "20px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiButtonGroup-root.MuiButtonGroup-outlined.css-iajp3t-MuiButtonGroup-root":
            {
              display: "flex",
              alignItems: "center",
              flexDirection: sidebarRTL ? "row-reverse" : "row",
              gap: sidebarRTL ? "35px" : "0px",
            },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
            justifyContent: sidebarRTL
              ? "flex-end !important"
              : "flex-start !important",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: sidebarRTL ? "end !important" : "start !important",
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
        }}
      >
        <DataGrid
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
          }}
          rows={students}
          columns={sidebarRTL ? columns.reverse() : columns}
          loading={loading && true}
          autoHeight
          pagination
          disableSelectionOnClick={true}
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("rowsPerPageText"),
              dir: sidebarRTL ? "rtl" : "ltr",
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15]}
        />
        <Pagenation pageSize={pageSize} />
      </Box>
    </Box>
  );
};

export default StudentsTable;
