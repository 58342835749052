import { Box } from "@mui/material";
import CurrenciesTable from "./table"
const Currencies = () => {
  return (
    <Box m="20px">
      <CurrenciesTable />
    </Box>
  );
};
export default Currencies;
