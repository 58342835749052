import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DropDownGradesSubjects from "./DropDownAdd";
import { useEffect, useState } from "react";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { selectExpenses } from "../../redux/gradeExpenses";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { EditSchedule, getExamSchedule } from "../../redux/ExamSchedule";

const EditExamSchedule = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { sidebarRTL } = useSidebarContext();
  const loading = useSelector((state) => state.ExamSchedule.loading);

  const checkoutSchema = yup.object().shape({
    exam_date: "",
    from: "",
    to: "",
  });

  const formik = useFormik({
    initialValues: {
      exam_date: dayjs(props.examDate),
      from: props.from,
      to: props.to,
    },
    validationSchema: checkoutSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  // console.log(props.from);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = async () => {
    const info = {
      gradeId: props.gradeExpensesId,
      pageSize: props.pageSize,
      typeId: props.typeId,
    };
    setOpen(false);
    await dispatch(getExamSchedule(info));
    formik.resetForm();
  };
  useEffect(() => {
    dispatch(selectExpenses());
  }, [open === true]);

  const handleFormSubmit = async (values) => {
    const formValues = {
      id: props.id,
      exam_date: values.exam_date.format("YYYY-MM-DD"),
      from: values.from.format("HH:mm"),
      to: values.to.format("HH:mm"),
    };
    await dispatch(EditSchedule(formValues)).then((res) =>
      res.payload.code === 200 ? handleClose() : setOpen(true)
    );
  };

  return (
    <Box my="20px">
      <Button
        variant="contained"
        sx={props.sx}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("Edit")}
      </Button>
      <Dialog
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          <DialogTitle
            sx={{ fontWeight: "bold", fontSize: "22px" }}
            align={sidebarRTL ? "right" : "left"}
          >
            {t("Edit")}
          </DialogTitle>
          <form onSubmit={formik.handleSubmit} dir={sidebarRTL ? "ltr" : "rtl"}>
            <Box
              dir={sidebarRTL ? "rtl" : "ltr"}
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Typography
                component="label"
                variant="body1"
                fontWeight="bold"
                mb={1}
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("StartDate")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                <DatePicker
                  sx={{
                    direction: sidebarRTL ? "rtl" : "ltr",
                    gridColumn: "span 4",
                  }}
                  placeholder={t("Date Picker")}
                  format="YYYY/MM/DD"
                  value={formik.values.exam_date}
                  onBlur={() => formik.setFieldTouched("exam_date", true)}
                  onChange={(date) => formik.setFieldValue("exam_date", date)}
                  name="exam_date"
                  error={
                    !!formik.touched.exam_date && !!formik.errors.exam_date
                  }
                  helperText={
                    formik.touched.exam_date && formik.errors.exam_date
                  }
                  renderInput={(props) => (
                    <TextField {...props} variant="outlined" fullWidth />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={{
                    direction: sidebarRTL ? "rtl" : "ltr",
                    gridColumn: "span 4",
                  }}
                  components={[
                    "TimePicker",
                    "MobileTimePicker",
                    "StaticTimePicker",
                  ]}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("StartTime")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    fullWidth={true}
                  >
                    <MobileTimePicker
                      ampm={false}
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      sx={{
                        direction: sidebarRTL ? "rtl" : "ltr",
                        gridColumn: "span 4",
                      }}
                      placeholder={t("Start time")}
                      value={formik.values.from}
                      onBlur={() => formik.setFieldTouched("from", true)}
                      onChange={(from) => formik.setFieldValue("from", from)}
                      name="from"
                      error={!!formik.touched.from && !!formik.errors.from}
                      helperText={formik.touched.from && formik.errors.from}
                      renderInput={(props) => (
                        <TextField {...props} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>

                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("EndTime")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    fullWidth={true}
                  >
                    <MobileTimePicker
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      ampm={false}
                      sx={{
                        direction: sidebarRTL ? "rtl" : "ltr",
                        gridColumn: "span 4",
                      }}
                      placeholder={t("Start time")}
                      value={formik.values.to}
                      onBlur={() => formik.setFieldTouched("to", true)}
                      onChange={(to) => formik.setFieldValue("to", to)}
                      name="to"
                      error={!!formik.touched.to && !!formik.errors.to}
                      helperText={formik.touched.to && formik.errors.to}
                      renderInput={(props) => (
                        <TextField {...props} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  backgroundColor: colors.greenAccent[400],
                  color: colors.primary[300],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 15px",
                  "&:hover": {
                    backgroundColor: colors.greenAccent[500],
                  },
                }}
                disabled={loading ? true : false}
                variant="outlined"
                onClick={handleClickOpen}
              >
                {loading ? t("wait") : t("Edit")}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditExamSchedule;
