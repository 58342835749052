// src/components/AddRolesPage.js
import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DefaultButton from "../Classes/defaultBtn";
import {
  Responsibility,
  addRole,
  getPermission,
} from "../../redux/responsibility";
import Table from "./GridComponent";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { preparePermissions } from "./prepareRoles";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";

const AddRolesPage = ({ icon, pageSize }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const loading = useSelector((state) => state.roles.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sidebarRTL } = useSidebarContext();

  useEffect(() => {
    dispatch(getPermission());
  }, [dispatch]);

  const allRoles =
    useSelector((state) => state.roles.getPermission?.data) || [];

  const { uniqueRoles, uniqueOperations, uniquePermissions } =
    preparePermissions(allRoles);

  const handleCheckboxChange = (newCheckedItems) => {
    setSelectedIds(Object.keys(newCheckedItems));
  };

  const handleAdd = async () => {
    await dispatch(addRole({ name, permissions: selectedIds })).then(
      (res) => res.payload.code === 201 && navigate("/roles")
    );
  };

  return (
    <Box m="21px" dir={sidebarRTL ? "rtl" : "ltr"}>
      <Typography variant="h4" gutterBottom>
        {t("Add", " ", "roles")}
      </Typography>
      <Box>
        <Typography
          component="label"
          variant="body1"
          fontWeight="bold"
          mb={1}
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {t("Name")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          placeholder={t("Name")}
          dir={sidebarRTL ? "rtl" : "ltr"}
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ fontWeight: "bold", fontSize: "16px" }}
        />
        <Table
          uniquePermissions={uniquePermissions}
          uniquePermissionsLength={Object?.keys(uniquePermissions)?.length}
          uniqueOperations={uniqueOperations}
          uniqueRoles={uniqueRoles}
          onCheckboxChange={handleCheckboxChange}
        />
      </Box>
      <Box
        className="btnsContainer"
        dir={sidebarRTL ? "rtl" : "ltr"}
        sx={{
          width: "100%",
          margin: "3rem auto",
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: colors.redAccent[500],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: colors.redAccent[500],
            },
            flex: 1,
            maxWidth: "calc(50% - 0.5rem)",
          }}
          onClick={() => navigate("/Responsibility")}
          startIcon={<CancelIcon />}
        >
          {t("Cancel")}
        </Button>
        <Button
          sx={{
            backgroundColor: colors.greenAccent[400],
            color: colors.primary[300],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 15px",
            "&:hover": {
              backgroundColor: colors.greenAccent[500],
            },
            flex: 1,
            maxWidth: "calc(50% - 0.5rem)",
          }}
          onClick={handleAdd}
          disabled={!name}
          startIcon={<VerifiedIcon />}
        >
          {loading ? t("wait") : t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default AddRolesPage;
