import React from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import { useState } from "react";
import { useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { addStudent } from "../../redux/student";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import defaultAPI from "../../axiosInstance";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const AddStudents = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [gradeOptions, setGradeOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [parentOptions, setParentOptions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleFormSubmit = async (values) => {
    await dispatch(addStudent({ values })).then((res) => {
      res.payload.code === 201 && navigate("/student");
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await defaultAPI.get("/select_menu/parents");
        setParentOptions(response.data.data);
      } catch (error) {
        console.error("Error fetching parent options:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchGradeAndClassOptions = async () => {
      try {
        const gradeResponse = await defaultAPI.get("/select_menu/grades");
        setGradeOptions(gradeResponse.data.data);
      } catch (error) {
        console.error("Error fetching grade options:", error);
      }
    };

    fetchGradeAndClassOptions();
  }, []);

  useEffect(() => {
    const fetchClassOptions = async () => {
      try {
        if (selectedGrade) {
          const classResponse = await defaultAPI.get(
            `/select_menu/classes?grade_id=${selectedGrade}`
          );
          setClassOptions(classResponse.data.data);
        }
      } catch (error) {
        console.error("Error fetching class options:", error);
      }
    };

    fetchClassOptions();
  }, [selectedGrade]);

  const initialValues = {
    name: "",
    username: "",
    school_student_id: "",
    password: "",
    password_confirmation: "",
    phone: "",
    parent_id: "",
    grade_id: "",
    class_id: "",
    is_male: "",
    age: "",
  };
  const checkoutSchema = yup.object().shape({
    name: yup.string().required(`${t("this-is-required")}`),
    username: yup
      .string()
      .max(255)
      .matches("^[a-zA-Z][a-zA-Z0-9_]{5,}$")
      .required("Required"),
    school_student_id: yup.number().required(`${t("this-is-required")}`),
    phone: yup.string().required(`${t(`${t("this-is-required")}`)}`),
    password: yup.string().required(`${t("this-is-required")}`),
    password_confirmation: yup.string().required(`${t("this-is-required")}`),
    parent_id: yup.string().required(`${t("this-is-required")}`),
    class_id: yup.string().required(`${t("this-is-required")}`),
    is_male: yup.boolean().required(`${t("this-is-required")}`),
    age: yup
      .number()
      .min(2)
      .max(25)
      .required(`${t("this-is-required")}`),
  });

  return (
    <Box m="20px">
      <Header title={t("Add Student")} />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Name")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  type="text"
                  placeholder={t("Name")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                  }}
                />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Username")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  type="text"
                  placeholder={t("Username")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name="username"
                  error={!!touched.username && !!errors.username}
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                  }}
                />
              </Box>

              <Box
                display="flex"
                fullWidth
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Password")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Password")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" },
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                  sx={{
                    gridColumn: "span 2",
                  }}
                />
              </Box>

              <Box
                display="flex"
                fullWidth
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Password Confirmation")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Password Confirmation")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password_confirmation}
                  name="password_confirmation"
                  error={
                    !!touched.password_confirmation &&
                    !!errors.password_confirmation
                  }
                  helperText={
                    touched.password_confirmation &&
                    errors.password_confirmation
                  }
                  InputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" },
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Phone")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  type="text"
                  placeholder={t("Phone")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  sx={{ gridColumn: "span 4" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" },
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("School Student Id")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  type="text"
                  placeholder={t("School Student Id")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.school_student_id}
                  name="school_student_id"
                  error={
                    !!touched.school_student_id && !!errors.school_student_id
                  }
                  helperText={
                    touched.school_student_id && errors.school_student_id
                  }
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" },
                  }}
                />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Age")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  type="number"
                  placeholder={t("Age")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.age}
                  name="age"
                  error={!!touched.age && !!errors.age}
                  helperText={touched.age && errors.age}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    style: { fontSize: "20px", fontWeight: "bold" },
                  }}
                />
              </Box>
              <FormControl
                variant="outlined"
                dir={sidebarRTL ? "rtl" : "ltr"}
                sx={{
                  gridColumn: "span 2",
                  direction: sidebarRTL ? "rtl" : "ltr",
                }}
                inputProps={{
                  style: {
                    direction: sidebarRTL ? "rtl" : "ltr",
                    fontSize: "20px",
                    fontWeight: "bold",
                  },
                }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Gender")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.is_male}
                  name="is_male"
                  error={!!touched.is_male && !!errors.is_male}
                  helperText={touched.is_male && errors.is_male}
                  sx={{
                    gridColumn: "span 2",
                    direction: sidebarRTL ? "rtl" : "ltr",
                  }}
                  inputProps={{
                    style: {
                      direction: sidebarRTL ? "rtl" : "ltr",
                      fontSize: "20px",
                      fontWeight: "bold",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        fontSize: "20px",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  <MenuItem
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    value="1"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("Male")}
                  </MenuItem>
                  <MenuItem
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    value="0"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("Female")}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ gridColumn: "span 2" }}>
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Parents")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.parent_id}
                  name="parent_id"
                  error={!!touched.parent_id && !!errors.parent_id}
                  helperText={touched.parent_id && errors.parent_id}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    style: { fontSize: "20px", fontWeight: "bold" },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        fontSize: "20px",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {parentOptions.map((option) => (
                    <MenuItem
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      key={option.id}
                      value={option.id}
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                dir={sidebarRTL ? "rtl" : "ltr"}
                variant="outlined"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Grade")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    setSelectedGrade(event.target.value);
                    setClassOptions([]);
                  }}
                  value={values.grade_id}
                  name="grade_id"
                  error={!!touched.grade_id && !!errors.grade_id}
                  helperText={touched.grade_id && errors.grade_id}
                  sx={{ gridColumn: "span 2" }}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        fontSize: "18px",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {gradeOptions.map((option) => (
                    <MenuItem
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      key={option.id}
                      value={option.id}
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" sx={{ gridColumn: "span 4" }}>
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Class")}
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <Select
                  fullWidth
                  variant="outlined"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.class_id}
                  name="class_id"
                  error={!!touched.class_id && !!errors.class_id}
                  helperText={touched.class_id && errors.class_id}
                  sx={{ gridColumn: "span 4" }}
                  disabled={selectedGrade}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        fontSize: "18px",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {classOptions.map((option) => (
                    <MenuItem
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      key={option.id}
                      value={option.id}
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              className="btnsContainer"
              dir={sidebarRTL ? "rtl" : "ltr"}
              sx={{
                width: "100%",
                margin: "3rem auto",
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Button
                type="submit"
                className="EditBtnStudent"
                dir={sidebarRTL ? "rtl" : "ltr"}
                sx={{
                  textAlign: "center",
                  background: colors.blueAccent[500],
                  fontWeight: "bold",
                  padding: "1rem",
                  flex: "1",
                  maxWidth: "calc(50% - 0.5rem)",
                }}
                endIcon={
                  <VerifiedIcon
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    sx={{ margin: sidebarRTL ? ".5rem" : "0" }}
                  />
                }
                variant="contained"
              >
                {t("Add Student")}
              </Button>
              <Link
                className="cancelBtnStudentLink"
                to="/student"
                style={{ flex: "1", maxWidth: "calc(50% - 0.5rem)" }}
              >
                <Button
                  className="cancelBtnStudent"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  sx={{
                    textAlign: "center",
                    background: colors.redAccent[600],
                    fontWeight: "bold",
                    padding: "1rem",
                    flex: "1",
                    width: "100%",
                  }}
                  endIcon={
                    <CancelIcon
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      sx={{ margin: sidebarRTL ? ".5rem" : "0" }}
                    />
                  }
                  variant="contained"
                >
                  {t("Cancel")}
                </Button>
              </Link>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
export default AddStudents;
