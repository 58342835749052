import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DefaultButton from "./defaultBtn";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAbsence } from "../../redux/absence";
import defaultAPI from "../../axiosInstance";
import { getStudentInfo } from "../../redux/student";
import { useEffect } from "react";
import { getClasses } from "../../redux/Grades";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const AddAbsence = ({ icon, selectedClassId, pageSize, show }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [classId, setClassId] = useState(null);
  const [selectedOption, setSelectedOption] = useState("absence");
  const [studentId, setStudentId] = useState(null);
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleApiCall = async () => {
    const apiUrl = `/absence`;
    try {
      if (studentId !== "") {
        setClassId(null);
      } else if (classId !== "") {
        setStudentId(null);
      }
      await defaultAPI
        .post(apiUrl, {
          student_id: studentId,
          class_id: classId,
          type: selectedOption,
        })
        .then((res) => {
          dispatch(getAbsence({ id: selectedClassId, pageSize: pageSize }));
          setStudentId("");
          setClassId("");
        });
    } catch (error) {
      throw error;
    }
    setOpen(false);
  };

  useEffect(() => {
    const fetchStudentInfo = async () => {
      try {
        const res = await dispatch(getStudentInfo());
        setStudents(res.payload.data || []);
      } catch (error) {}
    };

    fetchStudentInfo();
  }, [dispatch]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const res = await dispatch(getClasses());
        setClasses(res.payload.data || []);
      } catch (error) {}
    };

    fetchClasses();
  }, [dispatch]);

  const { sidebarRTL } = useSidebarContext();
  return (
    <div>
      <DefaultButton
        show={show}
        handleClick={handleClickOpen}
        text={t("Add")}
      />
      <Dialog
        fullWidth={true}
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("add-absence")}
        </DialogTitle>
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          {/* <DialogContentText>{t("Add")}</DialogContentText> */}
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3, mt: 2 }}
          >
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("Select Students")} <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              sx={{ direction: sidebarRTL ? "rtl" : "ltr" }}
              // disabled={classId !== null}
              value={studentId}
              onChange={(e) => {
                setStudentId(e.target.value);
                setClassId(null);
              }}
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "100px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} value={null}>
                {t("Select Student")}
              </MenuItem>
              {students.length > 0 &&
                students.map((student) => (
                  <MenuItem
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    key={student.id}
                    value={student.id}
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {student.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("add_absence_to_all_class")}{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              value={classId}
              // disabled={studentId !== null}
              onChange={(e) => {
                setClassId(e.target.value);
                setStudentId(null);
              }}
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "100px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              <MenuItem dir={sidebarRTL ? "rtl" : "ltr"} value={null}>
                {t("Select Class")}
              </MenuItem>
              {classes.map((classItem) => (
                <MenuItem
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  key={classItem.id}
                  value={classItem.id}
                  sx={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {classItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Typography
              component="label"
              variant="body1"
              fontWeight="bold"
              mb={1}
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              {t("Select Option")} <span style={{ color: "red" }}>*</span>
            </Typography>

            <Select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              dir={sidebarRTL ? "rtl" : "ltr"}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  direction: sidebarRTL ? "rtl" : "ltr",
                  maxHeight: "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  direction: sidebarRTL ? "rtl" : "ltr",
                }),
              }}
            >
              <MenuItem
                dir={sidebarRTL ? "rtl" : "ltr"}
                value="justified_absence"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("justified_absence")}
              </MenuItem>
              <MenuItem
                dir={sidebarRTL ? "rtl" : "ltr"}
                value="unjustified_absence"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("unjustified_absence")}
              </MenuItem>
              <MenuItem
                dir={sidebarRTL ? "rtl" : "ltr"}
                value="attendance"
                sx={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("attendance")}
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: colors.redAccent[500],
              },
            }}
            onClick={() => {
              setOpen(false);
              setStudentId("");
              setClassId("");
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: colors.greenAccent[400],
              color: colors.primary[300],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 15px",
              "&:hover": {
                backgroundColor: colors.greenAccent[500],
              },
            }}
            onClick={handleApiCall}
            endIcon={icon}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAbsence;
