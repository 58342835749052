import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import defaultAPI from '../axiosInstance'

export const fetchFullMarkData = createAsyncThunk(
    "fullMark/fetchFullMarkData",
    async (info) => {
        const { id, pageSize } = info
        const apiUrl = `/exams/${id}/custom_full_marks?per_page=${pageSize}`;
        try {
            const res = await defaultAPI.get(apiUrl);
            return res.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
export const fetchFullMarkDataByPage = (info) => async (dispatch) => {
    const { state, pageSize } = info
    try {
        dispatch(fetchFullMarkData.pending());
        const response = await defaultAPI.get(`${state}&per_page=${pageSize}`);
        const data = response.data;
        dispatch(fetchFullMarkData.fulfilled(data));
        dispatch(setCurrentPage(state));
    } catch (error) {
        dispatch(fetchFullMarkData.rejected());
    }
};
export const getExams = createAsyncThunk("fullMark/getExams", async () => {
    const apiUrl = "select_menu/exams";
    try {
        const res = await defaultAPI.get(apiUrl);
        return res.data;
    } catch (error) {
        throw error;
    }
});

export const addFullMark = createAsyncThunk(
    "fullMark/addFullMark",
    async (values, { rejectWithValue, getState }) => {
        try {
            const apiUrl = "/exams/custom_full_marks";
            const res = await defaultAPI.post(apiUrl, values);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data); // Handle errors and return the error data
        }
    }
);

export const getFullMarkGrades = createAsyncThunk(
    "fullMark/grades",
    async () => {
        const apiUrl = "/select_menu/grades";
        try {
            const res = await defaultAPI.get(apiUrl);
            return res.data;
        } catch (error) {
            throw error;
        }
    }
);
export const getFullMarkSubjects = createAsyncThunk(
    "fullMark/subjects",
    async ({ selectedGradeId }) => {
        const apiUrl = `/select_menu/subjects?grade_id=${selectedGradeId}`;
        try {
            const res = await defaultAPI.get(apiUrl);
            return res.data;
        } catch (error) {
            throw error;
        }
    }
);

export const editFullMark = createAsyncThunk(
    "fullMark/editFullMark",
    async (markInfo) => {
        try {
            const apiUrl = `/exams/custom_full_marks/${markInfo.id}`;
            const response = await defaultAPI.put(apiUrl, markInfo.values);
            return response.data;
        } catch (error) {
            return error
        }
    }
);
export const deleteFullMark = createAsyncThunk(
    "fullMark/deleteFullMark",
    async (ID, { rejectWithValue, getState }) => {
        try {
            const apiUrl = `/exams/custom_full_marks/${ID}`;
            const { data } = await defaultAPI.delete(apiUrl);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data); // Handle errors and return the error data
        }
    }
);

const FullMarkSlice = createSlice({
    name: "fullMark",
    initialState: {
        fullMarkData: [],
        getExamsData: [],
        getGradesData: [],
        getSubjectsData: [],
        id: null,
        fullMarkLinks: {
            first: null,
            last: null,
            next: null,
            prev: null
        },
        currentPage: null,
        loading: false,
        error: false,
    },
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload.meta.current_page;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFullMarkData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchFullMarkData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.fullMarkData = action.payload;
                state.fullMarkLinks = action.payload.links;
                state.fullMarkLinks.first = action.payload.links.first;
                state.fullMarkLinks.last = action.payload.links.last;
                state.fullMarkLinks.prev = action.payload.links.prev;
                state.fullMarkLinks.next = action.payload.links.next;
                state.currentPage = action.payload.meta.current_page;
            })
            .addCase(fetchFullMarkData.rejected, (state) => {
                state.error = true;
            })
            .addCase(getExams.pending, (state) => {
                state.loading = true;
            })
            .addCase(getExams.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.getExamsData = action.payload;
            })
            .addCase(getExams.rejected, (state) => {
                state.error = true;
            })
            .addCase(getFullMarkGrades.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFullMarkGrades.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.getGradesData = action.payload;
            })
            .addCase(getFullMarkGrades.rejected, (state) => {
                state.error = true;
            })
            .addCase(getFullMarkSubjects.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFullMarkSubjects.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.getSubjectsData = action.payload;
            })
            .addCase(getFullMarkSubjects.rejected, (state) => {
                state.error = true;
            })
    }
});
export const { setCurrentPage } = FullMarkSlice.actions;
export default FullMarkSlice.reducer;