import React, { useEffect, useMemo } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getClasses, getGradeInfo } from "../../redux/Grades";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const GradeInfoPopup = ({ grade, open, onClose, show }) => {
  const { t } = useTranslation()
  const { sidebarRTL } = useSidebarContext();
  const info = { id: grade.id };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGradeInfo(info));
    dispatch(getClasses());
  }, [dispatch, grade.id]);

  const loading = useSelector((state) => state.grades.loading);
  const gradeInfo = useSelector((state) => state.grades.gradeInfo?.data);
  const classesData = useSelector((state) => state.grades.classes?.data);

  const mapClassIdToName = (classIds, classesData) => {
    return classIds.map((classId) => {
      const classInfo = classesData.find((classItem) => classItem.id === classId);
      return classInfo ? classInfo.name : null;
    });
  };

  const classNames = useMemo(() => mapClassIdToName(gradeInfo?.classes || [], classesData || []), [
    gradeInfo?.classes,
    classesData,
  ]);

  return !show ? (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      dir={sidebarRTL ? "rtl" : "ltr"}
      sx={{
        width: { xs: "100%", md: "50%" },
        margin: "auto",
        padding: "0",
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>
        {t("You are not allowed")}
        <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    </Dialog>
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      dir={sidebarRTL ? "rtl" : "ltr"}
      fullWidth
      sx={{
        width: { xs: "100%", md: "50%" },
        margin: "auto",
        padding: "0",
      }}
    >
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>
        {gradeInfo?.name} Grade Information
        <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: sidebarRTL ? 0 : 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </div>
        ) : gradeInfo ? (
          <div>
            <p>Grade ID: {gradeInfo.id}</p>
            <p>Grade Name: {gradeInfo.name}</p>
            {classNames.length > 0 ? (
              classNames.map((className, index) => (
                <div key={index}>
                  <p>Class Name: {className}</p>
                </div>
              ))
            ) : (
              <p>No class information available</p>
            )}
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  )
};

export default GradeInfoPopup;
