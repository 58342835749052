import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useSidebarContext } from "../../pages/global/sidebar/sidebarContext";

const CustomAccordion = ({ options, title, icon: Icon, collapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { sidebarRTL } = useSidebarContext();

  return (
    <div dir={sidebarRTL ? "rtl" : "ltr"}>
      <Accordion sx={{ background: "transparent", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Icon
            style={{
              marginRight: sidebarRTL ? "0" : "8px",
              marginLeft: sidebarRTL ? "8px" : "0",
            }}
          />
          {!collapsed && (
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {title}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails dir={sidebarRTL ? "rtl" : "ltr"}>
          {options.map((item, index) => (
            <Typography
              key={index}
              sx={{
                margin: ".5rem auto",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {item}
            </Typography>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
