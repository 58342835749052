import defaultAPI from "../../axiosInstance";

export const apiCallingFn = async (info, name) => {
  const { id, studentId } = info;
  const apiUrl = `/students/${studentId}/${name}/${id}`;
  try {
    const res = await defaultAPI.get(apiUrl);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const apiCallingEditFn = async (info, description, name) => {
  const { id } = info;
  const apiUrl = `/${name}/${id}`;
  try {
    const res = await defaultAPI.put(apiUrl, { description });
    return res.data;
  } catch (error) {
    throw error;
  }
};
