import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { apiCallingEditFn, apiCallingFn } from "./ApiCall";
import {
  getStudentBans,
  getStudentReports,
  getstudentAppreciations,
  getstudentGuardians,
} from "../../redux/student";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const EditStudentDetails = ({ selectedTab, id, studentId, sx, icon }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState("");
  const dispatch = useDispatch();
  const info = {
    studentId,
    id,
  };

  const handleClickOpen = () => {
    if (selectedTab === 0) {
      apiCallingFn(info, "reports").then((res) => {
        setDesc(res.data.description);
      });
    } else if (selectedTab === 2) {
      apiCallingFn(info, "bans").then((res) => {
        setDesc(res.data.description);
      });
    } else if (selectedTab === 3) {
      apiCallingFn(info, "appreciations").then((res) => {
        setDesc(res.data.description);
      });
    } else if (selectedTab === 4) {
      apiCallingFn(info, "guardians").then((res) => {
        setDesc(res.data.description);
      });
    }
    setOpen(true);
  };

  const handleClickEdit = () => {
    if (selectedTab === 0) {
      apiCallingEditFn(info, desc, "reports").then(() => {
        dispatch(getStudentReports(info));
      });
    } else if (selectedTab === 2) {
      apiCallingEditFn(info, desc, "bans").then(() => {
        dispatch(getStudentBans(info));
      });
    } else if (selectedTab === 3) {
      apiCallingEditFn(info, desc, "appreciations").then(() => {
        dispatch(getstudentAppreciations(info));
      });
    } else if (selectedTab === 4) {
      apiCallingEditFn(info, desc, "guardians").then(() => {
        dispatch(getstudentGuardians(info));
      });
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { sidebarRTL } = useSidebarContext();

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={sx}
        endIcon={icon}
      >
        {t("Edit")}
      </Button>
      <Dialog
        fullWidth={true}
        open={open}
        dir={sidebarRTL ? "rtl" : "ltr"}
        onClose={handleClose}
        sx={{ width: "50%", margin: "auto", padding: "0" }}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} >{t("Edit")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Edit Data</DialogContentText> */}
          <TextField
            dir={sidebarRTL ? "rtl" : "ltr"}
            autoFocus
            margin="dense"
            id="name"
            value={desc}
            placeholder={t("Name")}
            type="text"
            onChange={(e) => setDesc(e.target.value)}
            fullWidth
            variant="outlined"
            multiline
            rows="3"
            inputProps={{
              style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
            }}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", alignItems: "center", flexDirection: sidebarRTL ? "row-reverse" : "row" }}>
          <Button variant="contained" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button variant="contained" onClick={handleClickEdit}>
            {t("Edit")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditStudentDetails;
