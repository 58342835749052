import React from 'react'
import ResponsibilityTable from './responsibilityTable'

const Responsibility = () => {
    return (
        <div>
            <ResponsibilityTable />
        </div>
    )
}

export default Responsibility
