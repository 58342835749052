import React from "react";
import Switch from '@mui/material/Switch';
import { useDispatch } from "react-redux";
import { TermsData, termActivation } from "../../redux/Terms";

const TermSwitch = (props) => {
    const dispatch = useDispatch();

    const handleChange = async () => {
        await dispatch(termActivation(props.id))
        await dispatch(TermsData());

    }  

    return (
        <Switch
            onChange={handleChange}
            checked={props.isCurrentTerm}
            disabled={props.disabled}
            color="secondary"
        />
    )
}

export default TermSwitch
