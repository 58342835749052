import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import defaultAPI from "../../axiosInstance";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const ExamsComponent = ({ setOpenDialog, openDialog, exam, studentId }) => {
  const { t } = useTranslation();

  const columns = [
    { field: "assignmentId", headerName: t("ID"), width: 50 },
    { field: "subjectName", headerName: t("Subject"), width: 150 },
    { field: "fullMark", headerName: t("Full Mark"), width: 100 },
    {
      field: "obtainedMark",
      headerName: t("obtained-Mark"),
      width: 150,
      editable: true,
    },
    { field: "createdAt", headerName: t("created at"), width: 150 },
  ];

  const [examData, setExamData] = useState([]);

  const fetchExamData = async () => {
    const apiUrl = `/students/${studentId}/${exam}/exam_assignments`;

    try {
      const res = await defaultAPI.get(apiUrl);
      const rowsWithId = res.data.data.map((row) => ({
        ...row,
        id: row.assignmentId,
      }));
      setExamData(rowsWithId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCellEditCommit = async ({ id, value }) => {
    const apiUrl = `/exam_assignment/${id}`;

    try {
      await defaultAPI
        .put(apiUrl, { obtained_mark: value })
        .then((res) => {
          //toast.success(res.data.message);
          setOpenDialog(false);
        })
        .catch((err) => {
          //toast.error(err.response.data.data.obtained_mark);
        });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (openDialog) {
      fetchExamData();
    }
  }, [openDialog]);

  const { sidebarRTL } = useSidebarContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={openDialog}
        onClose={handleClose}
        sx={{ width: { xs: "50%", md: "80%" }, margin: "auto", padding: "0" }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "22px",
            direction: sidebarRTL ? "rtl" : "ltr",
          }}
        >
          {t("Exam Details")}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: 400,
              width: "100%",
              
              "& .MuiButtonGroup-root.MuiButtonGroup-outlined.css-iajp3t-MuiButtonGroup-root":
                {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: sidebarRTL ? "row-reverse" : "row",
                  gap: sidebarRTL ? "35px" : "0px",
                },
              "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                justifyContent: sidebarRTL ? "flex-end !important" : "flex-start !important",
                
              },
              "& .MuiDataGrid-columnHeaderDraggableContainer": {
                flexDirection: sidebarRTL
                  ? "row-reverse !important"
                  : "row !important",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                justifyContent: sidebarRTL
                  ? "end !important"
                  : "start !important",
                flexDirection: sidebarRTL
                  ? "row-reverse !important"
                  : "row !important",
              },
            }}
          >
            <DataGrid
              autoHeight
              rows={examData}
              disableSelectionOnClick={true}

              columns={sidebarRTL ? columns.reverse() : columns}
              onCellEditCommit={handleCellEditCommit}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: t("rowsPerPageText"),
                  dir: sidebarRTL ? "rtl" : "ltr",
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExamsComponent;
