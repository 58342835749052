import * as React from "react";
import { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/auth";
import { useTranslation } from "react-i18next";
import { userProfile } from "../../redux/profile";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const UserDropDown = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await dispatch(userLogout()).then(() => navigate("/"));
  };
  const profileInfo = useSelector((state) => state.profile.userInfo.data) || {};

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);
  console.log(profileInfo);
  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {/* <Avatar src="/broken-image.jpg" /> */}
        {/* <AccountCircleIcon fontSize="large" color="#fff"/> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "8px",
            backgroundColor: "#ccc",
            padding: "4px 17px",
            borderRadius: "20px",
          }}
        >
          <img src={profileInfo.avatar} alt="avatar" width="40px" />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {profileInfo.name}
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {profileInfo.username}
            </Typography>
          </Box>
        </Box>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Link
          style={{
            textDecoration: "none",
            color: colors.grey[100],
          }}
          to="/profile"
        >
          <MenuItem sx={{ fontSize: "18px" }} onClick={handleClose}>
            {t("Profile")}
          </MenuItem>
        </Link>
        <MenuItem sx={{ fontSize: "18px" }} onClick={handleLogout}>
          {t("Logout")}
        </MenuItem>
      </Menu>
    </>
  );
};
export default UserDropDown;
