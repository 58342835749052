import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { getFullMarkGrades, getFullMarkSubjects } from "../../redux/FullMarks";
import { Select, FormControl, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const DropDownGradesSubjects = ({ setFormikValues }) => {
  const { t } = useTranslation();
  const [selectedGradeId, setSelectedGradeId] = useState();
  const [selectedGradeExpenses, setselectedGradeExpenses] = useState();
  const AllGrades =
    useSelector((state) => state.FullMarks.getGradesData.data) || [];

  const dispatch = useDispatch();
  const gradeExpenses =
    useSelector((state) => state.gradeExpenses.selectMenuExpenses.data) || [];

  useEffect(() => {
    dispatch(getFullMarkGrades());
  }, [dispatch]);

  useEffect(() => {
    if (selectedGradeId) {
      dispatch(getFullMarkSubjects({ selectedGradeId }));
    }
  }, [dispatch, selectedGradeId]);

  const handleSelectChangeGrade = (event) => {
    const selectedGradeId = event.target.value;
    setSelectedGradeId(selectedGradeId);
    setselectedGradeExpenses(null);
    setFormikValues("grade_id", selectedGradeId);
  };

  const handleSelectChangeGradeExpenses = (event) => {
    setselectedGradeExpenses(event.target.value);
    setFormikValues("expense_id", event.target.value);
  };

  const { sidebarRTL } = useSidebarContext();

  return (
    <Box
      m="20px"
      display="flex"
      flexDirection="column"
      dir={sidebarRTL ? "rtl" : "ltr"}
    >
      <FormControl variant="outlined">
        <Typography
          component="label"
          variant="body1"
          fontWeight="bold"
          mb={1}
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {t("Grade")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          fullWidth
          value={selectedGradeId}
          onChange={handleSelectChangeGrade}
          dir={sidebarRTL ? "rtl" : "ltr"}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: "#000",
              fontSize: "20px",
              fontWeight: "bold",
              direction: sidebarRTL ? "rtl" : "ltr",
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
          }}
        >
          {AllGrades &&
            AllGrades.map((gradeItem) => (
              <MenuItem
                dir={sidebarRTL ? "rtl" : "ltr"}
                key={gradeItem.id}
                value={gradeItem.id}
                style={{ fontWeight: "bold", fontSize: "18px" }}
              >
                {gradeItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ mt: 2 }}>
        <Typography
          component="label"
          variant="body1"
          fontWeight="bold"
          mb={1}
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {t("gradeExpenses")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          fullWidth
          value={selectedGradeExpenses}
          onChange={handleSelectChangeGradeExpenses}
          dir={sidebarRTL ? "rtl" : "ltr"}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: "#000",
              fontSize: "20px",
              fontWeight: "bold",
              direction: sidebarRTL ? "rtl" : "ltr",
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
          }}
        >
          {gradeExpenses &&
            gradeExpenses.map((item) => (
              <MenuItem
                dir={sidebarRTL ? "rtl" : "ltr"}
                key={item.id}
                value={item.id}
                style={{ fontWeight: "bold", fontSize: "18px" }}
              >
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDownGradesSubjects;
