import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import defaultAPI from '../axiosInstance'
export const fetchExamsData = createAsyncThunk(
    "Exam/fetchExamsData",
    async (info) => {
        const { pageSize } = info
        const apiUrl = `/exams?per_page=${pageSize}`;

        try {
            const res = await defaultAPI.get(apiUrl);
            return res.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
export const SearchExamsData = createAsyncThunk(
    "Exam/SearchExamsData",
    async (info) => {
        const { pageSize } = info
        const apiUrl = `/exams?handle=${info.handle}&per_page=${pageSize}`;

        try {
            const res = await defaultAPI.get(apiUrl);
            return res.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
export const fetchExamDataByPage = (info) => async (dispatch) => {
    const { state, pageSize } = info
    try {

        dispatch(fetchExamsData.pending());
        const response = await defaultAPI.get(`${state}&per_page=${pageSize}`);
        const data = response.data;

        dispatch(fetchExamsData.fulfilled(data));
        dispatch(setCurrentPage(state));
    } catch (error) {
        dispatch(fetchExamsData.rejected());
    }
};
export const getOneExam = createAsyncThunk(

    "Exam/oneExam",
    async (ID, { getState }) => {
        try {
            const apiUrl = `/exams/${ID}`;

            const response = await defaultAPI.get(apiUrl);
            return response.data;
        } catch (error) {
            return (error.response.data);
        }
    }
);

export const addExam = createAsyncThunk(
    "Exam/addExam",
    async (values, { rejectWithValue, getState }) => {
        try {
            const apiUrl = "/exams";

            const response = await defaultAPI.post(apiUrl, values,);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data); // Handle errors and return the error data
        }
    }
);
export const editExam = createAsyncThunk(
    "Exam/editExam",
    async (info) => {
        try {
            const apiUrl = `/exams/${info.id}`;
            const response = await defaultAPI.put(apiUrl, info.values);
            return response.data;
        } catch (error) {
            return
        }
    }
);
export const deleteExam = createAsyncThunk(
    "Exam/deleteExam",
    async (ID, { rejectWithValue, getState }) => {
        try {
            const apiUrl = `/exams/${ID}`;
            const { data } = await defaultAPI.delete(apiUrl);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data); // Handle errors and return the error data
        }
    }
);

const ExamSlice = createSlice({
    name: "Exam",
    initialState: {
        examsData: [],
        oneExam: [],
        examsLinks: {
            first: null,
            last: null,
            next: null,
            prev: null
        },
        currentPage: null,
        loading: false,
        error: false,
    },
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload.meta.current_page;
        }, resetOneExam: (state) => {
            state.oneExam.data = null;
            state.oneExam.loading = false;
            state.oneExam.error = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchExamsData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchExamsData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.examsData = action.payload;
                state.examsLinks = action.payload.links;
                state.examsLinks.first = action.payload.links.first;
                state.examsLinks.last = action.payload.links.last;
                state.examsLinks.prev = action.payload.links.prev;
                state.examsLinks.next = action.payload.links.next;
                state.currentPage = action.payload.meta.current_page;
            })
            .addCase(SearchExamsData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.examsData = action.payload;
                state.examsLinks = action.payload.links;
                state.examsLinks.first = action.payload.links.first;
                state.examsLinks.last = action.payload.links.last;
                state.examsLinks.prev = action.payload.links.prev;
                state.examsLinks.next = action.payload.links.next;
                state.currentPage = action.payload.meta.current_page;
            })
            .addCase(fetchExamsData.rejected, (state) => {
                state.error = true;
            })
            .addCase(getOneExam.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOneExam.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.getOneExam = action.payload;
            })
            .addCase(getOneExam.rejected, (state) => {
                state.error = true;
            })
    }
});
export const { setCurrentPage, resetOneExam } = ExamSlice.actions;
export default ExamSlice.reducer;