import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    useTheme,
  } from "@mui/material";
  import { useFormik } from "formik";
import * as yup from "yup";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
  import { useMediaQuery } from "@mui/material";
  import { tokens } from "../../theme";
  import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { useDispatch, useSelector } from "react-redux";
  import { useTranslation } from "react-i18next";
  import { useEffect, useState } from "react";
  import { useSidebarContext } from "../global/sidebar/sidebarContext";
  import {  selectExpenses } from "../../redux/gradeExpenses";
  import { getAllInstallmentsData, getAllStudentInstallments, PayStudentInstallments } from "../../redux/StudentInstallments";
import CustomSwitch from "../../components/CustomSwitch/CustomSwitch";
import { useParams } from 'react-router-dom';
  
const PayStudentInstallment = (props) => {
    const {student_id}=useParams()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { sidebarRTL } = useSidebarContext();
    const loading = useSelector((state) => state.loading);
  
    const checkoutSchema = yup.object().shape({
  payment_date: yup
    .date()
    .when('pay_once', {
      is: 0, // Apply the validation rule when pay_once is 1
      then: yup.date().required('Required'),
      otherwise: yup.date(), // No validation when pay_once is not 1
    }),
  amount: yup
    .number()
    .when('pay_once', {
      is: 0, // Apply the validation rule when pay_once is 1
      then: yup.number().min(1).required('Required & must be a number'),
      otherwise: yup.number(), // No validation when pay_once is not 1
    }),
  description: yup.string(),
});
  
    const formik = useFormik({
      initialValues: {
        pay_once:0,
        payment_date:"",
        description:"",
        amount:""
      },
      validationSchema: checkoutSchema,
      onSubmit: (values) => {
        handleFormSubmit(values);
      },
    });
  
    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = async () => {
      setOpen(false);
      formik.resetForm()
    };
    useEffect(() => {
      dispatch(selectExpenses())
    },[open===true])
    const handleFormSubmit = async (values) => {
      const info = {
        id: student_id,
        pageSize: props.pageSize,
      };
      const formValues = {
        ...values,
        id:props.id,
        payment_date:values.pay_once===0?values.payment_date.format("YYYY-MM-DD"):"",
        
      }
      await dispatch(PayStudentInstallments(formValues))
        .then((res) =>
        res.payload.code===201?dispatch(getAllStudentInstallments(info)).then(()=>dispatch(getAllInstallmentsData(student_id))).then(()=>handleClose()):null
      );
    };
    const handleSwitchChange = (isChecked) => {
      formik.setFieldValue('pay_once', isChecked ? 1 : 0);
    };
    return (
      <Box m="20px" >
        <Button
          sx={{
            backgroundColor: colors.greenAccent[400],
          }}
          variant="contained"
          onClick={handleClickOpen}
          endIcon={<CurrencyExchangeIcon />}
        >
          {t("Pay")}
        </Button>
        <Dialog
          sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
          open={open}
          onClose={handleClose}
        >
          <DialogContent dir={sidebarRTL?"rtl":"ltr"}>
            <DialogTitle sx={{ fontWeight: "bold", fontSize: "22px" }} align={sidebarRTL ? "right" : "left"}>{t("Add", " ", "prices")}</DialogTitle>
            <form onSubmit={formik.handleSubmit} dir={sidebarRTL?"ltr":"rtl"}>
              <Box
                dir={sidebarRTL?"rtl":"ltr"}
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <Box display="flex" flexDirection="column" sx={{ gridColumn: "span 4" }}>
                  <lable style={{fontSize:"20px",fontWeight:"bold"}}>{t("pay_once")}</lable>
                  <CustomSwitch
                    fullWidth
                    variant="outlined"
                    type="text"
                    onSwitchChange={handleSwitchChange} // Pass the callback function
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.pay_once}
                    name="pay_once"
                    error={!!formik.touched.pay_once && !!formik.errors.pay_once}
                    helperText={formik.touched.pay_once && formik.errors.pay_once}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                      />
                    </Box>
                <Box display="flex" flexDirection="column" sx={{ gridColumn: "span 4" }}>
                  <lable style={{fontSize:"20px",fontWeight:"bold"}}>{t("amount")}</lable>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    disabled={formik.values.pay_once===1?true:false}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.amount}
                    name="amount"
                    error={!!formik.touched.amount && !!formik.errors.amount}
                    helperText={formik.touched.amount && formik.errors.amount}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                      />
                    </Box>
                <lable style={{fontSize:"18px",fontWeight: "Bold"}}>{t("payment_date")}</lable>
                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth={true}>
                  <DatePicker
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    disabled={formik.values.pay_once===1?true:false}
                    sx={{ direction: sidebarRTL ? "rtl" : "ltr",gridColumn:"span 4" }}
                    placeholder={t("Date Picker")}
                    format="YYYY/MM/DD"
                    value={formik.values.payment_date}
                    onBlur={() => formik.setFieldTouched("payment_date", true)}
                    onChange={(date) => formik.setFieldValue("payment_date", date)}
                    name="payment_date"
                    error={
                      !!formik.touched.payment_date && !!formik.errors.payment_date
                    }
                    helperText={
                      formik.touched.payment_date && formik.errors.payment_date
                    }
                    renderInput={(props) => (
                      <TextField {...props} variant="outlined" fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <Box display="flex" flexDirection="column" sx={{ gridColumn: "span 4" }}>
                  <lable style={{fontSize:"20px",fontWeight:"bold"}}>{t("Description")}</lable>
                  <TextField
                    fullWidth
                    disabled={formik.values.pay_once===1?true:false}
                    variant="outlined"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    name="description"
                    error={!!formik.touched.description && !!formik.errors.description}
                    helperText={formik.touched.description && formik.errors.description}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                      />
                    </Box>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  disabled={loading ? true : false}
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  {loading ? t("wait") : t("Add")}
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Box>
    );
  };
  
  export default PayStudentInstallment;
  