import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import defaultAPI from "../../axiosInstance";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { useDispatch, useSelector } from "react-redux";
import { getAllInstallmentsData } from "../../redux/StudentInstallments";
import { useParams } from 'react-router-dom';
const StudentInstallmetChart = () => {
  const { t } = useTranslation();
  const { sidebarRTL } = useSidebarContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch()
  const {student_id}=useParams()
const chartData = useSelector(state=>state.Installments.InstallmentsData.data)||[]

  useEffect(() => {
    dispatch(getAllInstallmentsData(student_id))
  }, [student_id]);

  const chartDataKeys = Object.keys(chartData);
  return (
    <PieChart
      sx={{
        "& .MuiChartsLegend-column": {
          direction: sidebarRTL ? "ltr !important" : "ltr !important",
        },


      }}
      series={[
        {
          data: chartDataKeys
            .map(function (key, index) {
              return {
                id: index,
                value: parseFloat(chartData[key].split(" ")[0].replace(/,/g, "")),
                label: t(`${key}`),
                color: key === "paidInstallmentsExpenses" ? colors.greenAccent[400] : colors.redAccent[400],
              };
            })
            .filter((element) => element.label !== "studentTotalExpenses"),
        },
      ]}
      width={400}
      height={200}
    >
    </PieChart>
  );
};

export default StudentInstallmetChart;
