import { Box } from "@mui/material";
import ClassesTable from "./table";

const Classes = () => {
  return (
    <Box m="20px">
      <ClassesTable />
    </Box>
  );
};
export default Classes;
