import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DefaultButton from "./defaultBtn";
import defaultAPI from "../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { AllStudentsExpensesData } from "../../redux/StudentsExpenses";
import { dashboardGrades } from "../../redux/dashboard";
import { getStudentInfoByGrade } from "../../redux/student";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl } from "@mui/material";
import MultiSelectMenu from "./multiSelect";
import { getGradeExpenses } from "../../redux/Grades";

const validationSchema = Yup.object().shape({
  student_id: Yup.number().required("Student is required"),
});

const AddStudentsExpenses = ({ icon, pageSize, show }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { sidebarRTL } = useSidebarContext();

  // Removed unused state variables
  const [selectedGradeId, setSelectedGradeId] = useState(null);

  // Removed unused state variables
  const [selectedStudnetId, setSelectedStudnetId] = useState(null);

  // Added selectedOptions state
  const [selectedOptions, setSelectedOptions] = useState([]);

  const gradeExpensesdata =
    useSelector((state) => state.dashboard.grades) || [];
  const studentData =
    useSelector((state) => state.student.student_menu.data) || [];
  const StudnetGradeExpenses =
    useSelector((state) => state.grades.studentGradeExpenses.data) || [];
  useEffect(() => {
    dispatch(dashboardGrades());
  }, [dispatch, open]);

  const info = {
    pageSize: pageSize,
  };

  const formik = useFormik({
    initialValues: {
      student_id: null,
      grade_expenses: null,
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (values) => {
    const selectedExpenseIds = selectedOptions.map((option) => option.id);
    values.grade_expenses = selectedExpenseIds;
    values.student_id = selectedStudnetId;
    // Assign the selected expense IDs to the grade_expenses field in values

    try {
      const res = await defaultAPI.post("/student_grade_expenses", values);
      if (res.status === 201) {
        dispatch(AllStudentsExpensesData(info));
        handleClose();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSelectChangeGrade = async (event) => {
    const selectedGradeId = event.target.value;
    setSelectedGradeId(selectedGradeId);
    await dispatch(getStudentInfoByGrade(selectedGradeId));
  };

  const handleSelectChangeStudent = async (event) => {
    setSelectedStudnetId(event.target.value);
    await dispatch(getGradeExpenses({ id: event.target.value }));
  };

  const handleSelectedOptions = (selected) => {
    setSelectedOptions(selected);
  };
  const handleClose = () => {
    // setSelectedStudnetId(null)
    setSelectedGradeId(null);
    setOpen(false);
    formik.resetForm();
  };
  return (
    <div>
      <DefaultButton
        show={show}
        handleClick={handleClickOpen}
        text={t("Add")}
      />
      <Dialog
        fullWidth={true}
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={() => handleClose}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Add")}
        </DialogTitle>
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          <form onSubmit={formik.handleSubmit}>
            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <Typography
                component="label"
                variant="body1"
                fontWeight="bold"
                mb={1}
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("Grade")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                fullWidth
                value={selectedGradeId}
                onChange={handleSelectChangeGrade}
                dir={sidebarRTL ? "rtl" : "ltr"}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    color: "#000",
                    fontSize: "20px",
                    fontWeight: "bold",
                    direction: sidebarRTL ? "rtl" : "ltr",
                    maxHeight: "150px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }),
                  container: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    direction: sidebarRTL ? "rtl" : "ltr",
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    direction: sidebarRTL ? "rtl" : "ltr",
                  }),
                }}
              >
                {gradeExpensesdata &&
                  gradeExpensesdata.map((gradeItem) => (
                    <MenuItem
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      key={gradeItem.id}
                      value={gradeItem.id}
                      style={{ fontWeight: "bold", fontSize: "18px" }}
                    >
                      {gradeItem.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ width: "100%" }}>
              <Typography
                component="label"
                variant="body1"
                fontWeight="bold"
                mb={1}
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {t("Select Student")}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
                fullWidth
                value={formik.values.student_id || selectedStudnetId} // Provide an empty string as a fallback
                onChange={handleSelectChangeStudent}
                dir={sidebarRTL ? "rtl" : "ltr"}
                name="student_id"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    color: "#000",
                    fontSize: "20px",
                    fontWeight: "bold",
                    direction: sidebarRTL ? "rtl" : "ltr",
                    maxHeight: "150px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }),
                  container: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    direction: sidebarRTL ? "rtl" : "ltr",
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    direction: sidebarRTL ? "rtl" : "ltr",
                  }),
                }}
              >
                {studentData.length > 0 &&
                  studentData.map((studentItem) => (
                    <MenuItem
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      key={studentItem.id}
                      value={studentItem.id}
                      style={{ fontWeight: "bold", fontSize: "18px" }}
                    >
                      {studentItem.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <MultiSelectMenu
              name="grade_expenses"
              data={StudnetGradeExpenses}
              text={"StudentsExpenses"}
              onSelectedOptions={handleSelectedOptions}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: colors.redAccent[500],
              },
            }}
            onClick={handleClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            sx={{
              backgroundColor: colors.greenAccent[400],
              color: colors.primary[300],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 15px",
              "&:hover": {
                backgroundColor: colors.greenAccent[500],
              },
            }}
            onClick={() => handleSubmit(formik.values)}
            endIcon={icon}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddStudentsExpenses;
