import { Box } from "@mui/material";
import React from "react";
import GradesTable from "./GradeTable";

const Grades = () => {
  return (
    <Box m="20px">
      <GradesTable />
    </Box>
  );
};

export default Grades;
