import { Box } from "@mui/material";
import StudentsExpenses from "./table";

const StudentsExpensesTable = () => {
  return (
    <Box m="20px">
      <StudentsExpenses />
    </Box>
  );
};
export default StudentsExpensesTable;
