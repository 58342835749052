import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { getStudentInfo } from "../../redux/student";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import defaultAPI from "../../axiosInstance";
import VerifiedIcon from "@mui/icons-material/Verified";

const AddGuardians = () => {
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [guardiansDesc, setGuardiansDesc] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const students =
    useSelector((state) => state.student.studentInfo?.data) || [];

  useEffect(() => {
    dispatch(getStudentInfo());
  }, [dispatch || students === []]);

  const handleAddGuardians = async () => {
    if (!selectedStudent || guardiansDesc.trim() === "") {
      return;
    }

    const info = {
      student_id: selectedStudent.id,
      description: guardiansDesc,
    };

    try {
      await defaultAPI.post("/guardians", info);
    } catch (error) {}

    setSelectedStudent(null);
    setGuardiansDesc("");
  };

  const studentOptions =
    students.length > 0 &&
    students.map((student) => ({
      id: student.id,
      name: student.name,
    }));

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "20px",
      }}
    >
      <Typography
        variant="h2"
        align={sidebarRTL ? "right" : "left"}
        fontWeight="bold"
      >
        {t("Add Guardians Data")}
      </Typography>

      <Select
        fontSize={"19px"}
        fontWeight={"bold"}
        options={studentOptions}
        placeholder={t("Select Student")}
        value={selectedStudent}
        onChange={setSelectedStudent}
        isSearchable={true}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        sx={{ fontSize: "16px" }}
        styles={{
          option: (base) => ({
            ...base,
            zIndex: 9999,
            color: "#000",
            fontSize: "20px",
            fontWeight: "bold",
            direction: sidebarRTL ? "rtl" : "ltr",
            maxHeight: "150px",
            overflowY: "auto",
            overflowX: "hidden",
          }),
        }}
      />

      <TextField
        multiline
        dir={sidebarRTL ? "rtl" : "ltr"}
        rows={4}
        placeholder={t("Guardians Description")}
        fullWidth
        variant="outlined"
        value={guardiansDesc}
        onChange={(e) => setGuardiansDesc(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: colors.blueAccent[700],
            },
          "& .MuiFilledInput-input.MuiInputBase-inputMultiline": {
            textAlign: sidebarRTL ? "end" : "start",
            fontSize: "16px",
          },
        }}
        inputProps={{
          style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
        }}
      />
      <Button
        sx={{
          backgroundColor: colors.greenAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 15px",
          display: permission.includes("store-summon_guardian")
            ? "inline-flex"
            : "none",
          "&:hover": {
            backgroundColor: colors.greenAccent[300],
          },
        }}
        dir={sidebarRTL ? "rtl" : "ltr"}
        endIcon={
          <VerifiedIcon
            dir={sidebarRTL ? "rtl" : "ltr"}
            sx={{ marginRight: sidebarRTL ? ".5rem" : "0" }}
          />
        }
        disabled={!selectedStudent || guardiansDesc.trim() === ""}
        onClick={handleAddGuardians}
      >
        {t("Add Guardians")}
      </Button>
    </Box>
  );
};

export default AddGuardians;
