import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import defaultAPI from "../../axiosInstance";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { getGradesMenu } from "../../redux/Grades";
import { subjectDatagrade_id } from "../../redux/subject";
import { Box } from "@mui/system";

const UploadExamFile = ({ icon, grades }) => {
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    grade_id: "",
    file: null, // Change the initial value to null
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(getGradesMenu());
  }, []);

  const handleClickOpen = () => {
    setOpen(true);

    // Reset the form data when the dialog is opened
    setFormData({
      type: "",
      grade_id: "",
      file: null, // Reset the file input
    });
  };



  const handleApiCall = async () => {
    if (loading) {
      return; // If loading is true, do nothing (prevent multiple clicks)
    }

    // Check if a file is selected and it has a valid Excel file extension
    if (!formData.file) {
      // Show an error message or handle it as needed
      console.error("Please select a file.");
      return;
    }
    const allowedExtensions = [".xls", ".xlsx"];
    const fileName = formData.file.name;
    const fileExtension = fileName
      .substring(fileName.lastIndexOf("."))
      .toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      // Show an error message or handle it as needed
      setMessage("Please select a valid Excel file (xls or xlsx).");
      return;
    }

    setLoading(true); // Set loading state to true

    const apiUrl = `/imports/exam_schedules`;
    try {
      const formDataObj = new FormData(); // Create a FormData object
      formDataObj.append("grade_id", formData.grade_id);
      formDataObj.append("type", formData.type);
      formDataObj.append("file", formData.file); // Append the file to the FormData object

      await defaultAPI.post(apiUrl, formDataObj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Reset the form data
      setFormData({
        grade_id:"",
        type:"",
        file:null
      });

      // Close the dialog
      setOpen(false);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false); // Set loading state back to false
    }
  };
    const ExamsTypeArray = [`${t("mid-term")}`, `${t("final")}`]
  return (
    <Box m="20px">
      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        //   display: show ? "inline-flex" : "none"
        }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {t("Upload File")}
      </Button>
      <Dialog
        fullWidth={true}
        sx={{ width: { xs: "100%", md: "50%" }, margin: "auto", padding: "0" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "30px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Upload File")}
        </DialogTitle>
        <DialogContent>
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3, mt: 2 }}
          >
            <label
              style={{
                fontSize: "18px",
                marginBottom: ".5rem",
                fontWeight: "bold",
              }}
            >
              {t("Select Grade")}
            </label>
            <Select
              value={formData.grade_id}
              onChange={(e) =>
                setFormData({ ...formData, grade_id: e.target.value })
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
            >
              {Array.isArray(grades) && grades.length > 0 ? (
                grades.map((itme) => (
                  <MenuItem key={itme.id} value={itme.id}>
                    {itme.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">Loading grades...</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            dir={sidebarRTL ? "rtl" : "ltr"}
            fullWidth
            sx={{ mb: 3 }}
          >
            <label
              style={{
                fontSize: "18px",
                marginBottom: ".5rem",
                fontWeight: "bold",
              }}
            >
              {t("ExamType")}
            </label>
            <Select
              value={formData.type}
              onChange={(e) =>
                setFormData({ ...formData, type: e.target.value })
              }
              dir={sidebarRTL ? "rtl" : "ltr"}
            >
              {ExamsTypeArray.map((item,index) => (
                <MenuItem key={index} value={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <label
            htmlFor="fileInput"
            dir={sidebarRTL ? "rtl" : "ltr"}
            style={{
              display: "block",
              fontSize: "18px",
              marginBottom: ".5rem",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {t("Select File")}
          </label>
          <input
            type="file"
            id="fileInput"
            onChange={(e) =>
              setFormData({ ...formData, file: e.target.files[0] })
            } // Store the selected file in the state
            style={{ display: "none" }} // Hide the default file input
          />
          <div
            dir={sidebarRTL ? "rtl" : "ltr"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",

                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                variant="outlined"
                value={formData.file ? formData.file.name : ""}
                placeholder={t("File")}
                InputProps={{
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    borderRight: "50px",
                  },
                }}
                disabled
                style={{ width: "83%" }}
              />
              <Button
                variant="contained"
                component="label"
                htmlFor="fileInput" // Link the button to the hidden file input
                style={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "15px 0",

                  width: "15%",
                }}
              >
                {t("Browse")}
              </Button>
            </div>
          </div>
          {message && (
  <div
    style={{
      color: "red",
      margin: ".5rem 0",
      fontSize: "16px",
      fontWeight: "bold",
      backgroundColor: "rgba(255, 0, 0, 0.1)", // Red background with some opacity
      padding: "5px",
      borderRadius: "5px",
    }}
  >
    {t(message)}
  </div>
)}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => setOpen(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 15px",
            }}
            onClick={handleApiCall}
            endIcon={icon}
            disabled={loading} // Disable the button when loading is true
          >
            {loading ? t("Loading...") : t("Add")}{" "}
            {/* Display "Loading..." when loading, or "Add" when not loading */}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UploadExamFile;
