import React from 'react'
import TermsTable from './TermsTable'

const Allterms = () => {
    return (
        <div>
            <TermsTable />
        </div>
    )
}

export default Allterms
