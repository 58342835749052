import React, { useEffect, useState } from "react";
import {
  Box,
    Button,
    ButtonGroup,
    useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";
import AddStudentInstallment from "./AddStudentInstallment";
import { fetchStudentInstallmentsByPage, getAllStudentInstallments } from "../../redux/StudentInstallments";
import { Link, useParams } from 'react-router-dom';
import EditStudentInstallment from "./EditStudentInstallment";
import PayStudentInstallment from "./PayStudentInstallment";
import StudentInstallmetChart from "./Chart"
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const ShowStudentInstallmentsTable = () => {
  const { student_id } = useParams();

  const nextPage = useSelector(
    (state) => state.Installments.StudentInstallmentsLinks.next
  );
  const lastPage = useSelector(
    (state) => state.Installments.StudentInstallmentsLinks.last
  );
  const prevPage = useSelector(
    (state) => state.Installments.StudentInstallmentsLinks.prev
  );
  const currentPage = useSelector(
    (state) => state.Installments.currentPage
  );
  const firstPage = useSelector(
    (state) => state.Installments.StudentInstallmentsLinks.first
  );
  const dispatch = useDispatch()
  const { sidebarRTL } = useSidebarContext();
  
  const StudentInstallments =
    useSelector(state => state.Installments.StudentInstallments.data) || [];
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const loading = useSelector((state) => state.gradeExpenses.loading);
  const theme = useTheme();
  console.log(StudentInstallments)
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const info = {
      pageSize: pageSize,
      id: student_id
    }
    dispatch(getAllStudentInstallments(info))
  }, [dispatch, student_id]);

  const expense =
    permission.includes("update-exam_schedule") &&
    permission.includes("delete-exam_schedule")
      ? 200
      : 400;
  const columns = [
    { field: "id", headerName: t("ID"), width: 180 },
    {
      field: "totalExpenses",
      headerName: t("totalExpenses"),
      width: expense,
      cellClassName: "name-column--cell",
    },

    { field: "paidExpenses", headerName: t("paidExpenses"), width: 180 },
    { field: "remainingExpenses", headerName: t("remainingExpenses"), width: 180 },
    { field: "paymentDate", headerName: t("paymentDate"), width: 180 },
    { field: "createdAt", headerName: t("createdAt"), width: 180 },
    ...(permission.includes("delete-exam_schedule") &&
    permission.includes("update-exam_schedule")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 400,
          renderCell: (params) => params.row.paid===false?(
            <ButtonGroup
              sx={{
                display: "flex",
                flexDirection: sidebarRTL
                  ? "row-reverse !important"
                  : "row !important",
                alignItems: "center",
              }}
            >
              <EditStudentInstallment
                sx={{
                    background: `${colors.primary[600]}`,
                  textTransform:"capitalize",
                  color: colors.grey[100],
                  display: permission.includes("update-exam_schedule")
                    ? "inline-flex"
                    : "none",
                }}
                pageSize={ pageSize }
                id={params.id}
                student_id={student_id}
                paymentDate={params.row.paymentDate}
                />
                
              <PayStudentInstallment
                sx={{
                    background: `${colors.primary[600]}`,
                  textTransform:"capitalize",
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  
                }}
                pageSize={ pageSize }
                id={params.id}
                student_id={student_id}
                paymentDate={params.row.paymentDate}
                />
            </ButtonGroup>
                
          ) : (<span>{ t('all_paid')} </span>)
          },
        ]
      : []),
  ];
  const tableData = {
    rows: StudentInstallments.length > 0 && StudentInstallments,
    columns: columns,
    loading: loading && true,
    pageSize: pageSize,
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
  };
  return (
    <Box m="20px">
      <Box
        display="flex"
        dir={sidebarRTL ? "rtl" : "ltr"}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Header title={t("Student Installments")} />
      </Box>
      <Box
        display="flex"
        dir={sidebarRTL?"rtl":"ltr"}
        justifyContent="space-between"
        flexDirection={sidebarRTL ? "row" : "row-reverse"}
      >
                <StudentInstallmetChart id={student_id}/>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={sidebarRTL ? "row" : "row-reverse"}
      >
        
        <Link style={{margin: "20px"}} to={`/studentInvoice/${student_id}`}>
        <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
            variant="outlined"
        endIcon={<LocalPrintshopIcon />}
      >
        {t("PDF")}
          </Button>
          </Link>
      
        <AddStudentInstallment
          show={
            permission.includes("store-installments") ? "inline-flex" : "none"
          }
          pageSize={pageSize}
          // typeId={SelectedStudentId}
          id={student_id}
        />
      </Box>
      <CustomTableBox
        tableData={tableData}
        CustomPagenation={
          <CustomPagenation
            pageSize={pageSize}
            nextPage={nextPage}
            lastPage={lastPage}
            prevPage={prevPage}
            currentPage={currentPage}
            firstPage={firstPage}
            action={fetchStudentInstallmentsByPage}
          />
        
        }
      />
    </Box>
  );
};
export default ShowStudentInstallmentsTable;
