import React, { useState } from "react";
import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { editSchoolProfile, schoolProfile } from "../../redux/profile";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";

const SchoolInformation = () => {
  const navgite = useNavigate();
  const { sidebarRTL } = useSidebarContext();
  const schoolData =
    useSelector((state) => state.profile.schoolInfo?.data) || {};
  console.log(schoolData);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const { t } = useTranslation();
  const [logo, setLogo] = useState(""); // State to store the logo image
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(schoolProfile());
  }, []);

  const loading = useSelector((state) => state.profile.loading);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const checkoutSchema = yup.object().shape({
    title: yup.string().required("Required"),
    email: yup.string().email("Invalid email!").required("Required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid!")
      .required("Required"),
    address: yup.string().required("Required"),
    facebook: yup.string().required("Required"),
    whatsapp: yup.string().required("Required"),
    telegram: yup.string().required("Required"),
  });
  const handleFormSubmit = async (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("address", values.address);
    formData.append("facebook", values.facebook);
    formData.append("whatsapp", values.whatsapp);
    formData.append("telegram", values.telegram);
    formData.append("logo", logo);

    await dispatch(editSchoolProfile(formData));
    dispatch(schoolProfile());
  };

  const handleNavgite = () => {
    navgite("/dashboard");
  };

  useEffect(() => {
    dispatch(schoolProfile());
    if (schoolData.logo) {
      setLogo(schoolData.logo);
    }
  }, [schoolData.logo]);
  return (
    <Box m="20px" dir={sidebarRTL ? "rtl" : "ltr"}>
      <Header title={t("School Information")} />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
        >
          <LinearProgress />
        </Box>
      ) : (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={schoolData}
          validationSchema={checkoutSchema}
          dir={sidebarRTL ? "rtl" : "ltr"}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 4" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("Title")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name="title"
                    error={!!touched.title && !!errors.title}
                    helperText={touched.title && errors.title}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 4" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("Email")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 4" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("facebook")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.facebook}
                    name="facebook"
                    error={!!touched.facebook && !!errors.facebook}
                    helperText={touched.facebook && errors.facebook}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 4" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("whatsapp")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.whatsapp}
                    name="whatsapp"
                    error={!!touched.whatsapp && !!errors.whatsapp}
                    helperText={touched.whatsapp && errors.whatsapp}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 4" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("telegram")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.telegram}
                    name="telegram"
                    error={!!touched.telegram && !!errors.telegram}
                    helperText={touched.telegram && errors.telegram}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 4" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("Contact Number")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    name="phone"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 4" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    {t("Address")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    name="address"
                    error={!!touched.address && !!errors.address}
                    helperText={touched.address && errors.address}
                    sx={{ gridColumn: "span 4" }}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                    }}
                  />
                </Box>
                <Box>
                  <input
                    type="file"
                    name="logo"
                    accept="image/*"
                    onChange={(e) => setLogo(e.target.files[0])}
                  />
                  {schoolData.logo && (
                    <img
                      src={schoolData.logo}
                      alt="School Logo"
                      style={{
                        maxWidth: "100px",
                        margin: "1rem auto",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="2%"
                mt="20px"
              >
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],

                    color: colors.grey[100],
                    paddin: "2rem",
                    fontWeight: "bold",
                    fontSize: "18px",
                    width: "40%",
                  }}
                  type="submit"
                  variant="outlined"
                >
                  {t("Save")}
                </Button>
                <Button
                  onClick={handleNavgite}
                  variant="contained"
                  sx={{
                    backgroundColor: colors.redAccent[700],
                    color: colors.grey[100],
                    paddin: "2rem",
                    fontWeight: "bold",
                    fontSize: "18px",
                    width: "40%",
                    "&:hover": {
                      color: colors.greenAccent[700],
                    },
                  }}
                >
                  {t("Cancel")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default SchoolInformation;
