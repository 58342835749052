import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchFullMarkData, getExams } from "../../redux/FullMarks";
import { FormControl, InputLabel, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { Height } from "@mui/icons-material";

const DropDownExams = ({ pageSize }) => {
  const { t } = useTranslation();
  const [examName, setExamName] = useState("");
  const { sidebarRTL } = useSidebarContext();
  const exams = useSelector((state) => state.FullMarks.getExamsData.data) || [];
  const dispatch = useDispatch();
  const [examId, setExamId] = useState(exams.length > 0 ? exams[0].id : null);

  useEffect(() => {
    dispatch(getExams());
  }, [dispatch]);

  useEffect(() => {
    if (exams.length > 0 && !examId) {
      setExamId(exams[0].id);
      setExamName(exams[0].name);
    }
  }, [exams, examId]);

  useEffect(() => {
    if (examId) {
      const info = {
        id: examId,
        pageSize: pageSize,
      };
      dispatch(fetchFullMarkData(info));
    }
  }, [dispatch, examId, pageSize]);

  const handleSelectChange = (event) => {
    const selectedName = event.target.value; // Get the selected name
    setExamName(selectedName); // Update the selected name in state

    // Find the corresponding exam ID based on the selected name
    const selectedExam = exams.find(
      (examItem) => examItem.name === selectedName
    );
    if (selectedExam) {
      setExamId(selectedExam.id); // Update the selected exam ID in state
    }
  };

  return (
    <div dir={sidebarRTL ? "rtl" : "ltr"}>
      <FormControl
        sx={{
          maxWidth: 120,
          display: "flex",
          justifyContent: sidebarRTL ? "flex-end" : "flex-start",
          margin: 3,
        }}
      >
        <lable
          htmlFor="exam-select"
          style={{ fontWeight: "Bold", fontSize: "20px" }}
        >
          {t("Select Exam")}
        </lable>
        <Select
          value={examName} // Set the value to the selected examName
          onChange={handleSelectChange}
          sx={{ minWidth: 500 }}
          inputProps={{
            name: "exam",
            id: "exam-select",
          }}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: "#000",
              fontSize: "20px",
              fontWeight: "bold",
              direction: sidebarRTL ? "rtl" : "ltr",
              maxHeight: "150px",
              overflowY: "scroll",
              overflowX: "hidden",
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
          }}
        >
          {exams.map((examItem) => (
            <MenuItem
              key={examItem.id}
              value={examItem.name}
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              {examItem.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropDownExams;
