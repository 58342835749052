import React from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DropDownGradesSubjects from "./DropDownAdd";
import { addFullMark, fetchFullMarkData } from "../../redux/FullMarks";
import { useState } from "react";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { Link, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";

const AddFullMarkPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { sidebarRTL } = useSidebarContext();
  const loading = useSelector((state) => state.FullMarks.loading);
  const navigate = useNavigate();
  const checkoutSchema = yup.object().shape({
    full_mark: yup.number().required(`${t("this-is-required")}`),
  });

  const formik = useFormik({
    initialValues: {
      full_mark: "",
    },
    validationSchema: checkoutSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const handleFormSubmit = async (values) => {
    const info = {
      id: values.exam_id,
    };
    await dispatch(addFullMark(values)).then(() =>
      dispatch(fetchFullMarkData(info))
    );
    navigate(-1);
  };

  const setFormikFieldValue = (field, value) => {
    formik.setFieldValue(field, value);
  };

  return (
    <Box m="20px" dir={sidebarRTL ? "rtl" : "ltr"}>
      <Typography variant="h4" gutterBottom>
        {t("add-full-mark")}
      </Typography>
      <form onSubmit={formik.handleSubmit} dir={sidebarRTL ? "ltr" : "rtl"}>
        <DropDownGradesSubjects setFormikValues={setFormikFieldValue} />
        <Box
          display="flex"
          flexDirection="column"
          dir={sidebarRTL ? "rtl" : "ltr"}
          margin={"20px"}
        >
          <Typography
            component="label"
            variant="body1"
            fontWeight="bold"
            mb={1}
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {t("full-mark")}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            placeholder={t("Full Mark")}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.full_mark}
            name="full_mark"
            error={!!formik.touched.full_mark && !!formik.errors.full_mark}
            helperText={formik.touched.full_mark && formik.errors.full_mark}
            dir={sidebarRTL ? "rtl" : "ltr"}
            inputProps={{
              style: { fontSize: "18px", fontWeight: "bold" },
            }}
          />
        </Box>
        <Box
          className="btnsContainer"
          dir={sidebarRTL ? "rtl" : "ltr"}
          sx={{
            width: "100%",
            margin: "3rem auto",
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Button
            type="submit"
            dir={sidebarRTL ? "rtl" : "ltr"}
            sx={{
              textAlign: "center",
              background: colors.blueAccent[500],
              fontWeight: "bold",
              padding: "1rem",
              flex: "1",
              maxWidth: "calc(50% - 0.5rem)",
            }}
            endIcon={
              <VerifiedIcon
                dir={sidebarRTL ? "rtl" : "ltr"}
                sx={{ margin: sidebarRTL ? ".5rem" : "0" }}
              />
            }
            variant="contained"
          >
            {loading ? t("wait") : t("Save")}
          </Button>

          <Link
            className="cancelBtnStudentLink"
            to="/fullmarks"
            style={{ flex: "1", maxWidth: "calc(50% - 0.5rem)" }}
          >
            <Button
              className="cancelBtnStudent"
              dir={sidebarRTL ? "rtl" : "ltr"}
              sx={{
                textAlign: "center",
                background: colors.redAccent[600],
                fontWeight: "bold",
                padding: "1rem",
                flex: "1",
                width: "100%",
              }}
              endIcon={
                <CancelIcon
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  sx={{ margin: sidebarRTL ? ".5rem" : "0" }}
                />
              }
              variant="contained"
            >
              {t("Cancel")}
            </Button>
          </Link>
        </Box>
      </form>
    </Box>
  );
};

export default AddFullMarkPage;
