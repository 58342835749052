import React from "react";
import Switch from '@mui/material/Switch';

const CustomSwitch = (props) => {
    const handleChange = () => {
      // Toggle the switch and update the formik field
       props.onSwitchChange(!props.value);
    };
    return (
      <Switch
        onChange={handleChange}
        checked={props.value === 1} // Set the switch state based on the formik field
        color="secondary"
      />
    );
  };
export default CustomSwitch;