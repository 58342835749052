import { DataGrid } from "@mui/x-data-grid";
import { useSidebarContext } from "../../pages/global/sidebar/sidebarContext";
import { useTranslation } from "react-i18next";
import CustomToolTip from "../CustomToolTip/customToolTip";

const fieldsConfig = {
  translatedKeys: ['type']
};

const CustomTable = (props) => {
  const columns = props.columns?.map(function (element) {
    if (element.renderCell === undefined) {
      element.renderCell = (params) => (
        <CustomToolTip text={
          fieldsConfig.translatedKeys.includes(element.field)
            ? t(params.row[element.field])
            : params.row[element.field]
        } />
      );
    }

    return element;
  });
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  return (
    <DataGrid
      sx={{
        fontWeight: "bold",
        fontSize: "14px",
      }}
      rows={props.rows}
      autoHeight
      columns={sidebarRTL ? columns.reverse() : columns}
      loading={props.loading}
      pageSize={props.pageSize}
      onPageSizeChange={props.onPageSizeChange}
      disableSelectionOnClick={true}
      rowsPerPageOptions={[5, 10, 15]}
      componentsProps={{
        pagination: {
          labelRowsPerPage: t("rowsPerPageText"),
          dir: sidebarRTL ? "rtl" : "ltr",
        },
      }}
    />
  );
};
export default CustomTable;
