import React, { useState, useEffect } from "react";
import { Box, ButtonGroup, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AddSocial from "./addSocialForm";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import RemoveSocail from "./deleteSocial";
import DeleteIcon from "@mui/icons-material/Delete";
import SocailEdit from "./editSocial";
import Pagenation from "./pagenation";
import {
  socialSpecialistData,
  socialSpecialistSearch,
} from "../../redux/socialSpecialist";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CustomSearch from "../../components/customSearch/customSearch";

const SocialSpecialist = () => {
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const loading = useSelector((state) => state.socialSpecialist.loading);
  const social =
    useSelector((state) => state.socialSpecialist.SocialSpecialist.data) || [];
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];

  const userNameWidth =
    permission.includes("update-social_specialist") &&
    permission.includes("delete-social_specialist")
      ? 300
      : 450;
  const nameWidth =
    permission.includes("update-social_specialist") &&
    permission.includes("delete-social_specialist")
      ? 200
      : 450;
  const idWidth =
    permission.includes("update-social_specialist") &&
    permission.includes("delete-social_specialist")
      ? 150
      : 330;

  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: `${t("Name")}`,
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    { field: "username", headerName: `${t("User")}`, width: userNameWidth },
    ...(permission.includes("update-social_specialist") &&
    permission.includes("delete-social_specialist")
      ? [
          {
            field: "actions",
            headerName: `${t("Actions")}`,
            width: 400,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <SocailEdit
                  sx={{
                    background: `${colors.primary[600]}`,
                    display: permission.includes("update-social_specialist")
                      ? "inline-flex"
                      : "none",
                  }}
                  ID={params.id}
                  icon={<EditIcon />}
                  pageSize={pageSize}
                />
                <RemoveSocail
                  pageSize={pageSize}
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-social_specialist")
                      ? "inline-flex"
                      : "none",
                  }}
                  icon={<DeleteIcon />}
                  id={params.id}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const info = {
      pageSize,
    };
    dispatch(socialSpecialistData(info));
  }, [dispatch, pageSize]);
  return (
    <Box m="20px">
      <Box
        display="flex"
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("Social Specialist")} />
        <AddSocial
          show={permission.includes("store-social_specialist")}
          pageSize={pageSize}
        />
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
            fontWeight: "bold",
            fontSize: "20px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiButtonGroup-root.MuiButtonGroup-outlined.css-iajp3t-MuiButtonGroup-root":
            {
              display: "flex",
              alignItems: "center",
              flexDirection: sidebarRTL ? "row-reverse" : "row",
              gap: sidebarRTL ? "35px" : "0px",
            },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
            justifyContent: sidebarRTL
              ? "flex-end !important"
              : "flex-start !important",
          },
          "& .MuiDataGrid-columnHeaderDraggableContainer": {
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: sidebarRTL ? "end !important" : "start !important",
            flexDirection: sidebarRTL
              ? "row-reverse !important"
              : "row !important",
          },
        }}
      >
        <CustomSearch action={socialSpecialistSearch} pageSize={pageSize} />
        <DataGrid
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
          }}
          rows={social && social}
          columns={sidebarRTL ? columns.reverse() : columns}
          loading={loading && true}
          autoHeight
          pagination
          disableSelectionOnClick={true}
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("rowsPerPageText"),
              dir: sidebarRTL ? "rtl" : "ltr",
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15]}
        />
        <Pagenation pageSize={pageSize} />
      </Box>
    </Box>
  );
};

export default SocialSpecialist;
