import React from 'react'
import StudyPlaneTable from './StudyPlaneTable'

const StudyPlane = () => {
    return (
        <div>
            <StudyPlaneTable />
        </div>
    )
}

export default StudyPlane
