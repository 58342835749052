import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  useMediaQuery,
  IconButton,
  InputAdornment,
  colors,
} from "@mui/material";
import "./profile.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../redux/profile";
import { changePassword } from "../../redux/changePassowrd";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

const Profile = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Toggle for password visibility
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => state.profile.userInfo.data) || {};

  const validationSchema = yup.object({
    currentPassword: yup.string().required("Required"),
    newPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])/,
        "Password must contain at least one uppercase letter and one lowercase letter"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Required")
      .min(8)
      .oneOf([yup.ref("newPassword")], "Passwords must match"),
  });

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePasswordChange = (values) => {
    dispatch(
      changePassword({
        old_password: values.currentPassword,
        new_password: values.newPassword,
        new_password_confirmation: values.confirmPassword,
      })
    ).then((res) => {
      res.payload.code && setIsModalOpen(false);
    });
  };

  const { sidebarRTL } = useSidebarContext();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box m="20px">
      <Header title={t("USER PROFILE")} />

      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="#0000005e"
        padding="25px"
        borderRadius="10px"
        gap="20px"
        textAlign={sidebarRTL ? "right" : "left"}
      >
        <div
          className="mainProfileData"
          style={{ flexDirection: sidebarRTL ? "row-reverse" : "row" }}
        >
          <div
            className="profileDataContainer"
            style={{ flexDirection: sidebarRTL ? "row-reverse" : "row" }}
          >
            <p>{t("ID")} </p>
            <span style={{ margin: "0px 10px" }}>{profileInfo.id}</span>
          </div>
          <div
            className="profileDataContainer"
            style={{ flexDirection: sidebarRTL ? "row-reverse" : "row" }}
          >
            <p>{t("Username")} </p>
            <span style={{ margin: "0px 10px" }}>{profileInfo.username}</span>
          </div>
          <div
            className="profileDataContainer"
            style={{ flexDirection: sidebarRTL ? "row-reverse" : "row" }}
          >
            <p>{t("Name")} </p>
            <span style={{ margin: "0px 10px" }}>{profileInfo.name}</span>
          </div>
          <div
            className="profileDataContainer"
            style={{ flexDirection: sidebarRTL ? "row-reverse" : "row" }}
          >
            <p>{t("Phone")} </p>
            <span style={{ margin: "0px 10px" }}>{profileInfo.phone}</span>
          </div>
        </div>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0AAD0A",
            color: "#ffffff",
            fontSize: "18px",
            fontWeight: "blod",
            width: isNonMobile ? "50%" : "100%",
            height: "50px",
            margin: "1rem auto",
            alignSelf: isNonMobile
              ? sidebarRTL
                ? "flex-end"
                : "flex-start"
              : "center",
          }}
          onClick={handleModalOpen}
        >
          {t("Change Password")}
        </Button>

        <Modal
          sx={{ direction: sidebarRTL ? "rtl" : "ltr" }}
          open={isModalOpen}
          onClose={handleModalClose}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#ccc",
              p: 4,
              minWidth: "300px",
              maxWidth: "400px",
              borderRadius: "8px",
              boxShadow: 24,
              gap: "20px",
            }}
          >
            <Typography variant="h5" gutterBottom>
              {t("Change Password")}
            </Typography>
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handlePasswordChange}
            >
              {({ touched, errors, values }) => (
                <Form>
                  <Field
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Current Password")}
                    name="currentPassword"
                    error={touched.currentPassword && !!errors.currentPassword}
                    helperText={
                      touched.currentPassword && errors.currentPassword
                    }
                    value={values.currentPassword}
                    sx={{ marginBottom: "20px", marginTop: "20px" }}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("New Password")}
                    name="newPassword"
                    error={touched.newPassword && !!errors.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    value={values.newPassword}
                    sx={{ marginBottom: "20px", marginTop: "20px" }}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Confirm New Password")}
                    name="confirmPassword"
                    error={touched.confirmPassword && !!errors.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    value={values.confirmPassword}
                    sx={{ marginBottom: "20px", marginTop: "20px" }}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        backgroundColor: "#3e4396",
                        color: "#ffffff",
                        textTransform: "lowercase",
                      }}
                    >
                      {t("Save")}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Profile;
