// src/components/EditRolePage.js
import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { preparePermissions } from "./prepareRoles";
import {
  editRoles,
  getOneRole,
  getPermission,
} from "../../redux/responsibility";
import Table from "./GridComponent";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "../../theme";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";

const EditRolePage = ({ icon, pageSize }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const loading = useSelector((state) => state.roles.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the role id from the URL
  const { sidebarRTL } = useSidebarContext();

  useEffect(() => {
    dispatch(getPermission());
    if (id) {
      dispatch(getOneRole({ id })).then((res) => {
        setName(res.payload.data.name);
        const filteredPermissions = res.payload.data.permissions.filter(
          (permission) => permission.status === true
        );
        setPermissions(filteredPermissions);
      });
    }
  }, [dispatch, id]);

  const allRoles =
    useSelector((state) => state.roles.getPermission?.data) || [];

  const { uniqueRoles, uniqueOperations, uniquePermissions } =
    preparePermissions(allRoles);

  const handleCheckboxChange = (newCheckedItems) => {
    setSelectedIds(Object.keys(newCheckedItems));
  };

  const handleEdit = () => {
    dispatch(editRoles({ name, permissions: selectedIds, id })).then((res) => {
      res.payload.code === 200 && navigate("/roles");
    });
  };

  return (
    <Box m="20px" dir={sidebarRTL ? "rtl" : "ltr"}>
      <Typography variant="h4" gutterBottom>
        {t("Edit", " ", "role")}
      </Typography>
      <Box>
        <Typography
          component="label"
          variant="body1"
          fontWeight="bold"
          mb={1}
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {t("Name")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          placeholder={t("Name")}
          dir={sidebarRTL ? "rtl" : "ltr"}
          type="text"
          fullWidth
          variant="outlined"
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          sx={{ fontWeight: "bold", fontSize: "16px" }}
        />
        <Table
          uniquePermissions={uniquePermissions}
          uniquePermissionsLength={Object?.keys(uniquePermissions)?.length}
          uniqueOperations={uniqueOperations}
          uniqueRoles={uniqueRoles}
          onCheckboxChange={handleCheckboxChange}
          initialPermissions={permissions}
        />
      </Box>
      <Box
        className="btnsContainer"
        dir={sidebarRTL ? "rtl" : "ltr"}
        sx={{
          width: "100%",
          margin: "3rem auto",
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: colors.redAccent[500],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: colors.redAccent[500],
            },
            flex: 1,
            maxWidth: "calc(50% - 0.5rem)",
          }}
          onClick={() => navigate("/Responsibility")}
          startIcon={<CancelIcon />}
        >
          {t("Cancel")}
        </Button>
        <Button
          sx={{
            backgroundColor: colors.greenAccent[400],
            color: colors.primary[300],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 15px",
            "&:hover": {
              backgroundColor: colors.greenAccent[500],
            },
            flex: 1,
            maxWidth: "calc(50% - 0.5rem)",
          }}
          onClick={handleEdit}
          disabled={!name}
          startIcon={<VerifiedIcon />}
        >
          {loading ? t("wait") : t("Edit")}
        </Button>
      </Box>
    </Box>
  );
};

export default EditRolePage;
