import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getExams,
  getFullMarkGrades,
  getFullMarkSubjects,
} from "../../redux/FullMarks";
import { Select, FormControl, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const DropDownGradesSubjects = ({ setFormikValues }) => {
  const { t } = useTranslation();
  const [selectedGradeId, setSelectedGradeId] = useState();
  const [selectedSubjectId, setSelectedSubjectId] = useState();
  const [selectedExamId, setSelectedExamId] = useState(null);
  const AllGrades =
    useSelector((state) => state.FullMarks.getGradesData.data) || [];
  const AllSubjects =
    useSelector((state) => state.FullMarks.getSubjectsData.data) || [];
  const exams = useSelector((state) => state.FullMarks.getExamsData.data) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFullMarkGrades());
    dispatch(getExams());
  }, [dispatch]);

  useEffect(() => {
    if (selectedGradeId) {
      dispatch(getFullMarkSubjects({ selectedGradeId }));
    }
  }, [dispatch, selectedGradeId]);

  const handleSelectChangeGrade = (event) => {
    const selectedGradeId = event.target.value;
    setSelectedGradeId(selectedGradeId);
    setSelectedSubjectId(null);
    setSelectedExamId(null);
    setFormikValues("grade_id", selectedGradeId);
  };

  const handleSelectChangeSubject = (event) => {
    setSelectedSubjectId(event.target.value);
    setSelectedExamId(null);
    setFormikValues("subject_id", event.target.value);
  };

  const handleSelectChangeExam = (event) => {
    setSelectedExamId(event.target.value);
    setFormikValues("exam_id", event.target.value);
  };

  const { sidebarRTL } = useSidebarContext();

  return (
    <Box
      m="20px"
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      gap="20px"
      dir={sidebarRTL ? "rtl" : "ltr"}
    >
      <FormControl variant="outlined" sx={{ gridColumn: "span 1" }}>
        {" "}
        {/* عرض 1/2 */}
        <Typography
          component="label"
          variant="body1"
          fontWeight="bold"
          mb={1}
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {t("Grade")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          maxHeight={"100px"}
          value={selectedGradeId}
          onChange={handleSelectChangeGrade}
          dir={sidebarRTL ? "rtl" : "ltr"}
          fullWidth
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: "#000",
              fontSize: "20px",
              fontWeight: "bold",
              direction: sidebarRTL ? "rtl" : "ltr",
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
          }}
        >
          {AllGrades &&
            AllGrades.map((gradeItem) => (
              <MenuItem
                dir={sidebarRTL ? "rtl" : "ltr"}
                key={gradeItem.id}
                value={gradeItem.id}
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                {gradeItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" sx={{ gridColumn: "span 1" }}>
        {" "}
        {/* عرض 1/2 */}
        <Typography
          component="label"
          variant="body1"
          fontWeight="bold"
          mb={1}
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {t("Subject")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          value={selectedSubjectId}
          onChange={handleSelectChangeSubject}
          dir={sidebarRTL ? "rtl" : "ltr"}
          fullWidth
          disabled={selectedGradeId}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: "#000",
              fontSize: "20px",
              fontWeight: "bold",
              direction: sidebarRTL ? "rtl" : "ltr",
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
          }}
        >
          {AllSubjects &&
            AllSubjects.map((subjectItem) => (
              <MenuItem
                dir={sidebarRTL ? "rtl" : "ltr"}
                key={subjectItem.id}
                value={subjectItem.id}
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                {subjectItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" sx={{ gridColumn: "span 2" }}>
        {" "}
        {/* عرض 1/2 */}
        <Typography
          component="label"
          variant="body1"
          fontWeight="bold"
          mb={1}
          style={{ fontSize: "20px", fontWeight: "bold" }}
        >
          {t("Exams")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Select
          value={selectedExamId}
          onChange={handleSelectChangeExam}
          dir={sidebarRTL ? "rtl" : "ltr"}
          fullWidth
          disabled={selectedSubjectId}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              color: "#000",
              fontSize: "20px",
              fontWeight: "bold",
              direction: sidebarRTL ? "rtl" : "ltr",
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }),
            container: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
              direction: sidebarRTL ? "rtl" : "ltr",
            }),
          }}
        >
          {exams &&
            exams.map((examItem) => (
              <MenuItem
                dir={sidebarRTL ? "rtl" : "ltr"}
                key={examItem.id}
                value={examItem.id}
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                {examItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDownGradesSubjects;
