import React, { useState, useEffect } from "react";
import { Box, ButtonGroup, Chip, CircularProgress, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import AddGrade from "./AddGrade";
import DeleteGrade from "./DeleteGrade";
import EditGrade from "./EditGrade";
import { fetchGradesDataByPage, getGrades, getSearchGrades } from "../../redux/Grades";
import GradeInfoPopup from "./GradeInformation";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { useTranslation } from "react-i18next";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";
import CustomToolTip from "../../components/CustomToolTip/customToolTip";

const GradesTable = () => {
  const nextPage = useSelector(state => state.grades.gradesLinks.next)
    const lastPage = useSelector(state => state.grades.gradesLinks.last)
    const prevPage = useSelector(state => state.grades.gradesLinks.prev)
    const currentPage = useSelector(state => state.grades.currentPage)
    const firstPage = useSelector(state => state.grades.gradesLinks.first)
  const { t } = useTranslation();
  const { sidebarRTL } = useSidebarContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const info = { pageSize };
  const [selectedGrade, setSelectedGrade] = useState(null);
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];

  const handleOpenPopup = (grade) => {
    setSelectedGrade(grade);
  };

  const handleClosePopup = () => {
    setSelectedGrade(null);
  };

  useEffect(() => {
    dispatch(getGrades(info));
  }, [dispatch, pageSize]);

  const data = useSelector((state) => state?.grades?.grades?.data) || {};
  const loading = useSelector((state) => state?.grades?.loading);

  const nameWidth =
    permission.includes("update-grade") && permission.includes("delete-grade")
      ? 300
      : 700;
  const idWidth =
    permission.includes("update-grade") && permission.includes("delete-grade")
      ? 475
      : 400;
  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <CustomToolTip text={params.value} />
      )
      
    },
    ...(permission.includes("update-grade") &&
    permission.includes("delete-grade")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 400,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditGrade
                  sx={{
                    background: `${colors.primary[600]}`,
                    m: 4,
                    display: permission.includes("update-grade")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<EditIcon />}
                  pageSize={pageSize}
                />
                <DeleteGrade
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-grade")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  pageSize={pageSize}
                  icon={<DeleteForeverIcon />}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  const tableData = {
    rows:data.length>0 && data,
    columns:columns,
    loading:loading===true,
    pageSize:pageSize,
    onPageSizeChange:(newPageSize) => setPageSize(newPageSize)
  }
  return (
    <Box m="20px">
      {selectedGrade && (
        <GradeInfoPopup
          show={permission.includes("show-grade")}
          grade={selectedGrade}
          open={!!selectedGrade}
          onClose={handleClosePopup}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
      >
        <Header style={!permission.includes("all-grade")} title={t("Grades")} />
        <AddGrade
          show={permission.includes("store-grade")}
          pageSize={pageSize}
          icon={<AddIcon />}
        />
      </Box>
      <CustomTableBox
        tableData={tableData}
        action={getSearchGrades}
        CustomPagenation={
            <CustomPagenation
              pageSize={pageSize}
              nextPage={nextPage}
              lastPage={lastPage}
              prevPage={prevPage}
              firstPage={firstPage}
              currentPage={currentPage}
              action={fetchGradesDataByPage}
            />
          }
          />
    </Box>
  );
};

export default GradesTable;
