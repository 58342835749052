import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Container,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { addParent, parentData } from "../../redux/parents";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";

const AddParents = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { sidebarRTL } = useSidebarContext();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const loading = useSelector((state) => state.parentData.loading);
  const initialValues = {
    type: "parent",
    name: "",
    username: "",
    phone: "",
    password: "",
    password_confirmation: "",
  };
  const checkoutSchema = yup.object().shape({
    name: yup.string().required(`${t("this-is-required")}`),
    username: yup.string().required(`${t("this-is-required")}`),
    phone: yup.number().required(`${t("this-is-required")}`),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Z][a-z])/,
        "Password must contain at least one uppercase letter and least one lowercase letter"
      )
      .required(`${t("this-is-required")}`),
    password_confirmation: yup
      .string()
      .required(`${t("this-is-required")}`)
      .min(8)
      .oneOf([yup.ref("password")], `${t("this-is-required")}`),
  });
  const handleFormSubmit = async (values) => {
    await dispatch(addParent(values)).then((res) => {
      if (res.payload.code === 201) {
        dispatch(parentData({ pageSize: 10 }));
      }
    });
  };

  return (
    <Container>
      <Box m="20px">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontSize: "22px", fontWeight: "bold" }}
        >
          {t("add-parent")}
        </Typography>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                dir={sidebarRTL ? "rtl" : "ltr"}
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 2" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    {t("Name")}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder={t("Name")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" },
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 2" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    {t("Username")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder={t("Username")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" },
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 2" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    {t("Password")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Password")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 2" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    {t("Password Confirmation")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("Password Confirmation")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password_confirmation}
                    name="password_confirmation"
                    error={
                      !!touched.password_confirmation &&
                      !!errors.password_confirmation
                    }
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    InputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ gridColumn: "span 4" }}
                >
                  <Typography
                    component="label"
                    variant="body1"
                    fontWeight="bold"
                    mb={1}
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    {t("Phone")}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder={t("Phone")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    name="phone"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 2" }}
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    inputProps={{
                      style: { fontSize: "18px", fontWeight: "bold" },
                    }}
                  />
                </Box>
              </Box>

              <Box
                className="btnsContainer"
                dir={sidebarRTL ? "rtl" : "ltr"}
                sx={{
                  width: "100%",
                  margin: "3rem auto",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Button
                  type="submit"
                  className="EditBtnStudent"
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  sx={{
                    textAlign: "center",
                    background: colors.blueAccent[500],
                    fontWeight: "bold",
                    padding: "1rem",
                    flex: "1",
                    maxWidth: "calc(50% - 0.5rem)",
                  }}
                  endIcon={
                    <VerifiedIcon
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      sx={{ margin: sidebarRTL ? ".5rem" : "0" }}
                    />
                  }
                  variant="contained"
                >
                  {t("add-parent")}
                </Button>
                <Link
                  className="cancelBtnStudentLink"
                  to="/parents"
                  style={{ flex: "1", maxWidth: "calc(50% - 0.5rem)" }}
                >
                  <Button
                    className="cancelBtnStudent"
                    dir={sidebarRTL ? "rtl" : "ltr"}
                    sx={{
                      textAlign: "center",
                      background: colors.redAccent[600],
                      fontWeight: "bold",
                      padding: "1rem",
                      flex: "1",
                      width: "100%",
                    }}
                    endIcon={
                      <CancelIcon
                        dir={sidebarRTL ? "rtl" : "ltr"}
                        sx={{ margin: sidebarRTL ? ".5rem" : "0" }}
                      />
                    }
                    variant="contained"
                  >
                    {t("Cancel")}
                  </Button>
                </Link>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default AddParents;
