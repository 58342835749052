import {useTranslation} from "react-i18next";
// import { useSidebarContext } from '../global/sidebar/sidebarContext';
import defaultAPI from "../../axiosInstance";
import CallIcon from "@mui/icons-material/Call";
import "./InvoiceStyle.css";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import {Link} from "react-router-dom"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const InstallmentInvoice = () => {
    const {student_id} = useParams();

    const {t} = useTranslation();
    const [invoiceData, setInvoiceData] = useState(null);
    useEffect(() => {
        defaultAPI
            .get(`exports/students/${student_id}/installments`)
            .then((res) => setInvoiceData(res.data.data));
    }, [invoiceData === null]);

    let totalPaidExpenses = 0;

    if (invoiceData && invoiceData.installments) {
        totalPaidExpenses = invoiceData.installments
            .map(function (installment) {
                return parseFloat(
                    installment.paidExpenses.split(" ")[0].replace(/,/g, "")
                );
            })
            .reduce((number, numberTwo) => {
                return number + numberTwo;
            }, 0);
    }

    const [printing, setPrinting] = useState(false);

    const handlePrintClick = () => {
        // Set the printing state to true when the print button is clicked
        setPrinting(true);

        // Trigger the browser's print dialog after a brief delay
        setTimeout(() => {
            window.print();

            // After printing, set the printing state back to false
            setPrinting(false);
        }, 100); // Adjust the delay time as needed
    };
    return (
        <div dir={localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"} className="invoiceContainer">
            <div className="buttonContainer">
                <Link to={`/student-installments-info/${student_id}`}>
                    <button style={{display: printing ? "none" : "inline-block"}}>
                        <KeyboardBackspaceIcon/>
                    </button>
                </Link>
                <button style={{display: printing ? "none" : "inline-block"}} onClick={handlePrintClick}>
                    <LocalPrintshopIcon/>
                </button>
            </div>

            <div
                dir={localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"}
                className="header-section"
            >
                <div
                    dir={localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"}
                    className="student-info"
                >
                    {t("student_name")}: {invoiceData && invoiceData.studentInfo.name}
                    <br/>
                    {t("ID")}: {invoiceData && invoiceData.studentInfo.id}
                    <br/>
                    {t("invoice_date")}: {invoiceData && invoiceData.createdAt}
                </div>
                <div className="school-info">
                    <img
                        src={`${invoiceData && invoiceData.schoolInfo.logo}`}
                        alt="School Logo"
                        className="school-logo"
                    />
                    <div className="school-name">
                        {invoiceData && invoiceData.schoolInfo.title}
                    </div>
                </div>
            </div>
            <table className="outer-table">
                <thead>
                <tr>
                    <th className="header">{t("total_expenses")}</th>
                    <th className="header">{t("paid_expenses")}</th>
                    <th className="header">{t("Reports")}</th>
                </tr>
                </thead>
                <tbody>
                {invoiceData &&
                    invoiceData.installments.map((item, index) => (
                        <tr key={index}>
                            <td>{item.totalExpenses}</td>
                            <td>{item.paidExpenses}</td>
                            {item.installmentReports.length ?( <td>
                                <table className="inner-table">
                                    <thead>
                                    <tr>
                                        <th>{t("amount")}</th>
                                        <th>{t("date")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {item.installmentReports.map((smallTable, idx) => (
                                        <tr key={idx}>
                                            <td>{smallTable.amount}</td>
                                            <td>{smallTable.createdAt}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </td>) : <p style={{ textAlign: 'center', fontWeight: "bold"}}>{t('no_reports_to_show')}</p>}

                        </tr>
                    ))}
                </tbody>
                <tfoot>
                <tr>
                    <th colSpan="2" className="total-expenses">
                        {t("total_paid_expenses")}
                    </th>
                    <td colSpan="2">
                        {totalPaidExpenses} {invoiceData && invoiceData.currency}
                    </td>
                </tr>
                </tfoot>
            </table>
            {/* {invoiceData&&invoiceData.schoolInfo.facebook} */}
            <div className="footer-section">
                <div className="social-media-section">
                    <div className="Phones">
                        <div className="IconDiv">
                            <CallIcon
                                sx={{
                                    fontSize: "30px",
                                    margin: "auto",
                                }}
                                className="icon"
                            />
                        </div>
                        <div classNames="dataContainer">
                            <a disabled="true">
                                {invoiceData && invoiceData.schoolInfo.phone}
                            </a>
                        </div>
                    </div>
                    <div className="Links">
                        <div className="IconDiv">
                            <LocationOnIcon
                                className="icon"
                                sx={{
                                    fontSize: "30px",
                                    margin: "auto",
                                }}
                            />
                        </div>
                        <div classNames="dataContainer">
                            <a disabled="true">
                                {invoiceData && invoiceData.schoolInfo.address}
                            </a>
                        </div>
                    </div>
                    <div className="location">
                        <div className="IconDiv">
                            <PublicIcon
                                sx={{
                                    fontSize: "30px",
                                    margin: "auto",
                                }}
                                className="icon"
                            />
                        </div>
                        <div classNames="dataContainer">
                            <a disabled="true">
                                {invoiceData && invoiceData.schoolInfo.email}
                            </a>
                            <br/>
                            <a disabled="true">
                                {invoiceData && invoiceData.schoolInfo.facebook}
                            </a>
                            <br/>
                            <a disabled="true">
                                {invoiceData && invoiceData.schoolInfo.whatsapp}
                            </a>
                            <br/>
                            <a disabled="true">
                                {invoiceData && invoiceData.schoolInfo.telegram}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="contact-info">
                    <p>
                        {t("contact_us")}{" "}
                        <span>{invoiceData && invoiceData.schoolInfo.email}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};
