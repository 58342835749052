import React from 'react'
import ParentsTable from './parentsTable'

const Parents = () => {
    return (
        <div>
            <ParentsTable />
        </div>
    )
}

export default Parents
