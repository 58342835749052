import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import defaultAPI from "../axiosInstance";
export const Responsibility = createAsyncThunk(
  "roles/Responsibility",
  async (info) => {
    let { pageSize } = info
    const apiUrl = `/roles?per_page=${pageSize}`;
    try {
      const res = await defaultAPI.get(apiUrl);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const ResponsibilitySearch = createAsyncThunk(
  "roles/ResponsibilitySearch",
  async (info) => {
    let { pageSize } = info
    const apiUrl = `/roles?handle=${info.handle}&per_page=${pageSize}`;
    try {
      const res = await defaultAPI.get(apiUrl);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getOneRole = createAsyncThunk(
  "roles/getOneRole",
  async ({ id }) => {
    const apiUrl = `/roles/${id}`;
    try {
      const res = await defaultAPI.get(apiUrl);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteResponsibility = createAsyncThunk(
  "roles/deleteResponsibility",
  async ({ id }) => {
    const apiUrl = `/roles/${id}`;

    try {
      const res = await defaultAPI.delete(apiUrl);
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const editRoles = createAsyncThunk("school/editRoles", async ({ name, permissions, id }) => {
  const apiUrl = `/roles/${id}`;
  try {
    const res = await defaultAPI.put(apiUrl, { name, permissions });
    return res.data;
  } catch (error) {
    return error.response.data.data;
  }
});

export const getRoles = createAsyncThunk("roles/rolesMenu", async () => {
  const apiUrl = "select_menu/roles";

  try {
    const res = await defaultAPI.get(apiUrl);
    return res.data;
  } catch (error) {
    throw error;
  }
});
export const getPermission = createAsyncThunk("roles/getPermission", async () => {
  const apiUrl = "select_menu/permissions";

  try {
    const res = await defaultAPI.get(apiUrl);
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const addRole = createAsyncThunk("roles/addRole", async (info) => {
  const { name, permissions } = info;
  const apiUrl = "/roles";

  try {
    const res = await defaultAPI.post(apiUrl, { name, permissions });
    return res.data;
  } catch (error) {
    // console.log(object)
    return error.response.data;
  }
});

export const fetchRolesDataByPage = (info) => async (dispatch) => {
  let { state, pageSize } = info
  try {
    dispatch(Responsibility.pending());
    const response = await defaultAPI.get(`${state}&per_page=${pageSize}`);
    const data = response.data;
    dispatch(Responsibility.fulfilled(data));
    dispatch(setCurrentPage(state));
  } catch (error) {
    console.log(error)
    dispatch(Responsibility.rejected());
  }
};
const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    roles: [],
    getRoles: [],
    responsibilityInfo: [],
    getPermission: [],
    RolesLinks: {
      first: null,
      last: null,
      next: null,
      prev: null,
    },
    currentPage: null,
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: {
    [Responsibility.pending]: (state) => {
      state.loading = true;
    },
    [Responsibility.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.roles = action.payload;
      state.RolesLinks.first = action.payload.links.first;
      state.RolesLinks.last = action.payload.links.last;
      state.RolesLinks.prev = action.payload.links.prev;
      state.RolesLinks.next = action.payload.links.next;
      state.currentPage = action.payload.meta.current_page;
    },
    [ResponsibilitySearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = false;
      state.roles = action.payload;
      state.RolesLinks.first = action.payload.links.first;
      state.RolesLinks.last = action.payload.links.last;
      state.RolesLinks.prev = action.payload.links.prev;
      state.RolesLinks.next = action.payload.links.next;
      state.currentPage = action.payload.meta.current_page;
    },
    [Responsibility.rejected]: (state) => {
      state.error = true;
    },
    [getRoles.pending]: (state) => {
      state.loading = true;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.getRoles = action.payload;
      state.error = false;
    },
    [getRoles.rejected]: (state) => {
      state.error = true;
    },
    [getPermission.pending]: (state) => {
      state.loading = true;
    },
    [getPermission.fulfilled]: (state, action) => {
      state.loading = false;
      state.getPermission = action.payload;
      state.error = false;
    },
    [getPermission.rejected]: (state) => {
      state.error = true;
    },
    [getOneRole.pending]: (state) => {
      state.loading = true;
    },
    [getOneRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.responsibilityInfo = action.payload;
      state.error = false;
    },
    [getOneRole.rejected]: (state) => {
      state.error = true;
    },
  },
});
export const { setCurrentPage } = rolesSlice.actions;
export default rolesSlice.reducer;