import React, { useState, useEffect } from "react";
import { Box, ButtonGroup, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import Header from "../../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import SubjectInfoPopUp from "./SubjectInformation";
import { fetchSubjectsDataByPage, getSubjects, getSubjectSearch } from "../../redux/Subjects";
import AddSubject from "./AddSubject";
import DeleteSubject from "./DeleteSubject";
import EditSubject from "./EditSubject";
import Pagenation from "./pagenation";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { useTranslation } from "react-i18next";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import CustomPagenation from "../../components/CustomPagenation/CustomPagenation";

const SubjectsTable = () => {
  const nextPage = useSelector(state => state.subjects.subjectsLinks.next)
    const lastPage = useSelector(state => state.subjects.subjectsLinks.last)
    const prevPage = useSelector(state => state.subjects.subjectsLinks.prev)
    const currentPage = useSelector(state => state.subjects.currentPage)
    const firstPage = useSelector(state => state.subjects.subjectsLinks.first)
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const info = { pageSize };
  const [selectedSubject, setselectedSubject] = useState(null);

  const handleOpenPopup = (subject) => {
    setselectedSubject(subject);
  };

  const handleClosePopup = () => {
    setselectedSubject(null);
  };

  useEffect(() => {
    dispatch(getSubjects(info));
  }, [dispatch, pageSize]);

  const data = useSelector((state) => state?.subjects?.subjects?.data) || {};
  const loading = useSelector((state) => state?.subjects?.loading);
  const nameWidth =
    permission.includes("update-subject") &&
    permission.includes("delete-subject")
      ? 300
      : 700;
  const idWidth =
    permission.includes("update-subject") &&
    permission.includes("delete-subject")
      ? 150
      : 480;
  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <ButtonGroup>
          <span
            onClick={() => handleOpenPopup(params.row)}
            style={{ cursor: "pointer" }}
          >
            {params.value}
          </span>
        </ButtonGroup>
      ),
    },
    ...(permission.includes("delete-subject") &&
    permission.includes("update-subject")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 400,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditSubject
                  sx={{
                    background: `${colors.primary[600]}`,
                    m: 4,
                    display: permission.includes("update-subject")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  icon={<EditIcon />}
                  pageSize={pageSize}
                />
                <DeleteSubject
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-subject")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  pageSize={pageSize}
                  icon={<DeleteForeverIcon />}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  const tableData = {
    rows:data.length>0 && data,
    columns:columns,
    loading:loading===true,
    pageSize:pageSize,
    onPageSizeChange:(newPageSize) => setPageSize(newPageSize)
  }
  return (
    <Box m="20px">
      {selectedSubject && (
        <SubjectInfoPopUp
          subject={selectedSubject}
          open={!!selectedSubject}
          onClose={handleClosePopup}
        />
      )}
      <Box
        flexDirection={sidebarRTL ? "row-reverse" : "row"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={t("Subjects")} />
        <AddSubject
          show={permission.includes("store-subject") ? "inline-flex" : "none"}
          pageSize={pageSize}
          icon={<AddIcon />}
        />
      </Box>
      <CustomTableBox
        tableData={tableData}
        action={getSubjectSearch}
        CustomPagenation={
            <CustomPagenation
              pageSize={pageSize}
              nextPage={nextPage}
              lastPage={lastPage}
              prevPage={prevPage}
              firstPage={firstPage}
              currentPage={currentPage}
              action={fetchSubjectsDataByPage}
            />
          }
          />
    </Box>
  );
};

export default SubjectsTable;
