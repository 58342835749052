import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { editExam, fetchExamsData } from "../../redux/Exams";
import { tokens } from "../../theme";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const EditExam = (props) => {
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const loading = useSelector((state) => state.exams.loading);

  const handleFormSubmit = (values) => {
    const info = {
      values: values,
      id: props.ID,
    };
    const redispatchInfo = {
      pageSize: props.pageSize,
    };
    dispatch(editExam(info)).then(() => {
      dispatch(fetchExamsData(redispatchInfo));
    });
    setOpen(false);
  };

  const initialValues = {
    name: props.name,
    default_full_mark: props.defaultFullMark,
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required(`${t("this-is-required")}`),
    default_full_mark: yup.number().required(`${t("this-is-required")}`),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: checkoutSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      m="20px"
      sx={{
        backgroundColor: `${colors.primary[600]}`,
      }}
    >
      <Button
        variant="contained"
        sx={props.sx}
        endIcon={props.icon}
        onClick={handleClickOpen}
      >
        {t("edit-exam")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          align={sidebarRTL ? "right" : "left"}
        >
          {t("Edit")}
        </DialogTitle>
        <DialogContent dir={sidebarRTL ? "rtl" : "ltr"}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Name")} <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder={t("Name")}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                  error={formik.touched.name && formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{ gridColumn: "span 2" }}
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ gridColumn: "span 2" }}
              >
                <Typography
                  component="label"
                  variant="body1"
                  fontWeight="bold"
                  mb={1}
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  {t("Default Full Mark")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  placeholder={t("Default Full Mark")}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.default_full_mark}
                  name="default_full_mark"
                  error={
                    formik.touched.default_full_mark &&
                    formik.errors.default_full_mark
                  }
                  helperText={
                    formik.touched.default_full_mark &&
                    formik.errors.default_full_mark
                  }
                  sx={{ gridColumn: "span 2" }}
                  dir={sidebarRTL ? "rtl" : "ltr"}
                  inputProps={{
                    style: { fontSize: "18px", fontWeight: "bold" }, // Adjust the font size here
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="start" mt="20px" gap={4}>
                <Button
                  sx={{
                    backgroundColor: colors.redAccent[500],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    "&:hover": {
                      backgroundColor: colors.redAccent[500],
                    },
                  }}
                  onClick={() => setOpen(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.greenAccent[400],
                    color: colors.primary[300],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 15px",
                    "&:hover": {
                      backgroundColor: colors.greenAccent[500],
                    },
                  }}
                  disabled={loading}
                  variant="outlined"
                >
                  {loading ? "wait..." : t("Edit")}
                </Button>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditExam;
