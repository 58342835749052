import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, Switch, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TermsData } from "../../redux/Terms";
import { useSidebarContext } from "../global/sidebar/sidebarContext";
import { useTranslation } from "react-i18next";
import CustomTableBox from "../../components/customTableBox/CustomTableBox";
import DeleteTerm from "./DeleteTerm";
import EditTerm from "./EditTerm";
import TermSwitch from "./Switch";
import AddTerm from "./AddTerm";
const TermsTable = () => {
  const terms = useSelector(state=>state.Terms.getTermsData.data)||[]
  const permission =
    useSelector((state) => state.user.userInfo.data.permissions) || [];
  const { sidebarRTL } = useSidebarContext();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  
  const loading = useSelector((state) => state.Terms.loading);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const createdWidth =
    permission.includes("update-term") &&
    permission.includes("delete-term")
      ? 200
      : 400;
  const nameWidth =
    permission.includes("update-term") &&
    permission.includes("delete-term")
      ? 200
      : 300;
  const idWidth =
    permission.includes("update-term") &&
    permission.includes("delete-term")
      ? 130
      : 230;
  const typeWidth =
    permission.includes("update-term") &&
    permission.includes("delete-term")
      ? 150
      : 150;

  const columns = [
    { field: "id", headerName: t("ID"), width: idWidth },
    {
      field: "name",
      headerName: t("Name"),
      width: nameWidth,
      cellClassName: "name-column--cell",
    },
    { field: "startDate", headerName: t("StartDate"), width: 150 },
    { field: "endDate", headerName: t("EndDate"), width: 150 },
    {
      field: "isCurrentTerm",
      headerName: t("isCurrentTerm"),
      width: 100,
      renderCell: (params) => (
        <TermSwitch
          id={params.row.id}
          isCurrentTerm={params.row.isCurrentTerm}
          disabled={params.row.isCurrentTerm}
        />
          )
    },
    
    ...(permission.includes("update-term") &&
    permission.includes("delete-term")
      ? [
          {
            field: "actions",
            headerName: t("Actions"),
            width: 300,
            renderCell: (params) => (
              <ButtonGroup
                sx={{
                  display: "flex",
                  flexDirection: sidebarRTL
                    ? "row-reverse !important"
                    : "row !important",
                  alignItems: "center",
                }}
              >
                <EditTerm
                  sx={{
                    background: `${colors.primary[600]}`,
                    display: permission.includes("update-term")
                      ? "inline-flex"
                      : "none",
                  }}
                  id={params.id}
                  name={params.row.name}
                  startDate={params.row.startDate}
                  endDate={params.row.endDate}
                  icon={<EditIcon />}
                />
                <DeleteTerm
                  sx={{
                    background: `${colors.redAccent[600]}`,
                    display: permission.includes("delete-term")
                      ? "inline-flex"
                      : "none",
                  }}
                  icon={<DeleteIcon />}
                  id={params.id}
                />
              </ButtonGroup>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    dispatch(TermsData());
  }, [dispatch])
  
  const tableData={
    rows:terms.length>0 && terms,
    columns: columns,
    loading:loading && true,
    pageSize:pageSize,
    onPageSizeChange:(newPageSize) => setPageSize(newPageSize)
}
  return (
    <Box m="20px">
      <Header dir={sidebarRTL ? "rtl" : "ltr"} title={t("terms")} />
      <Box
        display="flex"
        flexDirection={sidebarRTL ? "end" : "start"}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        dir={sidebarRTL ? "rtl" : "ltr"}
      >
        <Box
          dir={sidebarRTL ? "rtl" : "ltr"}
          width="50%"
          sx={{ display: "inline-flex", marginTop: "1rem" }}
        >
          <AddTerm
            show={permission.includes("store-term")}
            pageSize={pageSize}
            dir={sidebarRTL ? "rtl" : "ltr"}
          />
        </Box>
      </Box>
      <CustomTableBox
        tableData={tableData}
      />
    </Box>
  );
};
export default TermsTable;