import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { addExam, fetchExamsData } from "../../redux/Exams";
import { useTranslation } from "react-i18next";
import { useSidebarContext } from "../global/sidebar/sidebarContext";

const AddExam = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const loading = useSelector((state) => state.exams.loading);

  const initialValues = {
    name: "",
    default_full_mark: "",
  };

  const checkoutSchema = yup.object().shape({
    name: yup.string().required(`${t("this-is-required")}`),
    default_full_mark: yup.number().required(`${t("this-is-required")}`),
  });

  const [open, setOpen] = React.useState(false);
  const { sidebarRTL } = useSidebarContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (resetForm) => {
    resetForm(); // Reset the form fields to their initial values
    setOpen(false);
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    const info = {
      pageSize: props.pageSize,
    };
    await dispatch(addExam(values)).then(() => dispatch(fetchExamsData(info)));
    handleClose(resetForm); // Close the dialog and reset the form
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm, // Add resetForm to Formik's values
      }) => (
        <Box m="20px">
          <Button
            sx={{
              backgroundColor: colors.greenAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              display: props.show ? "inline-flex" : "none",
            }}
            variant="outlined"
            onClick={handleClickOpen}
            endIcon={<AddIcon />}
          >
            {t("add-exam")}
          </Button>
          <Dialog
            dir={sidebarRTL ? "right" : "left"}
            open={open}
            onClose={() => handleClose(resetForm)}
          >
            <DialogTitle
              sx={{ fontSize: "22px", fontWeight: "bold" }}
              align={sidebarRTL ? "right" : "left"}
            >
              {t("add-exam")}
            </DialogTitle>
            <DialogContent>
              <form dir={sidebarRTL ? "rtl" : "ltr"} onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ gridColumn: "span 2" }}
                  >
                    <Typography
                      component="label"
                      variant="body1"
                      fontWeight="bold"
                      mb={1}
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      {t("Name")} <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      placeholder={t("Name")}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      name="name"
                      error={!!touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      sx={{ gridColumn: "span 2" }}
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      inputProps={{
                        style: { fontSize: "18px", fontWeight: "bold" },
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ gridColumn: "span 2" }}
                  >
                    <Typography
                      component="label"
                      variant="body1"
                      fontWeight="bold"
                      mb={1}
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      {t("Default Full Mark")}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="number"
                      placeholder={t("Default Full Mark")}
                      dir={sidebarRTL ? "rtl" : "ltr"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.default_full_mark}
                      name="default_full_mark"
                      error={
                        !!touched.default_full_mark &&
                        !!errors.default_full_mark
                      }
                      helperText={
                        touched.default_full_mark && errors.default_full_mark
                      }
                      sx={{ gridColumn: "span 2" }}
                      inputProps={{
                        style: { fontSize: "18px", fontWeight: "bold" },
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.greenAccent[400],
                      color: colors.primary[300],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 15px",
                      "&:hover": {
                        backgroundColor: colors.greenAccent[500],
                      },
                    }}
                    disabled={loading ? true : false}
                    variant="outlined"
                  >
                    {loading ? "wait..." : t("Save")}
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Formik>
  );
};

export default AddExam;
